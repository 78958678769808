@import '../../../lib/styles/variables';
@import '../../../lib/styles/common';

.dao {
    > h4 {
        max-width: 600px;
        margin: 0 auto 6.875rem;
        font-size: 2.5rem;
        text-align: center;
    }

    > h4, .decode, .optimize, .topSection {
        padding: 0 1.25rem;
    }

    .decode, .activate, .optimize {
        max-width: 370px;
        margin: auto;

        + div {
            margin-top: 18.75rem;
        }

        > .body {
            max-width: 330px;
            margin-bottom: 7.8125rem;

            p {
                &.heading-2 {
                    margin-bottom: 1.5625rem;
                    font-size: 3.3rem;
                    letter-spacing: -.4px;
                }

                &:not(.heading) {
                    font-size: 1.875rem;
                }

                b {
                    font-size: 2.5rem;
                }
            }

            > hr {
                width: 330px;
                height: 6px;
                margin: 0 0 1.5625rem;
            }
        }

        .copy {
            .body {
                > p {
                    &.heading-3, &.heading-4, &.heading-6 {
                        color: $secondary-dark;
                    }

                    &.heading-3 {
                        font-size: 2.5rem;

                        + .heading {
                            margin-top: 0.5rem;
                        }
                    }

                    &.heading-4 {
                        font-family: $calibre-light;
                        font-size: 1.875rem;
                        font-weight: normal;
                    }

                    &.heading-5 {
                        font-family: $tiempos-semibold;
                        font-size: 1.5rem;

                        u {
                            position: relative;
                            display: inline-block;
                            text-decoration: none;
                            white-space: nowrap;

                            &::before {
                                position: absolute;
                                z-index: -1;
                                right: 0;
                                bottom: 5%;
                                left: 0;
                                height: 3px;
                                background: $secondary-light;
                                content: '';
                            }
                        }
                    }

                    &.heading-6 {
                        margin-bottom: 0.3125rem;
                        font-size: 1.5rem;
                    }
                }

                > ul {
                    width: 100%;
                    max-width: 330px;
                    padding: 1.875rem 0;
                    border-top: 2px solid transparent;
                    margin-top: 1.875rem;
                    border-image-slice: 1 0 0 0;
                    border-image-source: linear-gradient(to right, $primary, $secondary-light);

                    li {
                        @include checklist('primary', $primary, 1rem, 1);

                        margin-bottom: 1.25rem;
                    }
                }

                .quoteBubble {
                    position: relative;
                    width: 100%;
                    max-width: 330px;
                    padding: 0.125rem;
                    margin: 3.75rem auto;
                    background: conic-gradient(from 0.26turn, $secondary-light, $primary 0.49turn, $secondary-light);
                    border-radius: 1.125rem;

                    &::before, &::after {
                        @extend %absolute-x;

                        display: block;
                        width: 0;

                        border-style: solid;
                        content: '';
                    }

                    &--bottom {
                        &::before {
                            bottom: -13px;
                            border-width: 15px 15px 0;
                            border-color: $secondary transparent;
                        }

                        &::after {
                            bottom: -10px;
                            border-width: 13px 13px 0;
                            border-color: #fff transparent;
                        }
                    }

                    &--top {
                        &::before {
                            top: -13px;
                            border-width: 0 15px 15px;
                            border-color: $secondary transparent;
                        }

                        &::after {
                            top: -10px;
                            border-width: 0 13px 13px;
                            border-color: #fff transparent;
                        }
                    }

                    &--textGradient {
                        p {
                            -webkit-background-clip: text;
                            background-image: linear-gradient(to right, $primary, $secondary);
                            -webkit-box-decoration-break: clone;
                            font-family: $calibre-semibold;
                            font-size: 1.125rem;
                            text-align: center;
                            -webkit-text-fill-color: transparent;
                        }
                    }

                    .textBlock__inner {
                        padding: 1.5625rem;
                        background: $white;
                        border-radius: 1rem;
                    }
                }
            }
        }

        :global {
            .carousel {
                .control-dots {
                    bottom: -5px;

                    .dot {
                        background: $gray-dark;

                        &.selected, &.hover {
                            background: $secondary;
                        }
                    }
                }

                .slide {
                    img {
                        width: auto;
                    }
                }
            }
        }
    }

    .decode {
        > .body {
            > hr {
                background: linear-gradient(to right, $primary 0%, $primary 33%, #d6d7db 33%, #d6d7db 100%);
            }
        }

        :global {
            .Carousel {
                .Carousel__wrapper {
                    max-width: 356px;

                    .slide {
                        padding: 0 10px;
                    }

                    picture {
                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .activate {
        > .body {
            padding: 0 1.25rem;

            p {
                b, &.heading {
                    color: $secondary-dark;
                }
            }

            > hr {
                background: linear-gradient(to right, $primary 0%, $secondary 66%, #d6d7db 66%, #d6d7db 100%);
            }
        }

        .bottomSection {
            display: flex;
            flex-direction: column;
            margin-top: 10rem;

            .heading-5 {
                padding: 0 1.25rem;
                margin: 8.75rem auto 3.75rem;
                text-align: center;
            }

            .hi-complete {
                order: 0;
                padding: 0 1.25rem;
            }

            .formulated--one {
                order: 1;

                picture {
                    img {
                        width: 100%;
                    }
                }
            }

            .formulated--two {
                order: 3;

                .heading-4 {
                    padding: 0 2rem;
                    margin-bottom: 4.375rem;
                }
            }

            > picture {
                order: 2;
                margin: 6.25rem auto 4.375rem;
            }

            .formulated--supplements, .formulated--prepro {
                position: relative;

                .body {
                    p {
                        &:not(.heading) {
                            font-size: 1.125rem;
                        }
                    }
                }

                picture {
                    position: absolute;
                    z-index: -1;
                    bottom: 0;
                }

                ul {
                    padding-top: 0;
                    border: 0;

                    li {
                        margin-bottom: 0.625rem;

                        p {
                            font-family: $calibre-semibold;
                            font-size: 1rem;
                        }
                    }
                }
            }

            .formulated--supplements {
                padding-bottom: 5rem;
                margin-bottom: 9.375rem;

                .body {
                    padding-left: 2.5rem;
                }

                picture {
                    right: 0;
                }
            }

            .formulated--prepro {
                padding-bottom: 10.9375rem;

                .body {
                    width: 250px;
                    padding-right: 1.25rem;
                    margin-left: auto;
                }

                picture {
                    left: 0;
                }
            }

            .plusDivider {
                display: none;
            }
        }

        :global {
            .Carousel {
                .Carousel__wrapper {
                    max-width: 302px;
                }
            }
        }
    }

    .optimize {
        > .body {
            p {
                color: $secondary;
            }

            > hr {
                background: linear-gradient(to right, $primary, $secondary-light);
            }
        }

        .quoteBubble {
            .textBlock__inner {
                padding: 2.5rem !important;
            }

            p {
                &.heading-2 {
                    color: $secondary-light;
                    font-size: 4.5rem;
                    line-height: .9;
                }

                &:not(.heading) {
                    color: $charcoal;
                    font-size: 1.5rem;
                    line-height: 1.1;
                }
            }
        }
    }

    &.decode-activate-optimize--home {
        /* background: linear-gradient($white 0, $white 210px, $gray 210px, $gray 100%); */
        > h4 {
            display: none;
        }

        > .body {
            position: relative;
            margin-bottom: 150px;
            text-align: center;

            > p {
                @include viomeButton;

                a {
                    margin-top: 50px;
                }
            }

            > ol {
                @extend %absolute-x;

                top: 115px;
                width: 165px;

                p {
                    color: $white;
                    font-family: $calibre-semibold;
                    font-size: 18.5px;
                    letter-spacing: -0.23px;
                    line-height: 1.17;
                    text-align: center;

                    u {
                        -webkit-background-clip: text;
                        background-image: linear-gradient(to right, #f44565 0%, #a065fe 50%, #02dbb4 100%);
                        -webkit-box-decoration-break: clone;
                        text-decoration: none;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
        }

        .decode, .activate, .optimize {
            > .body {
                p {
                    &.heading-2 {
                        font-size: 50px;
                    }

                    &:not(.heading) {
                        font-family: $calibre-medium;
                        font-size: 20px;
                        line-height: 1.3;
                    }

                    b {
                        font-size: 30px;
                    }
                }
            }

            .copy {
                .body {
                    .heading-3, .heading-4 {
                        color: $primary;
                    }

                    .heading-3 {
                        font-size: 30px;
                    }

                    > ul {
                        border-image-source: linear-gradient(to right, #ca3550 0%, #7348b7 50%, #009e82 100%);
                    }

                    .quoteBubble {
                        background: linear-gradient(to right, #ca3550 0%, #7348b7 50%, #009e82 100%);

                        &::before {
                            border-color: #7348b7 transparent;
                        }

                        .textBlock__inner {
                            p {
                                background-image: linear-gradient(to right, #ca3550 0%, #7348b7 50%, #009e82 100%);
                            }
                        }
                    }
                }
            }
        }

        .decode {
            > .body {
                > hr {
                    background: linear-gradient(to right, #ca3550 0%, #7348b7 33%, #d6d7db 33%, #d6d7db 100%);
                }
            }
        }

        .activate {
            > .body {
                > hr {
                    background: linear-gradient(to right, #ca3550 0%, #7348b7 50%, $blue-dark 66%, #d6d7db 66%, #d6d7db 100%);
                }

                p {
                    b, &.heading {
                        color: $primary;
                    }
                }
            }

            .copy {
                .body {
                    > p {
                        &.heading-5 {
                            margin-bottom: 40px;
                            font-family: $calibre-semibold;
                            font-size: 30px;

                            u {
                                -webkit-background-clip: text;
                                background-image: linear-gradient(to right, #ca3550 0%, #7348b7 50%, #009e82 100%);
                                -webkit-box-decoration-break: clone;
                                -webkit-text-fill-color: transparent;

                                &::before {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        .optimize {
            padding-bottom: 100px;

            > .body {
                > hr {
                    background: linear-gradient(to right, #ca3550 0%, #7348b7 50%, #009e82 100%);
                }

                p {
                    color: $primary;

                    &.heading-2 {
                        font-size: 60px;
                    }

                    &:not(.heading) {
                        font-size: 17px;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        > h4 {
            padding: 0;
        }

        .activate {
            max-width: 433px;

            > .body {
                padding: 0 0 0 3.125rem;
            }

            .topSection, .bottomSection {
                .body {
                    > p {
                        max-width: 330px;
                    }

                    > p, > ul {
                        margin-right: auto;
                        margin-left: auto;
                    }

                    .quoteBubble {
                        max-width: 390px;
                    }
                }
            }

            .bottomSection {
                .hi-complete {
                    max-width: 370px;
                    margin-right: auto;
                    margin-left: auto;
                }

                .formulated--two {
                    .heading-4 {
                        max-width: none;
                        padding: 0;
                    }
                }

                .formulated--supplements, .formulated--prepro {
                    width: 433px;
                    height: 418px;
                    padding: 3.75rem 0 0 3.75rem;
                    border: 1px solid $secondary;

                    .body {
                        > p, > ul {
                            margin-left: 0;
                        }
                    }

                    picture {
                        right: 0;
                    }
                }

                .formulated--supplements {
                    margin-bottom: 0;

                    .body {
                        padding-left: 0;
                    }
                }

                .formulated--prepro {
                    .body {
                        width: auto;
                        padding-right: 0;
                        margin: 0;
                    }

                    picture {
                        left: unset;
                    }
                }

                .plusDivider {
                    display: block;
                }
            }
        }

        &.decode-activate-optimize--home {
            > .body {
                > ol {
                    top: 190px;
                    width: 265px;

                    p {
                        font-size: 30px;
                        letter-spacing: -0.37px;
                        line-height: 1.17;

                    }
                }
            }

            .activate {
                .bottomSection {
                    .formulated--supplements, .formulated--prepro {
                        border-image-slice: 1;
                        border-image-source: conic-gradient(from 0.4turn, #009e82, #028e79 0.18turn, #ca3550 0.44turn, #6b49ae 0.77turn, #009e82);
                    }

                    .plusDivider {
                        opacity: 0;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        > h4 {
            max-width: none;
        }

        .decode, .activate, .optimize {
            display: flex;
            max-width: 1000px;
            justify-content: space-between;
            padding: 0;

            + div {
                margin-top: 15.625rem;
            }

            &.column {
                flex-direction: column;
            }

            > .body {
                max-width: 475px;
                padding-top: 5rem;
            }

            .decode-carousel {
                margin: 0;
            }

            .copy {
                .body {
                    .quoteBubble {
                        margin: 1.875rem auto 0;

                        &--lg--right {
                            &::before {
                                top: 50%;
                                right: -20px;
                                bottom: auto;
                                left: auto;
                                border-width: 15px 0 15px 22px;
                                border-color: transparent $secondary-light;
                                transform: translateY(-50%);
                            }

                            &::after {
                                top: 50%;
                                right: -16px;
                                bottom: auto;
                                left: auto;
                                border-width: 12px 0 12px 19px;
                                border-color: transparent $white;
                                transform: translateY(-50%);
                            }
                        }

                        &--lg--left {
                            &::before {
                                top: 50%;
                                right: auto;
                                bottom: auto;
                                left: -19px;
                                border-width: 15px 22px 15px 0;
                                border-color: transparent $primary;
                                transform: translateY(-50%);
                            }

                            &::after {
                                top: 50%;
                                right: auto;
                                bottom: auto;
                                left: -15px;
                                border-width: 12px 19px 12px 0;
                                border-color: transparent $white;
                                transform: translateY(-50%);
                            }
                        }
                    }
                }
            }
        }

        .decode {
            :global {
                .Carousel {
                    .Carousel__wrapper {
                        .slide {
                            padding: 0;
                        }
                    }
                }
            }
        }

        .activate {
            > .body {
                padding: 0;
            }

            .topSection, .bottomSection {
                display: flex;

                .body {
                    > ul {
                        margin-left: 0;
                    }
                }
            }

            .topSection {
                padding: 0;

                .body {
                    padding-top: 5rem;
                    margin-right: 12.5rem;

                    > p {
                        max-width: none;
                    }
                }
            }

            .bottomSection {
                flex-flow: row wrap;

                .hi-complete, .formulated--one {
                    flex: 0 0 50%
                }

                .hi-complete {
                    max-width: none;
                    padding: 0;
                    margin: 0;

                    p {
                        max-width: none;
                    }
                }

                .formulated--one {
                    p {
                        margin-top: 0;
                    }

                    picture {
                        padding-left: 1.25rem;

                        img {
                            width: auto;
                        }
                    }
                }

                .formulated--two {
                    display: flex;
                    flex-wrap: wrap;

                    > .body {
                        flex: 0 0 100%;

                        p {
                            width: 445px;
                            margin: 0 0 3.4375rem;
                        }
                    }

                    .plusDivider {
                        margin-top: 75px;
                    }

                    .formulated--prepro {
                        margin-top: -150px;
                    }
                }

                > picture {
                    margin-left: 10.3125rem;
                }
            }
        }

        &.decode-activate-optimize--home {
            > .body {
                > ol {
                    top: 300px;
                    width: 420px;

                    p {
                        font-size: 48px;
                        letter-spacing: -0.6px;
                        line-height: 1.17;
                    }
                }
            }

            .decode, .activate, .optimize {
                .copy {
                    .body {
                        .quoteBubble {
                            &--lg--right {
                                &::before {
                                    border-color: transparent $secondary-light;
                                }

                                &::after {
                                    border-color: transparent $white;
                                }

                                .textBlock__inner {
                                    p {
                                        text-align: left;
                                    }
                                }
                            }

                            &--lg--left {
                                &::before {
                                    top: 50%;
                                    right: auto;
                                    bottom: auto;
                                    left: -19px;
                                    border-width: 15px 22px 15px 0;
                                    border-color: transparent $primary;
                                    transform: translateY(-50%);
                                }

                                &::after {
                                    top: 50%;
                                    right: auto;
                                    bottom: auto;
                                    left: -15px;
                                    border-width: 12px 19px 12px 0;
                                    border-color: transparent $white;
                                    transform: translateY(-50%);
                                }
                            }
                        }
                    }
                }
            }

            .activate {
                .bottomSection {
                    .hi-complete {
                        padding-top: 95px;
                    }

                    > picture {
                        margin: 0 0 6.25rem 10.3125rem;
                    }
                }
            }
        }
    }
}

@import '../../lib/styles/variables';
@import '../../lib/styles/common';

.hero {
    margin: 7.5rem 0 1.875rem;

    > .section {
        padding: 0 1.25rem;
    }

    .wrapper {
        position: relative;
        max-width: 330px;
        margin: auto;
    }

    > .viome-modern__product-hero__top {
        .wrapper {
            margin: 1.25rem auto 6.25rem;
        }

        .body {
            position: absolute;
            top: -80px;
        }
    }

    > .viome-modern__product-hero__bottom {
        h4 {
            display: block;
            max-width: 310px;
            margin: 0 auto 1.875rem;
            color: $secondary;
            font-size: 1.5rem;
            text-align: center;
        }

        .wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            > .body {
                order: 100;
                margin-top: 1.25rem;
            }

            ul {
                margin-bottom: 60px;

                li {
                    ~ li {
                        @include checklist('primary', $primary, 1rem, calc(18 / 16));

                        &::before {
                            margin-right: 0.9375rem;
                            margin-left: 0.3125rem;
                        }

                        margin-bottom: 0.75rem;
                    }
                }
            }
        }

        :global {
            .Card {
                width: 140px;

                p {
                    font-family: $calibre-semibold;
                    font-size: 1rem;
                    line-height: 1;
                }

                .inline {
                    flex-basis: 1.375rem;
                    margin-right: 0.6875rem;
                }

                .image {
                    display: flex;
                    max-width: 22px !important;
                    height: 22px;
                    justify-content: center;
                    background-color: $gold;
                    border-radius: 100%;

                    picture {
                        width: 60%;
                        align-items: center;
                    }
                }

                &__before {
                    display: flex;
                    align-items: flex-end;
                    margin-bottom: 1rem;
                }

                &:nth-child(4) {
                    .image {
                        background-color: $secondary-light;
                    }
                }

                &:nth-child(5) {
                    .image {
                        background-color: $red;
                    }
                }

                &:nth-child(6) {
                    .image {
                        background-color: $charcoal;
                    }
                }

                &:nth-child(7) {
                    .image {
                        background-color: $purple;
                    }
                }
            }

            .priceWrapper {
                text-align: center;
            }

            .price {
                font-size: 1rem;

                &--current {
                    color: $secondary;
                }
            }

            .cta {
                justify-content: center;
                margin-top: 15px;

                .button {
                    @include viomeButton;
                }
            }
        }
    }

    .heading-1 {
        max-width: 325px;
        margin: 0;
        font-family: $tiempos-light;
        font-size: 3.125rem;
        font-weight: 400;
        line-height: calc(60 / 50);
    }

    p {
        &:not(.heading) {
            font-size: 1.375rem;
            line-height: calc(28 / 22);
        }
    }

    i {
        font-family: $calibre;
        font-style: normal;
    }

    u {
        font-family: $tiempos-light;
        font-size: 1rem;
        font-style: italic;
    }

    @media screen and (min-width: $breakpoint-tablet) {
        margin-top: 6.25rem;

        > .viome-modern__product-hero__top {
            .wrapper {
                display: flex;
                max-width: 510px;
                justify-content: flex-end;
                margin: 0 auto 4.375rem;

                picture {
                    margin-right: -3.75rem;
                }
            }

            .body {
                top: 0;
            }
        }

        > .viome-modern__product-hero__bottom {
            .wrapper {
                max-width: 445px;
            }

            h4 {
                max-width: none;
            }

            :global {
                .Card {
                    margin: 0 0.75rem 0.3125rem;
                }

                ul {
                    .Card {
                        margin-left: 0;
                    }
                }
            }
        }

        .heading-1 {
            width: 240px;
            font-size: 2.5rem;
            line-height: calc(50 / 40);
        }
    }

    @media screen and (min-width: $breakpoint-lg) {
        display: flex;
        max-width: 1110px;
        justify-content: space-between;
        margin: 6.25rem auto 1.875rem;

        > .section {
            padding: 0;
            margin: 0;
        }

        > .viome-modern__product-hero__top {
            flex: 0 1 510px;

            .wrapper {
                margin-bottom: 0;

                picture {
                    margin-right: -8.75rem;
                }
            }

        }

        > .viome-modern__product-hero__bottom {
            margin-right: 6.25rem;

            .wrapper {
                max-width: 320px;
                justify-content: space-between;

                > .body {
                    margin-top: 1.875rem;
                }
            }

            h4 {
                width: 290px;
                margin: 0 0 1.875rem;
                text-align: left;
            }

            :global {
                .Card {
                    width: 140px;
                    margin: 0;
                }

                .priceWrapper {
                    text-align: left;
                }

                .price {
                    font-size: 1.875rem;
                }

                .cta {
                    justify-content: flex-start;
                }
            }
        }

        .heading-1 {
            width: 310px;
            font-size: 3.125rem;
            line-height: calc(60 / 50);
        }
    }
}

.ambassador__hero {
    padding: 0 10px 60px;
    margin: 40px 0 0;
    background-image: linear-gradient(to bottom, $white, #f4f4f5);

    .heading-2, .heading-3 {
        font-family: $tiempos-light;
        font-weight: normal;
    }

    .heading, p {
        text-align: center;
    }

    .container {
        .ambassador__header {
            picture {
                margin: 0;
            }

            + .ambassador__top {
                margin-top: 30px;
            }
        }

        .ambassador__top {
            .heading-2 {
                width: 100%;
                max-width: 320px;
                margin: 0 auto 8px;
                font-weight: normal;
                line-height: calc(52 / 40);
            }

            p {
                margin-bottom: 40px;
            }

            picture {
                margin-bottom: 2.188rem;
            }
        }

        .ambassador__bottom {
            .heading-3 {
                margin: 30px auto;
                font-size: 24px;
                font-weight: 600;
                line-height: calc(30 / 24);
                text-decoration: underline 3px solid #009e82;
            }

            .heading-6 {
                font-size: 30px;

                .price--suffix {
                    font-size: 20px;
                }
            }

            p {
                margin: 0;
                font-size: 18px;
                line-height: calc(24 / 18);
            }

            i {
                display: block;
                max-width: 290px;
                margin: auto;
                color: #727484;
                font-size: 16px;
                font-style: italic;
                line-height: calc(20 / 16);
            }

            :global {
                .priceWrapper {
                    text-align: center;

                    p {
                        color: $secondary;
                        font-family: $calibre-semibold;
                        font-size: 30px;
                        font-weight: 600;
                    }
                }

                .cta {
                    justify-content: center;
                    margin-bottom: 20px;

                    .footer {
                        justify-content: center;

                        .inline {
                            width: auto !important;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-desktop) {
        display: block;
        max-width: none;

        .container {
            display: flex;
            max-width: 1110px;
            flex-flow: row wrap;
            margin: auto;

            p, .heading {
                text-align: left;
            }

            .ambassador__header {
                flex-basis: 100%;

                .wrapper {
                    margin: 0;
                }
            }

            .ambassador__top {
                margin: 30px 0 0;

                .heading-2 {
                    max-width: none;
                }

                picture {
                    margin: 0 0 0 -120px;
                }

                .wrapper {
                    max-width: 635px;
                }
            }

            .ambassador__bottom {
                margin-left: 125px;

                i {
                    margin: 0;
                }

                .wrapper {
                    max-width: 350px;
                }

                :global {
                    .priceWrapper {
                        text-align: left;
                    }

                    .cta {
                        justify-content: flex-start;

                        .footer {
                            justify-content: flex-start;
                        }
                    }
                }
            }
        }
    }
}

/* stylelint-disable plugin/declaration-block-no-ignored-properties */
@import '../../../lib/styles/variables';
@import '../../../lib/styles/common';

.generic {
    h1 {
        margin: 0.75rem 0;
        font-family: $tiempos;
        font-size: 2.5rem;
        font-weight: normal;
        line-height: 1.25;
        text-align: center;
    }

    h4 {
        flex-basis: 100%;
        margin-bottom: 6.25rem;
        font-family: $tiempos;
        font-size: 2.5rem;
        text-align: center;
    }

    .heading {
        line-height: 1.4;
    }

    .heading-3 {
        margin-bottom: 3.125rem;
        font-family: $tiempos-semibold;
        font-size: 2.125rem;
    }

    .heading-4 {
        margin-bottom: 1.875rem;
    }

    .heading-5 {
        position: relative;
        padding-left: 0.625rem;
        border-left: 2px solid $secondary-light;
        margin: 3.125rem 0 1.125rem -0.125rem;
        color: $secondary-dark;
        font-family: $tiempos;
        font-weight: normal;

        + .heading {
            margin-top: 3.125rem;
        }
    }

    .heading-6 {
        margin: 3.125rem 0 0;
        color: $secondary-dark;
        font-size: 1.5rem;
        line-height: 1.08;
    }

    p {
        &:not(.heading) {
            font-size: 1rem;
            line-height: 1.38;

            i {
                display: block;
                margin: 1.5625rem 0;
                color: $secondary;
                font-family: $calibre-medium;
                font-style: italic;
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        h1 {
            font-size: 3.125rem;
            line-height: 1.2;
            text-align: left;
        }

        &.reverse {
            h4 {
                order: 0;
            }
        }
    }

    &--singleColumn {
        padding: 3.4375rem 1.25rem;

        .body {
            max-width: 770px;
            margin: auto;

            > p {
                margin: 0 auto;

                @include viomeButton;

                &:not(.heading) {
                    width: 80%;
                }

                &.heading {
                    + p {
                        margin-top: 1.875rem;
                    }
                }

                &.heading-4 {
                    line-height: calc(40 / 30);
                }

                &.heading-6 {
                    color: $black;
                    font-family: $calibre-medium;
                    font-size: 17px;
                    font-weight: normal;
                    line-height: 1;
                    text-align: center;
                }
            }
        }

        &.full {
            position: relative;

            .body {
                @extend %absolute-center;

                width: 80%;

                max-width: 770px;
            }

            picture {
                img {
                    width: 100%;
                }
            }
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            padding: 3.4375rem 2.5rem;

            .body {
                > p {
                    &.heading-6 {
                        font-size: 24px;
                    }
                }
            }
        }

        @media only screen and (min-width: $breakpoint-lg) {
            .body {
                max-width: 700px;
            }
        }

        &.generic--underline {
            .heading-2 {
                max-width: 330px;
                font-size: 24px;
                letter-spacing: -0.34px;
                line-height: 1.25;
                text-align: center;

                u {
                    position: relative;
                    display: inline-block;
                    text-decoration: none;

                    &::after {
                        position: absolute;
                        z-index: -1;
                        right: 0;
                        bottom: 6px;
                        left: 0;
                        height: 6px;
                        background-image: linear-gradient(to right, $secondary-light 0%, $white 100%);
                        content: '';
                    }
                }
            }

            @media only screen and (min-width: $breakpoint-tablet) {
                .heading-2 {
                    width: 570px;
                    max-width: none;
                    font-size: 40px;
                    letter-spacing: -0.57px;
                    line-height: 1.15;

                    u {
                        &::after {
                            bottom: 10px;
                            height: 10px;
                        }
                    }
                }
            }

            @media only screen and (min-width: $breakpoint-lg) {
                .heading-2 {
                    width: 675px;
                }
            }
        }

        &.generic--rainbow {
            width: 330px;
            padding: 35px 0 65px;
            margin-right: auto;
            margin-left: auto;

            &.generic--rainbow--period {
                h1, .heading-2 {
                    i {
                        b {
                            &::after {
                                content: '.';
                            }
                        }
                    }
                }
            }

            > .body {
                h1, .heading-2 {
                    font-family: $calibre-semibold;
                    font-size: 30px;
                    line-height: 1.2;
                    text-align: center;

                    i {
                        b {
                            @extend %rainbow-gradient;

                            position: relative;

                            -webkit-background-clip: text;
                            -webkit-box-decoration-break: clone;
                            font-style: normal;
                            -webkit-text-fill-color: transparent;

                            &::after {
                                -webkit-background-clip: text;
                                background-color: $black;
                                content: '';
                                -webkit-text-fill-color: transparent;
                            }
                        }
                    }

                    + p {
                        padding-top: 20px;
                        margin-top: 0;
                        text-align: center;
                    }
                }

                h1 {
                    margin: 6px 0 0;
                    letter-spacing: -0.69px;
                }

                .heading-2 {
                    letter-spacing: -0.37px;

                    i {
                        b {
                            @extend %rainbow-gradient;
                        }
                    }

                    + p {
                        font-family: $calibre-medium;
                        font-size: 20px;
                        letter-spacing: -0.46px;
                        line-height: 1.3;
                    }
                }
            }

            @media only screen and (min-width: $breakpoint-tablet) {
                width: 700px;
                padding: 70px 0 90px;

                > .body {
                    h1 {
                        margin-top: 20px;
                        font-size: 50px;
                        letter-spacing: -1.15px;
                        line-height: 1;

                        + p {
                            padding: 35px 100px 0;
                        }
                    }

                    .heading-2 {
                        font-size: 48px;
                        letter-spacing: -0.6px;
                        line-height: 1.17;

                        br {
                            display: none;
                        }

                        + p {
                            padding-top: 35px;
                            font-size: 26px;
                            letter-spacing: -0.6px;
                            line-height: 1.23;
                        }
                    }
                }
            }

            @media only screen and (min-width: $breakpoint-lg) {
                width: 870px;
                padding: 110px 0 130px;

                > .body {
                    max-width: none;

                    h1, .heading-2 {
                        width: 100%;
                        text-align: center;
                    }

                    h1 {
                        font-size: 70px;
                        letter-spacing: -1.62px;

                        br {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &--twoColumn {
        max-width: 700px;
        padding: 0 1.25rem;
        margin-right: auto;
        margin-left: auto;
        row-gap: 4.375rem;

        .body {
            max-width: 370px;
            padding: 0 1.25rem;

            > ul {
                margin: 1.25rem 0;

                li {
                    @include checklist('primary', $primary, 1rem, 1);

                    margin-bottom: 1.25rem;

                    b {
                        font-family: $calibre-semibold;
                    }
                }
            }

            > p {
                @include viomeButton('secondary-dark');

                a {
                    margin-top: 2.5rem;
                }
            }
        }

        > .section {
            + .section {
                margin-top: 90px;
            }
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            .body {
                max-width: 450px;
                padding: 0;
            }

            > .section {
                + .section {
                    margin-top: 150px;
                }
            }
        }

        @media only screen and (min-width: $breakpoint-lg) {
            > .section {
                + .section {
                    margin-top: 0;
                }
            }
        }

        &--hero {
            padding: 3.75rem 0 6.25rem;

            &.generic--border-bottom {
                margin-bottom: 0;
            }

            > .body {
                p {
                    text-align: center;

                    &.heading-4 {
                        color: $font-gray;
                        font-family: $tiempos-light;
                        font-size: 1.5rem;
                        font-weight: normal;
                        line-height: 1.33;
                    }

                    &:not(.heading) {
                        i {
                            display: inline;
                            margin: 0;
                            color: $font-gray;
                            font-family: $tiempos-light;
                        }
                    }
                }
            }

            .generic--hero-section {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-top: 2.5rem;

                &.reverse {
                    flex-direction: column-reverse;
                    align-items: center;
                }

                > .body {
                    .inline {
                        position: relative;
                        display: flex;
                        align-items: center;
                        margin-top: 3.125rem;
                        column-gap: 1.25rem;
                    }
                }

                :global {
                    .priceWrapper {
                        margin-top: 0.25rem;
                    }

                    .price {
                        color: $secondary;
                        font-size: 1.875rem;
                    }

                    .cta {
                        display: block;
                        margin-top: 0;
                    }

                    .footer {
                        @extend %absolute-x;

                        bottom: -40px;
                    }
                }
            }

            @media only screen and (min-width: $breakpoint-tablet) {
                padding-top: 5.3125rem;

                > .body {
                    max-width: none;

                    p {
                        &.heading-4 {
                            font-size: 1.75rem;

                            br {
                                display: none;
                            }
                        }
                    }
                }
            }

            @media only screen and (min-width: $breakpoint-lg) {
                display: flex;

                &.generic--twoColumn--fullAtLg {
                    max-width: 1110px;
                    margin: auto;
                    column-gap: 13.4375rem;
                }

                > .body {
                    max-width: 350px;

                    p {
                        &.heading-4 {
                            font-size: 1.875rem;

                            br {
                                display: block;
                            }
                        }

                        text-align: left;
                    }
                }

                .generic--hero-section {
                    align-items: flex-start;

                    > .body {
                        .inline {
                            width: auto !important;
                        }
                    }
                }
            }
        }

        &--fullAtSml, &--fullAtMed, &--fullAtLg {
            > .body {
                margin: auto;
            }
        }

        &--fullAtSml {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            column-gap: 2.5rem;

            &.reverse {
                flex-direction: row-reverse;
                justify-content: flex-end;
                column-gap: 1.875rem;
            }

            > .body {
                padding: 0 1.25rem 0 0;
                margin: 0;

                b {
                    margin-top: 0;
                    color: $secondary-dark;
                    font-size: 1.125rem;
                    line-height: 1.38;
                }
            }
        }

        &--fullAtMed {
            .heading-4 {
                margin-bottom: 20px;
                line-height: 1.17;
            }

            p {
                &:not(.heading) {
                    font-size: 1.125rem;
                    line-height: 1.33;
                }
            }

            &:not(.reverse) {
                > picture {
                    margin-top: 3.125rem;
                }
            }

            &.generic--twoColumn--product {
                .heading-4 {
                    margin-top: 2.5rem;
                }
            }

            @media only screen and (min-width: $breakpoint-tablet) {
                display: flex;
                max-width: 640px;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                padding: 0;
                column-gap: 3.125rem;

                &:not(.reverse) {
                    > picture {
                        margin-top: 0;
                    }
                }

                &.reverse {
                    flex-direction: row;
                }

                > .body {
                    max-width: 500px;
                    padding: 0 ;
                    margin: 0;
                }

                &.generic--twoColumn--product {
                    max-width: none;
                    column-gap: 0;

                    > .body {
                        padding: 0 5rem;
                    }

                    .heading-4 {
                        margin-top: 0;
                    }
                }
            }

            @media only screen and (min-width: $breakpoint-lg) {
                max-width: 720px;
                column-gap: 8.125rem;

                &.generic--twoColumn--product {
                    column-gap: 9.375rem;

                    &.full {
                        padding-right: 10rem;
                        padding-left: 12.5rem;
                    }

                    > .body {
                        max-width: 400px;
                        padding: 0;
                    }
                }
            }
        }

        &--fullAtLg {
            .heading-4 {
                line-height: 1.1;
            }

            @media only screen and (min-width: $breakpoint-lg) {
                display: flex;
                max-width: none;
                justify-content: space-between;
                margin-right: 0;
                margin-left: 0;
                column-gap: calc(190 / 1440 * 100%);

                &.full {
                    flex-direction: row-reverse;
                    padding: 0 calc(156 / 1440 * 100%) 0 0;

                    &.generic--twoColumn--imageRight {
                        flex-direction: row;
                        padding: 0 0 0 calc(156 / 1440 * 100%);
                    }
                }

                .body {
                    margin: 0;
                }
            }
        }

        &--max-1110 {
            .accordion {
                :global {
                    .accordion {
                        > .viome__section__body {
                            margin: 50px auto;

                            .heading-3 {
                                font-family: $calibre-semibold;
                                font-size: 22px;
                                letter-spacing: -0.31px;
                                line-height: 1.27;
                                text-align: center;

                                u {
                                    position: relative;
                                    display: inline-block;
                                    text-decoration: none;

                                    &::after {
                                        position: absolute;
                                        z-index: -1;
                                        right: 0;
                                        bottom: 5px;
                                        left: 0;
                                        height: 8px;
                                        background-image: linear-gradient(to right, $secondary-light 0%, $white 100%);
                                        content: '';
                                    }
                                }
                            }
                        }
                    }
                }
            }

            @media only screen and (min-width: $breakpoint-tablet) {
                .accordion {
                    :global {
                        .accordion {
                            > .viome__section__body {
                                max-width: 540px;

                                .heading-3 {
                                    font-size: 34px;
                                    letter-spacing: -0.49px;
                                    line-height: 1.24;

                                    u {
                                        &::after {
                                            z-index: -1;
                                            bottom: 10px;
                                            height: 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            @media only screen and (min-width: $breakpoint-lg) {
                max-width: 1110px;
                flex-direction: row-reverse;
                padding: 0;
                margin: auto;
                column-gap: 0;

                > .section {
                    flex: 0 0 auto;
                    margin: 0;

                    &.carousel {
                        width: 50%;
                    }
                }

                .accordion {
                    margin-top: 120px;

                    :global {
                        .accordion {
                            > .viome__section__body {
                                margin-top: 0;

                                .heading-3 {
                                    text-align: left;
                                }
                            }
                        }
                    }
                }

                :global {
                    .Carousel {
                        margin-top: 200px;

                        &__wrapper {
                            margin: 0;
                        }

                        .slide {
                            img {
                                width: auto;
                            }
                        }
                    }
                }
            }
        }

        &--wide, &--product, &--product-v2 {
            max-width: none;
            row-gap: 0;

            picture {
                img {
                    width: 100%;
                }
            }

            :global {
                .price {
                    font-size: 1.875rem;

                    &--current {
                        color: $secondary;
                    }
                }

                .cta {
                    justify-content: center;
                    text-align: center;
                }
            }

            @media only screen and (min-width: $breakpoint-lg) {
                flex-flow: row wrap;
                column-gap: 0;
            }
        }

        &--wide {
            > .body {
                max-width: none;
                padding: 8.125rem 1.25rem;

                .heading {
                    line-height: 1.1;
                }

                .heading-3 {
                    line-height: 1.29;
                }
            }

            @media only screen and (min-width: $breakpoint-tablet) {
                > .body {
                    padding: 10rem 20% !important;
                }
            }

            @media only screen and (min-width: $breakpoint-lg) {
                > .body {
                    align-self: stretch;
                    padding: 9.9rem 5rem 0 6.875rem !important;

                    > * {
                        max-width: 475px;
                    }
                }

                &.full {
                    padding: 0;
                }
            }
        }

        &--product, &--product-v2 {
            background: $gray;

            @media only screen and (min-width: $breakpoint-tablet) {
                :global {
                    .cta {
                        margin: 0;
                    }
                }
            }
        }

        &--product {
            &.full {
                padding: 8.125rem 0;
            }

            > .body {
                .heading-6 {
                    color: $secondary;
                    font-size: 2.25rem;
                }

                > .inline {
                    margin-top: 2.5rem;
                    text-align: center;
                }
            }

            @media only screen and (min-width: $breakpoint-tablet) {
                > .body {
                    max-width: 500px;

                    .inline {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        column-gap: 2.5rem;
                    }
                }

                :global {
                    .price {
                        margin-top: 0.3125rem;
                    }

                    .cta {
                        margin: 0;
                    }
                }
            }

            @media only screen and (min-width: $breakpoint-lg) {
                > .body {
                    max-width: none;

                    > * {
                        max-width: 500px;
                    }

                    .heading-6 {
                        margin-top: 0;
                    }

                    .inline {
                        justify-content: flex-start;
                    }
                }

                &.full {
                    padding: 10.625rem 0;
                }

                picture {
                    flex: 1;
                }
            }
        }

        &--product-v2 {
            > .body {
                max-width: 500px;
                padding: 6.25rem 2.5rem;

                .heading-3 {
                    margin-bottom: 2.5rem;
                }

                ol {
                    margin-bottom: 0.625rem;

                    li {
                        display: flex;
                        align-items: flex-start;

                        .inline {
                            width: auto !important;

                            + p {
                                padding: 0.1875rem 0 0 0.5625rem;
                            }
                        }
                    }
                }

                > ul {
                    padding-left: 1.9375rem;
                    margin-bottom: 1.875rem;

                    &:nth-of-type(2) {
                        li {
                            &::before {
                                content: url('https://images.ctfassets.net/qk4l4jfatr3e/1WlSjk8czhHTL0rX96j5Ug/a687922659a85cc3184b2b0fc2fb1097/path-copy-14.svg');
                            }
                        }
                    }

                    &:nth-of-type(3) {
                        li {
                            &::before {
                                content: url('https://images.ctfassets.net/qk4l4jfatr3e/4UxcjF3rBKORlGg0JIsRDg/2912b4df5406c1f3f5fb2b46cebe6972/path-copy-18.svg');
                            }
                        }
                    }
                }

                > .inline {
                    position: relative;
                    display: flex;
                    justify-content: center;

                    :global {
                        .priceWrapper {
                            padding-top: 0.5625rem;
                        }

                        .cta {
                            width: 140px;

                            .footer {
                                @extend %absolute-x;

                                bottom: -40px;
                            }
                        }
                    }
                }
            }

            @media only screen and (min-width: $breakpoint-tablet) {
                > .body {
                    > .inline {
                        width: 300px !important;
                        justify-content: flex-start;
                        column-gap: 1.875rem;
                    }
                }

                :global {
                    .priceWrapper {
                        margin-left: 1.9375rem;
                    }
                }
            }

            @media only screen and (min-width: $breakpoint-lg) {
                &.full {
                    padding: 0
                }

                > .body {
                    max-width: none;
                    padding: 0 6.25rem;
                    margin: auto;
                }
            }
        }

        &--notOne {
            .body {
                > p {
                    &:not(.heading) {
                        margin-bottom: 0;
                        font-size: 0.75rem;
                        line-height: calc(18 / 12);
                    }

                    &.heading-3 {
                        margin-bottom: 1.25rem;
                        font-size: 1.5rem;
                        line-height: calc(28 / 24);
                    }
                }
            }

            @media only screen and (max-width: $breakpoint-tablet) {
                display: block;
                max-width: 330px;
                padding: 0 1.25rem;
                margin: auto;

                picture {
                    margin-top: 2.8125rem;
                }
            }

            @media only screen and (min-width: $breakpoint-tablet) {
                display: flex;
                justify-content: center;
                padding: 0;
                column-gap: 3.125rem;

                .body {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }
            }
        }

        &--withKit {
            position: relative;

            > .body {
                z-index: 1;

                .inline {
                    &:nth-of-type(1) {
                        margin-top: -8.75rem;
                        margin-bottom: 5rem;

                        @media only screen and (min-width: $breakpoint-tablet) {
                            margin-top: -13.75rem;
                        }

                        @media only screen and (min-width: $breakpoint-lg) {
                            position: absolute;
                            bottom: 0;
                            left: 640px;
                            width: auto !important;
                            margin: 0;
                            transform: translate(-70%, 40%);
                        }
                    }

                    &:nth-of-type(2) {
                        display: flex;
                        align-items: flex-end;
                        margin-top: 0.625rem;
                    }
                }

                :global {
                    .price {
                        font-size: 1.875rem !important;

                        &--current {
                            margin-right: 0.625rem;
                            color: $secondary;
                        }
                    }
                }
            }
        }

        &--biorxiv {
            position: relative;
            column-gap: 0;

            > .body {
                display: flex;
                max-width: 330px;
                min-height: 375px;
                flex-direction: column;
                justify-content: space-between;
                padding: 3.125rem 1.875rem 1.875rem;
                border: 1px solid $primary;

                p {
                    &:not(.heading) {
                        font-size: 1.125rem;
                        line-height: calc(24 / 18);
                    }

                    @include viomeButton('secondary');
                }
            }

            &--home {
                > h4 {
                    margin-bottom: 40px;
                    font-family: $calibre-semibold;
                    font-size: 30px;
                    line-height: 1.2;
                }

                > .body {
                    height: 499px;
                    padding: 50px 45px 115px;

                    > p {
                        font-family: $calibre-medium;
                        font-size: 18px;
                        line-height: 1.33;
                    }

                    > ol {
                        position: absolute;
                        right: 20px;
                        bottom: 0;
                        left: 20px;
                        display: flex;
                        height: 313.5px;
                        flex-wrap: wrap;

                        li {
                            height: 50%;
                            flex: 0 0 50%;
                            padding: 25px 20px 0;

                            &:nth-of-type(odd) {
                                border-right: 1px solid $white;
                            }

                            &:first-of-type {
                                border-bottom: 1px solid $white;
                            }

                            &:last-of-type {
                                border-top: 1px solid $white;
                            }

                            p {
                                &:not(.heading) {
                                    color: $white;
                                    font-family: $calibre-medium;
                                    font-size: 10px;
                                    line-height: 1.41;

                                    b {
                                        display: inline-block;
                                        margin-top: 9px;
                                        font-size: 16px;
                                        line-height: 1;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            picture {
                justify-content: flex-start;
            }

            @media only screen and (max-width: $breakpoint-tablet) {
                max-width: 370px;
                padding: 0 1.25rem;

                picture {
                    margin-top: 0;
                }
            }

            @media only screen and (min-width: $breakpoint-tablet) {
                &--home {
                    max-width: 539px;
                    padding: 0;

                    > h4 {
                        font-size: 48px;
                        line-height: 1.17;
                    }

                    > .body {
                        max-width: none;
                        padding: 90px 80px 120px;

                        > p {
                            font-family: $calibre-medium;
                            font-size: 18px;
                            line-height: 1.33;
                        }

                        > ol {
                            right: 0;
                            left: 0;
                            height: 499px;

                            li {
                                padding: 35px 40px;

                                p {
                                    &:not(.heading) {
                                        font-size: 17px;

                                        b {
                                            font-size: 26px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            @media only screen and (min-width: $breakpoint-lg) {
                &--home {
                    max-width: 920px;
                    flex-wrap: wrap;
                    margin: auto;

                    > h4 {
                        flex-basis: 100%;
                        padding-right: 300px;
                        margin-bottom: 90px;
                        text-align: left;
                    }

                    > .body {
                        max-width: 381px;
                        padding: 90px 60px 90px 55px;

                        > ol {
                            left: 381px;
                        }
                    }
                }
            }
        }
    }

    &--twoRow {
        display: flex;
        max-width: 350px;
        flex-direction: row-reverse;
        padding-bottom: 100px;
        margin: auto;
        column-gap: 10px;

        > .body {
            ul {
                display: flex;
                height: 100%;
                flex-direction: column;

                li {
                    display: flex;
                    max-width: 190px;
                    flex-basis: calc(1 / 3 * 100%);

                    &:nth-of-type(1) {
                        padding-top: 7px;
                    }

                    &:nth-of-type(2) {
                        align-items: center;
                    }

                    &:nth-of-type(3) {
                        align-items: flex-end;
                    }

                    p {
                        font-size: 17px;
                        line-height: 1.29;

                        b {
                            font-size: 20px;
                            line-height: 1.6;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            max-width: 470px;
            padding: 0 0 100px;
            column-gap: 60px;

            > .body {
                ul {
                    li {
                        max-width: 270px;

                        p {
                            font-size: 22px;
                            line-height: 1.27;

                            b {
                                font-size: 30px;
                                line-height: 1.07;
                            }
                        }
                    }
                }
            }
        }

        @media only screen and (min-width: $breakpoint-lg) {
            > .body {
                ul {
                    flex-direction: row;

                    li {
                        max-width: none;

                        &:nth-of-type(1) {
                            padding: 0 0 0 10px;
                        }

                        &:nth-of-type(2) {
                            justify-content: center;
                        }

                        &:nth-of-type(3) {
                            justify-content: flex-end;
                        }

                        p {
                            text-align: center;
                        }
                    }
                }
            }
        }

        &--fullAtLg {

            @media only screen and (min-width: $breakpoint-lg) {
                max-width: 980px;
                flex-direction: column-reverse;
            }
        }
    }

    &--tiempos {
        .heading {
            font-family: $tiempos;
            font-weight: normal;
        }

        &--sb {
            .heading {
                font-family: $tiempos-semibold;
            }
        }
    }

    &--center {
        p {
            text-align: center;
        }
    }

    &--border-bottom {
        padding-bottom: 8.125rem;
        border-bottom: solid 1px $gray-dark;
        margin-bottom: 8.125rem;
    }

    &--home {
        max-width: 330px;
        padding: 50px 30px 80px;
        border-width: 1px;
        border-style: solid;
        border-image-slice: 1;
        border-image-source: conic-gradient(from 0.4turn, #009e82, #028e79 0.18turn, #ca3550 0.44turn, #6b49ae 0.77turn, #009e82);

        &.reverse {
            row-gap: 0;

            picture {
                margin-bottom: 70px;
            }
        }

        > .body {
            p {
                &.heading-4 {
                    line-height: 1.07;
                }

                &:not(.heading) {
                    font-family: $calibre-medium;
                    font-size: 17px;
                    line-height: 1.29;
                }
            }
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            max-width: 570px;
            padding: 50px 60px 60px;
        }

        @media only screen and (min-width: $breakpoint-lg) {
            max-width: 790px;
            padding: 70px;

            &.reverse {
                flex-direction: row;
                column-gap: 80px;

                picture {
                    margin: 0;
                }
            }
        }
    }
}

.generic--advisory {
    max-width: 370px;
    margin: auto;

    &.full {
        padding: 0 20px;
    }

    > .body {
        margin-bottom: 55px;

        p {
            text-align: center;

            &.heading-2 {
                margin-bottom: 30px;
                font-size: 30px;
                letter-spacing: -0.37px;
            }

            &:not(.heading) {
                font-family: $calibre-medium;
                font-size: 20px;
                letter-spacing: -0.25px;
                line-height: 1.3;
            }
        }
    }

    .grid--advisory {
        display: none;
    }

    :global {
        .Carousel {
            .Carousel__wrapper {
                .carousel-slider {
                    padding-bottom: 50px;
                }

                .slide {
                    > div {
                        display: flex;
                        flex-direction: column-reverse;

                        .viome__section__body {
                            max-width: 270px;

                            p {
                                text-align: left;

                                &.heading-4 {
                                    margin-bottom: 10px;
                                    font-size: 24px;
                                    letter-spacing: -0.3px;
                                    line-height: 1.08;
                                }

                                &:not(.heading) {
                                    font-family: $calibre-medium;
                                    font-size: 20px;
                                    letter-spacing: -0.25px;
                                    line-height: 1.3;
                                }
                            }
                        }

                        > picture {
                            padding: 0 3px;
                            margin-bottom: 20px;

                            img {
                                width: 100%;
                            }
                        }
                    }
                }

                .control-dots {
                    .dot {
                        background: $gray-dark;

                        &.selected {
                            background: $secondary-dark;
                        }
                    }
                }
            }
        }
    }

    .advisory-cta {
        margin-top: 50px;

        > .body {
            p {
                @include viomeButton('secondary');
            }
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        max-width: 570px;

        &.full {
            padding: 0;
        }

        > .body {
            margin-bottom: 80px;

            p {
                &.heading-2 {
                    font-size: 48px;
                    letter-spacing: -0.6px;
                }

                &:not(.heading) {
                    font-size: 26px;
                    letter-spacing: -0.33px;
                    line-height: 1.23;
                }
            }
        }

        :global {
            .carousel {
                .slide {
                    > div {
                        position: relative;

                        &.tall {
                            .viome__section__body {
                                p {
                                    color: $white;
                                }
                            }
                        }

                        .viome__section__body {
                            position: absolute;
                            top: 40px;
                            left: 45px;

                            p {
                                &.heading-4 {
                                    margin-bottom: 0;
                                    font-size: 30px;
                                    letter-spacing: -0.38px;
                                    line-height: 1.87;
                                }

                                &:not(.heading) {
                                    font-size: 26px;
                                    letter-spacing: -0.33px;
                                    line-height: 1.23;
                                }
                            }
                        }

                    }
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        max-width: 1110px;

        > .body {
            p {
                text-align: left;
            }
        }

        :global {
            .carousel {
                display: none;
            }
        }

        .grid--advisory {
            display: block;

            .grid {
                &__wrapper {
                    display: grid;
                    column-gap: 65px;
                    grid-template-columns: 474px 571px;
                    grid-template-rows: repeat(2, auto);
                    row-gap: 45px;

                    &.wrap {
                        flex-wrap: wrap;
                    }
                }

                &__item {
                    position: relative;

                    &.tall {
                        grid-row-start: span 2;

                        > .body {
                            max-width: 350px;

                            p {
                                color: $white;
                            }
                        }
                    }

                    > .body {
                        position: absolute;
                        top: 40px;
                        left: 45px;
                        max-width: 270px;

                        p {
                            &.heading-4 {
                                margin-bottom: 0;
                                font-size: 30px;
                                letter-spacing: -0.38px;
                            }

                            &:not(.heading) {
                                font-family: $calibre-medium;
                                font-size: 26px;
                                letter-spacing: -0.33px;
                                line-height: 1.23;
                            }
                        }
                    }
                }
            }
        }
    }
}

@import '../../lib/styles/variables';

.our-mission {
    > h4 {
        display: block;
        margin: 12.5rem 0 3.125rem;
        font-size: 1.875rem;
        text-align: center;
    }

    .missions {
        width: 100%;
        max-width: 370px;
        margin: auto;
        background-color: $secondary-dark;
    }

    .viome-modern__home__mission-item {
        position: relative;
        width: 100%;
        height: 370px;

        .body {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        p, .heading {
            color: $white;
        }

        p {
            width: 100%;
            max-width: 290px;
            margin: 0;
            font-size: 1.125rem;
            line-height: calc(22 / 18);
            text-align: center;

            &.heading-2 {
                margin-bottom: 0;
                font-size: 6rem;
                line-height: .6;

                u {
                    padding-right: 0.375rem;
                    font-family: $calibre-light;
                    font-size: .6562em;
                    font-weight: normal;
                    text-decoration: none;
                    vertical-align: middle;
                }
            }

            &.heading-3 {
                margin-bottom: 0.25rem;
                font-size: 1.5rem;
                line-height: 3rem;
                text-transform: uppercase;
            }
        }

        img {
            width: 100%;
        }

        &--two {
            .heading {
                color: $secondary-light;
            }
        }

        &--last {
            height: 620px;
            background-color: $primary;
            background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/5BaI3Zw0GGlHl5klETE1Gd/370a6bc44390c4e482e4f9616c14f8fb/home-section-background-spheres-mobile.svg?fm=webp');
            background-position: top center;
            background-repeat: no-repeat;

            .body {
                &::before {
                    color: $white;
                    content: '“';
                    font-size: 6.25rem;
                }

                div > div {
                    margin: auto;
                }
            }

            .heading {
                margin-bottom: 0;
                color: $white;
                font-size: 1rem;
            }

            p {
                padding: 0 2.5rem;
                font-size: 1rem;
                line-height: calc(24 / 16);
            }

            picture {
                width: 100px;
                margin: 3.125rem auto 2.1875rem;
            }
        }
    }

    &.our-mission--mental-health {
        .viome-modern__home__mission-item {
            p {
                &:not(.heading) {
                    font-size: 1.125rem;
                    line-height: 1;
                }
            }
        }
    }

    &.our-mission--biological-age {
        h4 {
            display: block;
            width: 370px;
            margin: 12.5rem auto 3.125rem;
            font-size: 1.875rem;
            text-align: center;

            u {
                text-decoration-color: $secondary-light;
                text-decoration-thickness: 3px;
            }
        }

        .viome-modern__home__mission-item {
            &--two {
                background-color: $secondary-dark;
            }

            .image {
                margin: 0 auto 1rem;
            }

            p {
                &:not(.heading) {
                    font-size: 1.125rem;
                    line-height: 1;
                }
            }
        }
    }

    @media screen and (max-width: $breakpoint-sml) {
        .missions {
            max-width: 290px;
        }

        .viome-modern__home__mission-item {
            height: 290px;

            picture {
                height: 290px;
            }

            p {
                max-width: 250px;
                padding: 0 1.25rem;
            }

            &--last {
                height: 620px;

                picture {
                    height: auto;
                }
            }
        }

        .viome-modern__home__biological-age {
            height: 290px;

            picture {
                height: 290px;
            }

            p {
                max-width: 250px;
                padding: 0 1.25rem;
            }

            &--last {
                picture {
                    height: auto;
                }
            }
        }
    }

    @media screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-lg) {
        &.our-mission--mental-health {
            .missions {
                max-width: 768px;
            }

            .viome-modern__home__mission-item {
                max-width: 768px;
                height: 343px;

                p {
                    &:not(.heading) {
                        font-size: 1.125rem;
                        line-height: 1;
                    }
                }
            }
        }

        &.our-mission--biological-age {
            .missions {
                max-width: 768px;
            }

            .viome-modern__home__mission-item {
                max-width: 768px;
                height: 343px;

                p {
                    &:not(.heading) {
                        font-size: 1.125rem;
                        line-height: 1;
                    }
                }

                &--three > div > picture > img {
                    height: 343px;
                }
            }
        }
    }

    @media screen and (min-width: $breakpoint-lg) {
        .missions {
            display: flex;
            max-width: none;
            flex-flow: row wrap;
        }

        .viome-modern__home__mission-item {
            width: auto;
            max-width: 480px;
            height: auto;
            flex: 0 0 calc( 100% / 3);

            &--last {
                width: 100%;
                max-width: none;
                height: 600px;
                flex-basis: 100%;
                background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/4HRexoP2R29CWeA6cK7MMH/613264959fe85a3df93b3b4abf7d7a6f/home-section-background-spheres-desktop.svg?fm=webp');
                background-size: contain;

                p {
                    width: 360px;
                    padding: 0;
                }

                picture {
                    margin-bottom: 2.188em;
                }
            }
        }
    }
}

@import './Product.module.scss';
@import './Count-Global.module.scss';

@mixin last-card {
    border: 2px solid #fff;

    .Card__before {
        height: 50px;
        align-items: center;

        p {
            margin: 0;
            font-size: 1.125rem;
        }
    }

    .Card__after {
        p {
            font-size: 1rem;
            line-height: calc(20 / 16);

            &:first-of-type {
                display: none;
            }
        }

        a {
            justify-content: flex-start;
            padding: 0;
            background: transparent;
            color: $blue;
        }
    }
}

.Product {
    background: $primary;

    .viome-modern__product-module__top {
        margin-bottom: 3.125rem;

        hr {
            background: linear-gradient(to right,$white 33%,rgb(255 255 255 / 10%) 33%);
        }
    }

    .viome-modern__product-module__middle {
        width: 100%;
        max-width: 370px;
        padding: 7.5rem 1.25rem 0;

        > .wrapper {
            > .body {
                > p {
                    margin-top: 0;
                    text-align: left;
                }
            }
        }

        .animate {
            height: 230px;

            div {
                color: $white;
                font-family: $tiempos-light;
                font-size: 1.5rem;
                font-weight: 400;
                line-height: calc(36 / 24);
            }

            + ul {
                display: none;
            }
        }
    }

    .viome-modern__product-module__bottom {
        li {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            p {
                margin: 0 0 0 1.5625rem;
            }

            .inline {
                max-width: 35px;
            }
        }

        a {
            justify-content: flex-start;
            padding: 0;
            background: transparent;
            color: $white;
        }
    }

    &__wrapper {
        :global {
            .viome-modern__product-module__middle {
                .Card {
                    &:last-of-type {
                        display: none;
                    }
                }
            }

            .viome-modern__product-module__bottom {
                .Card {
                    @include last-card;

                    width: 100%;
                    padding: 0 1rem;

                    margin-bottom: 3.75rem;
                }
            }
        }
    }

    &--hi {
        .viome-modern__product-module__bottom {
            p {
                a {
                    padding: 0;
                    background: transparent;
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        &.full {
            padding: 0 0 200px;
        }

        .viome-modern__product-module__top {
            .body {
                padding-top: 10.875rem;
            }
        }

        .viome-modern__hi--decode-carousel {
            order: 3;
            margin: 0 calc(((178/1440) * 100%) + 6.25rem) 0 0;
        }

        .viome-modern__product-module__middle {
            max-width: 330px;
            padding: 0;
            margin: -20rem 0 0 calc((165/1440) * 100%);

            > .wrapper {
                > .body {
                    > p {
                        &:last-of-type {
                            padding-top: 6.25rem;
                        }
                    }
                }
            }
        }

        .viome-modern__product-module__bottom {
            max-width: none;
            flex-basis: 100%;
            order: 4;
            margin-top: 0;

            ul {
                width: 88%;
                max-width: 1110px;
                padding-top: 3.75rem;
                border-top: 1px solid $charcoal;
                margin: auto;
            }

            li {
                justify-content: center;
            }
        }

        &__wrapper {
            :global {
                .Carousel {
                    .carousel-slider {
                        .control-arrow {
                            &.control-next {
                                &::before {
                                    padding-top: 1.4375rem;
                                    transform: rotate(180deg);
                                }
                            }
                        }
                    }

                    .control-dots {
                        bottom: -35px;
                        left: 50%;
                        display: flex;
                        justify-content: center;
                        transform: translateX(-50%);
                    }
                }

                .viome-modern__product-module__bottom {
                    .Card {
                        display: none;
                    }
                }
            }
        }

        &--hi {
            .Product__wrapper {
                .viome-modern__product-module__top {
                    padding-top: 10.625em;

                    .body {
                        padding-top: 0;
                    }
                }

                .viome-modern__product-module__middle {
                    :global {
                        .Card {
                            &:last-of-type {
                                @include last-card;

                                display: block;
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    &--gi, &--gi-plan {
        .viome-modern__product-module__top {
            p {
                &.heading-4 {
                    text-align: left;
                }
            }
        }

        .viome-modern__accordion {
            > .wrapper {
                display: flex;
                flex-direction: column;

                > .body {
                    &:first-of-type {
                        order: 1;

                        p {
                            margin: 0;
                        }

                        :global {
                            .button {
                                width: 100%;
                            }

                            .footer {
                                margin-top: 1.25rem;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }

    &--gi {
        .Product__wrapper {
            .viome-modern__accordion {
                > .wrapper {
                    > .body {
                        + div {
                            border: solid 2px $secondary-light;
                            margin-bottom: 2.8125rem;

                            :global {
                                .Card__before {
                                    height: 50px;
                                    align-items: center;
                                }

                                p {
                                    margin: 0;

                                    &::after {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }

                :global {
                    .Card {
                        &:last-of-type {
                            display: block;
                            border: 0;
                        }
                    }
                }
            }
        }

        @media only screen and (min-width: $breakpoint-lg) {
            .Product__wrapper {
                .viome-modern__accordion {
                    margin-top: 0;
                }

                .viome-modern__hi--decode-carousel {
                    align-self: center;
                }
            }
        }
    }

    &--gi-plan {
        .viome-modern__product-module__top {
            p {
                &.heading-4 {
                    margin-bottom: 0;
                }
            }
        }

        .Product__wrapper {
            > .viome-modern__hi--decode-carousel {
                display: none;
            }

            .viome-modern__accordion {
                padding-top: 0;

                .viome-modern__hi--decode-carousel {
                    margin-bottom: 10rem;
                }

                :global {
                    .viome-modern__hi--decode-carousel {
                        + .Card {
                            border: solid 2px $secondary-light;
                            margin-bottom: 2.8125rem;

                            .Card__before {
                                height: 50px;
                                align-items: center;
                            }

                            p {
                                margin: 0;

                                &::after {
                                    display: none;
                                }
                            }
                        }
                    }

                    .Card {
                        &:last-of-type {
                            display: block;
                            border: 0;
                        }
                    }
                }
            }
        }

        @media only screen and (min-width: $breakpoint-lg) {
            &.full {
                padding: 10.625rem 0 12.5rem;
            }

            .viome-modern__product-module__top {
                .body {
                    padding-top: 0;
                }
            }

            .Product__wrapper {
                .viome-modern__hi--decode-carousel {
                    display: block;
                    margin-top: -2.5rem;
                }

                .viome-modern__accordion {
                    margin-top: -30.25rem;

                    .viome-modern__hi--decode-carousel {
                        display: none;
                    }

                    > .wrapper {
                        display: flex;
                        flex-direction: column;

                        > .body {
                            > p {
                                &:last-of-type {
                                    padding-top: 0;
                                    margin-bottom: 6.25rem;
                                }
                            }

                            &:first-of-type {
                                display: block;
                                order: 2;

                                > p {
                                    &:last-of-type {
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
    }
}

/* stylelint-disable media-feature-name-no-vendor-prefix */
@import '../../../lib/styles/variables';
@import '../../../lib/styles/common';

.promotional {
    background-size: cover;

    .body {
        display: flex;
        height: 350px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 1.25rem;

        > p {
            @include viomeButton();

            &.heading-2 {
                display: inline-flex;
                font-family: $tiempos-medium;
                font-size: 1.875rem;

                i {
                    margin-left: 0.25rem;
                    font-size: .476em;
                    font-style: normal;
                }
            }

            &:not(.heading) {
                i {
                    font-size: 0.8125rem;
                    line-height: 1.3;
                }
            }

            a {
                margin-bottom: 0.75rem;
            }
        }

        ul {
            display: flex;
            width: 100%;
            justify-content: center;
            padding: 1.5625rem 0;

            li {
                min-height: 90px;
                flex: 0 0 48%;
                padding-top: 0.25rem;
                text-align: center;

                &:nth-child(2) {
                    flex-basis: 4%;
                    padding: 0;
                    background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/6u41SYA8Ku9HC4dledXTPZ/968e3f25aa15818b741ab7e2fc207544/line-3__5_.svg');
                    background-position: top center;
                    background-repeat: no-repeat;
                }

                > p {
                    font-family: $calibre-semibold;
                    font-size: 0.8938rem;

                    i , u {
                        font-family: $calibre;
                    }

                    i {
                        font-size: 0.7312rem;
                    }

                    u {
                        font-size: 0.8125rem;
                        text-decoration: none;
                    }
                }
            }
        }

        :global {
            .price {
                display: flex;
                justify-content: center;
                font-size: 2.0313rem;

                .prefix {
                    padding: 0.125rem 0.125rem 0 0;
                    font-size: 0.515em;
                }

                &--original {
                    display: inline-flex;
                    font-family: $calibre-light;
                    font-size: 1.0688rem;

                    + .price {
                        margin-top: 0.25rem;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        .body {
            max-width: 520px;
            height: 450px;
            padding: 0;
            margin: auto;

            > p {
                &.heading-2 {
                    font-size: 2.5rem;

                    i {
                        font-size: .476em;
                    }
                }

                &:not(.heading) {
                    line-height: 1;

                    i {
                        font-size: 1.125rem;
                    }
                }
            }

            ul {
                padding: 1.75rem 0;

                li {
                    min-height: 130px;

                    &:nth-child(2) {
                        background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/7D8BzSH30dNmy7a8YfngDX/eb6f9ebdc67a5e6f9d269c80b2fe2798/line-3__4_.svg');
                    }

                    > p {
                        font-size: 1.375rem;

                        i {
                            font-size: 1.125rem;
                        }

                        u {
                            font-size: 1.25rem;
                        }
                    }
                }
            }

            :global {
                .price {
                    font-size: 3.125rem;

                    .prefix {
                        padding-top: 0.25rem 0.25rem 0 0;
                    }

                    &--original {
                        font-size: 1.575rem;
                    }
                }
            }
        }
    }

    &-mothers-day-2021 {
        background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/6lQ4bu6wVpNtV7SX2OFgc0/3eabfe86eabf2f2f3b4ca2df6ec0ba42/pexels-lisa-1083822_3x.jpg?w=370&fm=webp&q=50');

        .body {
            color: $white;

            > p {
                @include viomeButton('default', $white, $red, $red);

                a {
                    &:hover {
                        border: 2px solid $white;
                        color: $white;
                    }
                }
            }

            ul {
                li {
                    &:nth-child(2) {
                        background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/5VHqbYmogkhXoYrUgmaoqF/07f2c6c98d6634ee787620507328140e/line-3.svg');
                    }
                }
            }
        }

        @media only screen and (-webkit-min-device-pixel-ratio: 2),
            only screen and (-o-min-device-pixel-ratio: 2/1),
            only screen and (min-resolution: 192dpi),
            only screen and (min-resolution: 2dppx) {
            background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/6lQ4bu6wVpNtV7SX2OFgc0/3eabfe86eabf2f2f3b4ca2df6ec0ba42/pexels-lisa-1083822_3x.jpg?w=740&fm=webp&q=50');

        }

        @media only screen and (-webkit-min-device-pixel-ratio: 3),
            only screen and (-o-min-device-pixel-ratio: 3/1),
            only screen and (min-resolution: 384dpi),
            only screen and (min-resolution: 3dppx) {
            background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/6lQ4bu6wVpNtV7SX2OFgc0/3eabfe86eabf2f2f3b4ca2df6ec0ba42/pexels-lisa-1083822_3x.jpg?fm=webp&q=50');
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            height: 450px;
            background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/39C8ShacjhEfSBA3t4MvJX/26f2d83b5e10b39f6d8ebf906488cf3b/pexels-lisa-1083822_2x__1_.jpg?w=768&fm=webp&q=50');

            .body {
                ul {
                    li {
                        &:nth-child(2) {
                            background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/60zohchBomogldGmCXBsmv/c9f8523c2df7ad54057cdd8d2bd8f34a/line-3__1_.svg');
                        }
                    }
                }
            }

            @media only screen and (-webkit-min-device-pixel-ratio: 2),
                only screen and (-o-min-device-pixel-ratio: 2/1),
                only screen and (min-resolution: 192dpi),
                only screen and (min-resolution: 2dppx) {
                background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/39C8ShacjhEfSBA3t4MvJX/26f2d83b5e10b39f6d8ebf906488cf3b/pexels-lisa-1083822_2x__1_.jpg?fm=webp&q=50');
            }
        }

        @media only screen and (min-width: $breakpoint-lg) {
            background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/5BCrr6m4XzAiPgVXb3DluM/42686fe8d75ad01be50444b84d2e1c5f/pexels-lisa-1083822_2x.jpg?w=1440&fm=webp&q=50');

            @media only screen and (-webkit-min-device-pixel-ratio: 2),
                only screen and (-o-min-device-pixel-ratio: 2/1),
                only screen and (min-resolution: 192dpi),
                only screen and (min-resolution: 2dppx) {
                background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/5BCrr6m4XzAiPgVXb3DluM/42686fe8d75ad01be50444b84d2e1c5f/pexels-lisa-1083822_2x.jpg?fm=webp&q=50');
            }
        }
    }

    &-fathers-day-2021 {
        background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/1BDb4ZZfy2vr1IkEOpzGdK/f5903f00f4515fb9396b9bb0372a29fa/pexels-lisa-1083822_3x.webp?w=370&q=50');

        .body {
            color: $white;

            > p {
                @include viomeButton('default', $white, $secondary-light, $secondary-light);

                a {
                    &:hover {
                        border: 2px solid $white;
                        color: $white;
                    }
                }
            }

            ul {
                li {
                    &:nth-child(2) {
                        background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/5VHqbYmogkhXoYrUgmaoqF/07f2c6c98d6634ee787620507328140e/line-3.svg');
                    }
                }
            }
        }

        :global {
            .price {
                &--original {
                    &::after {
                        background-color: $white;
                    }
                }
            }
        }

        @media only screen and (-webkit-min-device-pixel-ratio: 2),
            only screen and (-o-min-device-pixel-ratio: 2/1),
            only screen and (min-resolution: 192dpi),
            only screen and (min-resolution: 2dppx) {
            background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/1BDb4ZZfy2vr1IkEOpzGdK/f5903f00f4515fb9396b9bb0372a29fa/pexels-lisa-1083822_3x.webp?w=740&q=50');

        }

        @media only screen and (-webkit-min-device-pixel-ratio: 3),
            only screen and (-o-min-device-pixel-ratio: 3/1),
            only screen and (min-resolution: 384dpi),
            only screen and (min-resolution: 3dppx) {
            background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/1BDb4ZZfy2vr1IkEOpzGdK/f5903f00f4515fb9396b9bb0372a29fa/pexels-lisa-1083822_3x.webp?q=50');
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            height: 450px;
            background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/eQfqO1Zl12cmviVnDgSfk/59e3a82abb750f9d664cc4f8eaddb85c/pexels-lisa-1083822_3x__1_.webp?w=768&q=50');

            .body {
                ul {
                    li {
                        &:nth-child(2) {
                            background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/60zohchBomogldGmCXBsmv/c9f8523c2df7ad54057cdd8d2bd8f34a/line-3__1_.svg');
                        }
                    }
                }
            }

            @media only screen and (-webkit-min-device-pixel-ratio: 2),
                only screen and (-o-min-device-pixel-ratio: 2/1),
                only screen and (min-resolution: 192dpi),
                only screen and (min-resolution: 2dppx) {
                background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/eQfqO1Zl12cmviVnDgSfk/59e3a82abb750f9d664cc4f8eaddb85c/pexels-lisa-1083822_3x__1_.webp?q=50');
            }
        }

        @media only screen and (min-width: $breakpoint-lg) {
            background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/5GVPiQMEmpLMmW5siv8hxF/01eacdef6595d42e56c336f43035bbbd/pexels-lisa-1083822_2x.webp?w=1440&q=50');

            @media only screen and (-webkit-min-device-pixel-ratio: 2),
                only screen and (-o-min-device-pixel-ratio: 2/1),
                only screen and (min-resolution: 192dpi),
                only screen and (min-resolution: 2dppx) {
                background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/5GVPiQMEmpLMmW5siv8hxF/01eacdef6595d42e56c336f43035bbbd/pexels-lisa-1083822_2x.webp?q=50');
            }
        }
    }

    &-memorial-day-2021 {
        background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/11TrJCg2vbBRWMeue3AMWq/efd66112c62ed0011bd514ef12137084/pexels-lumn-1028598_3x__2_.webp?w=370');

        .body {
            color: $secondary-dark;
        }

        @media only screen and (-webkit-min-device-pixel-ratio: 2),
            only screen and (-o-min-device-pixel-ratio: 2/1),
            only screen and (min-resolution: 192dpi),
            only screen and (min-resolution: 2dppx) {
            background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/11TrJCg2vbBRWMeue3AMWq/efd66112c62ed0011bd514ef12137084/pexels-lumn-1028598_3x__2_.webp?w=740');

        }

        @media only screen and (-webkit-min-device-pixel-ratio: 3),
            only screen and (-o-min-device-pixel-ratio: 3/1),
            only screen and (min-resolution: 384dpi),
            only screen and (min-resolution: 3dppx) {
            background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/11TrJCg2vbBRWMeue3AMWq/efd66112c62ed0011bd514ef12137084/pexels-lumn-1028598_3x__2_.webp');
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            height: 450px;
            background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/58V0b0penIyZRaWt59XKI4/c30a68eae4c146529475c28d9dd2767e/pexels-lumn-1028598_2x__1_.webp?w=768');

            @media only screen and (-webkit-min-device-pixel-ratio: 2),
                only screen and (-o-min-device-pixel-ratio: 2/1),
                only screen and (min-resolution: 192dpi),
                only screen and (min-resolution: 2dppx) {
                background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/58V0b0penIyZRaWt59XKI4/c30a68eae4c146529475c28d9dd2767e/pexels-lumn-1028598_2x__1_.webp');
            }
        }

        @media only screen and (min-width: $breakpoint-lg) {
            background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/1sCwtpdMglvmgqgSuJYk35/d9630b7ed04eedb98ca340ce2a479c2d/pexels-lumn-1028598_2x.webp?w=1440');

            @media only screen and (-webkit-min-device-pixel-ratio: 2),
                only screen and (-o-min-device-pixel-ratio: 2/1),
                only screen and (min-resolution: 192dpi),
                only screen and (min-resolution: 2dppx) {
                background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/1sCwtpdMglvmgqgSuJYk35/d9630b7ed04eedb98ca340ce2a479c2d/pexels-lumn-1028598_2x.webp');
            }
        }
    }
}

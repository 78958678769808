@import '../../lib/styles/variables';

.SEOLinks {
    max-width: 450px;
    margin: 6.25rem auto;

    h4, p {
        color: $charcoal;
    }

    h4 {
        display: block;
        margin-bottom: 1.25rem;
        font-size: 1.25rem;
        line-height: calc(35 / 20);
    }

    p {
        max-width: 86.66%;
        margin: 0;
        font-size: 1.125rem;
        line-height: calc(25 / 18);

        a {
            &:active, &:focus, &:hover {
                color: $charcoal;
            }
        }
    }

    ul {
        padding-top: 0.3125rem;
        border-top: 0.1875rem solid $gray-dark;
    }

    li {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.9375rem 0 0.8125rem;
        border-bottom: 1px solid $gray-dark;

        &::after {
            display: block;
            width: 8px;
            height: 12px;
            margin-right: 1rem;
            background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/6fgJTfzKEESXOSINJMigwT/9d35179ae328d31efad9c5f63e6624e3/gray_pointer_-_carousel.svg');
            background-size: cover;
            content: '';
            transform: rotate(-180deg);
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        margin-top: 9.375rem;
    }

    @media only screen and (min-width: $breakpoint-lg) {
        max-width: 728px;
    }
}

@import './Count-Global.module.scss';

.Product {
    .heading-2, .heading-3, b {
        color: $secondary;
    }

    .heading-3 {
        border-bottom: 1px solid $secondary;
        margin-bottom: 0.9375rem;
        font-family: $calibre-semibold;
        font-size: 1.875rem;
        line-height: calc(35 / 30);
        text-align: left;
    }

    .heading-4 {
        margin-bottom: 1.875rem;
        font-size: 1.125rem;
    }

    p {
        &:not(.heading) {
            font-size: 1.125rem;
            line-height: calc(24 / 18);
        }
    }

    &__wrapper {
        .body {
            width: 100%;
            max-width: 370px;
            padding: 0 1.25rem;
            margin: auto;
        }

        > .body {
            margin: 11.25rem auto 4.375rem;

            li {
                display: flex;
                align-items: flex-start;
                margin-bottom: 1.5rem;

                &::before {
                    display: block;
                    margin: 0.1875rem .75rem 0 0;
                    content: url('https://images.ctfassets.net/qk4l4jfatr3e/4mOzRJjOsodazJP8LAHo8c/8ce2cc26293bbcc7a6784dcdd8ff4077/path-copy-42__1_.svg');
                }

                &:last-of-type {
                    padding: 0;
                    margin: 0;
                }
            }
        }

        :global {
            .Carousel {
                .Carousel__wrapper {
                    max-width: 277px;
                    margin-bottom: 9.0625rem;
                }

                .carousel-slider {
                    .control-arrow {
                        &::before {
                            content: url('https://images.ctfassets.net/qk4l4jfatr3e/6REyX3MvLqAmd7fv6HJA2l/e29e98834d5865edaa804bf5594de5a9/green_pointer_-_carousel.svg');
                        }

                        &.control-prev {
                            left: -6.25rem;

                            &::before {
                                padding-top: 1.4375rem;
                                transform: rotate(180deg);
                            }
                        }

                        &.control-next {
                            right: -6.25rem;
                        }
                    }
                }

                .carousel {
                    .control-dots {
                        .dot {
                            background: $gray-dark;

                            &.selected, &.hover {
                                background: $secondary;
                            }
                        }
                    }
                }
            }
        }
    }

    .viome-modern__product-module__top {
        hr {
            background: linear-gradient(to right,$secondary 66%,rgb(0 0 0 / 20%) 66%);
        }
    }

    .viome-modern__product-module__carousel-section {
        > .wrapper {
            .body {
                margin-bottom: 5rem;
            }
        }

        p {
            &:not(.heading) {
                + p {
                    margin-top: 1.875rem;
                }
            }

            &:last-of-type {
                @include viomeButton('secondary');
            }
        }
    }

    .viome-modern__product-module__recommendations {
        max-width: 370px;
        padding: 3.75rem 1.25rem;
        border-top: 3px solid $secondary;
        border-bottom: 3px solid $secondary;
        margin: 0 auto;

        p {
            &:not(.heading) {
                font-size: 1rem;
                line-height: calc(22 / 16);
            }

            &.heading-4 {
                font-size: 24px;
                line-height: 1;
            }
        }
    }

    .viome-modern__product-module__info {
        max-width: 330px;
        padding: 0 1.25rem;
        margin: 0 auto;

        > .wrapper {
            display: flex;
            flex-direction: column-reverse;
            padding: 1.5625rem 1.25rem;
            border-width: 2px;
            border-style: solid;
            border-image-slice: 1;
            border-image-source: conic-gradient(from .42turn,$purple,$secondary-light .49turn,$purple);

            > .body {
                .heading {
                    display: none;
                }

                p {
                    &:not(.heading) {
                        font-size: 1rem;
                    }
                }

                > p {
                    &:nth-child(2) {
                        margin-bottom: 1rem;
                        font-family: $calibre-semibold;
                        line-height: calc(20 / 16);
                    }
                }

                li {
                    position: relative;
                    padding: 0.625rem 0 0.625rem 1.875rem;
                    margin-bottom: 0;

                    a {
                        color: $blue;
                        font-family: $calibre-medium;
                    }

                    p {
                        margin-top: 0.125rem;
                    }

                    &::before {
                        position: absolute;
                        left: 0;
                        display: flex;
                        width: 18px;
                        height: 18px;
                        justify-content: center;
                        border-radius: 3.125rem;
                        color: $white;
                        font-family: $calibre-semibold;
                        font-size: 1.125rem;
                    }

                    &:nth-child(1) {
                        border-top: 2px solid $secondary;
                        border-bottom: 2px solid $blue-dark;

                        &::before {
                            background-color: $secondary;
                            content: '1';
                        }
                    }

                    &:nth-child(2) {
                        border-bottom: 2px solid $purple-dark;

                        &::before {
                            background-color: $blue-dark;
                            content: '2';
                        }
                    }

                    &:nth-child(3) {
                        max-width: 330px;

                        &::before {
                            background-color: $purple-dark;
                            content: '3';
                        }

                        p {
                            display: inline;

                            + .inline {
                                display: inline-flex;
                                width: auto !important;
                                margin-left: 0.375rem;

                                p {
                                    color: $secondary;
                                    font-size: 1.125rem
                                }
                            }
                        }
                    }

                    &:last-of-type {
                        padding: 0 0 0 1.875rem;
                    }
                }
            }
        }

        .body {
            padding: 0;
        }

        .heading-3 {
            border: 0;
            margin-bottom: 1.25rem;
            color: #1f4855;
            font-family: $calibre-semibold;
            text-align: center;
        }

        .viome-modern__product-module__info--top {
            .body {
                > * {
                    &:not(.heading) {
                        display: none;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        .viome-modern__product-module__info {
            padding: 0;
        }

        .viome-modern__product-module__recommendations {
            max-width: 470px;
            padding: 3.75rem 0;
            margin: 0 auto 4.375rem;
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        &.full {
            padding-bottom: 220px;
        }

        &__wrapper {
            :global {
                .Carousel {
                    .Carousel__wrapper {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .viome-modern__product-module__carousel-section {
            flex-basis: 100%;
            margin-left: calc((165/1440) * 100%);

            > .wrapper {
                display: flex;
                align-items: center;

                .body {
                    max-width: 330px;
                    padding: 0;
                    margin: 0 calc((178/1440) * 100%) 0 0;
                }
            }
        }

        .viome-modern__product-module__recommendations {
            max-width: 1110px;
            padding: 3.75rem 0;
            margin: 1.875rem calc((165/1440) * 100%);

            ul {
                display: grid;
                grid-gap: 30px 50px;
                grid-template-columns: repeat(2, 450px);

                li {
                    margin-bottom: 0;
                }
            }
        }

        .viome-modern__product-module__info {
            width: 100%;
            max-width: 730px;
            padding: 5.625rem 0 0;

            > .wrapper {
                flex-direction: row-reverse;
                padding: 5rem 3.75rem;

                > .body {
                    width: 250px;
                    margin: 0 0 0 3.75rem;

                    .heading {
                        display: block;
                        margin-bottom: 1.25rem;
                        font-size: 1.25rem;
                        line-height: 1;
                        text-align: left;
                    }
                }
            }

            .viome-modern__product-module__info--top {
                .body {
                    > * {
                        &:not(.heading) {
                            display: block;
                        }

                        &.heading {
                            display: none;
                        }
                    }

                    .image {
                        width: 116px;
                        margin-bottom: 0.625rem;

                        img {
                            width: 100%;
                        }
                    }

                    p {
                        margin-bottom: 3.75rem;
                        font-family: $calibre-semibold;
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }

    &--gi, &--gi-plan {
        .viome-modern__product-module__top {
            .heading-4 {
                color: $secondary;
            }

            p {
                &:not(.heading) {
                    line-height: calc(35 / 30);
                }
            }
        }

        .viome-modern__product-module__carousel-section {
            .heading-3 {
                padding-bottom: 1rem;
                margin-bottom: 1.6875rem;
            }

            b {
                color: $primary;
            }
        }

        @media only screen and (min-width: $breakpoint-lg) {
            .viome-modern__product-module__top {
                .body {
                    padding-top: 10.875rem;
                }
            }
        }
    }

    &--gi {
        .viome-modern__product-module__carousel-section {
            > .wrapper {
                .body {
                    > ul {
                        margin-top: 1.875rem;
                    }
                }
            }

            .inline {
                :global {
                    .Card {
                        .viome__section__body {
                            margin: 0 auto;

                            p {
                                font-size: 1.25rem;
                                line-height: 1.2;
                            }
                        }

                        .Card__before {
                            display: flex;
                            height: 50px;
                            align-items: center;
                            justify-content: space-between;
                            padding: 0 1.25rem;
                            margin-top: 1.25rem;

                            p {
                                color: $white;
                                font-family: $calibre-semibold;
                                text-transform: uppercase;
                            }

                            &::after {
                                padding-top: 0.375rem;
                                color: $white;
                                content: '+';
                                font-size: 1.875rem;
                            }
                        }

                        .Card__after {
                            background: transparent;

                            p {
                                padding: 1.25rem 0 0;
                            }
                        }

                        .active {
                            .Card__before {
                                &::after {
                                    content: '-';
                                }
                            }
                        }

                        $colors: ('009e82': $secondary-light, '6d4ab0': $purple, 'ca3550': $red, '2998c3': $blue);

                        @each $color, $name in $colors {
                            .color--#{$color} {
                                .Card__before {
                                    background-color: $name;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &--gi-plan {
        .viome-modern__product-module__carousel-section {
            .body {
                li {
                    @include checklist('primary', $primary, 1.125rem, 1.33, 0, false);

                    padding-bottom: 0.5rem;

                    margin-bottom: 0.5rem;

                    &::before {
                        margin-top: 0.1875rem;
                    }
                }
            }
        }

        .viome-modern__product-module__info {
            max-width: none;

            > .wrapper {
                padding: 6.25rem 0;
                border: 0;
                border-top: 1px solid $gray-dark;
                border-bottom: 1px solid $gray-dark;

                > .body {
                    padding: 0 1.25rem;

                    .heading {
                        display: block;
                        font-family: $tiempos;
                        font-weight: normal;
                        text-align: left;
                    }

                    p {
                        &:not(.heading) {
                            margin: 0;
                            font-family: $calibre;
                            font-size: 1.125rem;
                            line-height: calc(24 / 18);
                        }
                    }
                }
            }

            picture {
                margin-bottom: 1.75rem;
            }
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            .viome-modern__product-module__info {
                > .wrapper {
                    > .body {
                        max-width: 440px;
                        padding: 0;
                    }
                }

                picture {
                    margin-bottom: 2.375rem;
                }
            }
        }

        @media only screen and (min-width: $breakpoint-lg) {
            .viome-modern__product-module__carousel-section {
                > .wrapper {
                    align-items: flex-start;
                }

                .viome-modern__hi--activate-carousel-1 {
                    margin-top: 0;
                }
            }

            .viome-modern__product-module__info {
                max-width: 1110px;
                margin-top: 0;

                > .wrapper {
                    align-items: center;
                    justify-content: center;

                    > .body {
                        width: 100%;

                        .heading {
                            font-size: 1.875rem;
                            line-height: calc(40 / 30);
                            white-space: nowrap;
                        }
                    }
                }

                picture {
                    margin-bottom: 0;
                }
            }
        }
    }
}

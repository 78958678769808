/* stylelint-disable media-feature-name-no-vendor-prefix */
@import '../../lib/styles/variables';
@import '../../lib/styles/common';

.benefits {
    background-color: $secondary-dark;

    .body {
        width: 100%;
        max-width: 390px;
        padding: 0 1.875rem;
        margin: 4.688rem auto 3.125rem;

        > p {
            margin: 3.125rem 0 1.875rem;

            &:last-of-type {
                @include viomeButton('secondary-dark');
            }
        }
    }

    .heading {
        margin-bottom: 1.875rem;
        color: $white;
        font-family: $calibre-semibold;
        font-size: 1.875rem;
        line-height: calc(34 / 30);
    }

    .heading-3 {
        width: 290px;
        margin: 0 auto 1.875rem;
    }

    img {
        width: 100%;
    }

    li {
        display: flex;
        align-items: flex-start;
        padding-bottom: 0.75rem;
        margin-bottom: 0.75rem;
        color: $white;

        &::before {
            display: block;
            margin: 0.1875rem .75rem 0 0;
            content: url('https://images.ctfassets.net/qk4l4jfatr3e/4mOzRJjOsodazJP8LAHo8c/8ce2cc26293bbcc7a6784dcdd8ff4077/path-copy-42__1_.svg');
        }

        &:last-of-type {
            padding: 0;
            margin: 0;
        }

        p {
            font-size: 1.25rem;
            line-height: calc(28 / 20);
        }
    }

    :global {
        .priceWrapper {
            margin-top: 5rem;
            text-align: center;
        }

        .price {
            color: $white;
            font-size: 1.875rem;

            &--current {
                display: inline-flex;
            }

            .currency {
                margin-top: 0.3125rem;
            }
        }

        .cta {
            align-items: flex-start;
            justify-content: center;
            margin-top: 1.5625rem;

            li {
                &::before {
                    display: none;
                }

                align-items: center;
                padding: 0;
                margin: 0;
            }
        }
    }

    &--carousel, &--gi-plan {
        > .body {
            ul {
                padding: .625rem;
                border: 4px solid $white;
                margin: auto;
            }

            li {
                border-bottom: 1px solid $white;

                &:last-of-type {
                    border: 0;
                }
            }
        }
    }

    &--carousel {
        background-color: $secondary;

        > .body {
            width: 100%;
            max-width: none;
            padding: 7.5rem 0 6.25rem;
            margin: 0;
            background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/7lco2kJ8EkNLxTuKYJZ4Ev/312478e831ec20873515cef5699db52f/group-7_3x__1_.jpg?w=370&fm=webp&q=70');
            background-size: cover;

            > p {
                margin: 3.125rem auto 1.875rem;
            }

            ul {
                width: 290px;
            }
        }

        :global {
            .Carousel__wrapper {
                width: 310px;
                padding: 9.375rem 0 8.75rem;
            }

            .slide {
                .body {
                    background: none;
                }

                .image {
                    margin: auto;
                }

                picture {
                    width: 100px;
                    margin: 0 auto 1.625rem;
                }

                p {
                    font-size: 1rem;
                    line-height: calc(24 / 16);

                    &:not(.heading) {
                        margin-bottom: 2.5rem;
                        font-family: $calibre-semibold;
                    }

                    &.heading {
                        margin: 0;
                    }

                    &.heading-2 {
                        font-size: 1.25rem;
                        line-height: calc(24 / 20);
                    }

                    &.heading-3 {
                        width: auto;
                        font-family: $calibre;
                        font-weight: normal;
                    }
                }
            }

            .carousel {
                li {
                    &::before {
                        display: none;
                    }
                }

                .control-dots {
                    bottom: -10px;

                    .dot {
                        padding: 0;
                        background: $secondary-dark;
                    }
                }
            }
        }

        @media only screen and (-webkit-min-device-pixel-ratio: 2),
            only screen and (-o-min-device-pixel-ratio: 2/1),
            only screen and (min-resolution: 192dpi),
            only screen and (min-resolution: 2dppx) {
            > .body {
                background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/7lco2kJ8EkNLxTuKYJZ4Ev/312478e831ec20873515cef5699db52f/group-7_3x__1_.jpg?w=740&fm=webp&q=70');
            }
        }

        @media screen and (min-width: $breakpoint-tablet) {
            > .body {
                background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/6Yb74jrQX5qMlN4IOm40M0/9b3ba90b5af4298e1f6bc2b0630a8071/group-29_3x.jpg?w=768&fm=webp&q=70');

                ul {
                    width: 390px;
                }
            }

            .heading-3 {
                width: 390px;
            }

            @media only screen and (-webkit-min-device-pixel-ratio: 2),
                only screen and (-o-min-device-pixel-ratio: 2/1),
                only screen and (min-resolution: 192dpi),
                only screen and (min-resolution: 2dppx) {
                > .body {
                    background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/6Yb74jrQX5qMlN4IOm40M0/9b3ba90b5af4298e1f6bc2b0630a8071/group-29_3x.jpg?w=1536&fm=webp&q=70');
                }
            }
        }

        @media screen and (min-width: $breakpoint-lg) {
            display: flex;

            > .body {
                background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/5qhed6588OUDiFtugDkv0R/4c38e0c5e38b0e5f55b8a93535f46824/group-7_3x.jpg?w=720&fm=webp&q=70');

                > p {
                    margin-top: 7.5rem;
                }
            }

            .viome-modern__carousel--customer-quotes {
                height: 700px;
                flex: 0 0 50%;
            }

            @media only screen and (-webkit-min-device-pixel-ratio: 2),
                only screen and (-o-min-device-pixel-ratio: 2/1),
                only screen and (min-resolution: 192dpi),
                only screen and (min-resolution: 2dppx) {
                > .body {
                    background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/5qhed6588OUDiFtugDkv0R/4c38e0c5e38b0e5f55b8a93535f46824/group-7_3x.jpg?w=1440&fm=webp&q=70');
                }
            }
        }
    }

    &--gi-plan {
        position: relative;
        height: 775px;

        picture {
            height: 775px;

            img {
                height: 100%;
            }
        }

        > .body {
            @extend %absolute-x;

            top: 128px;
            margin: 0;

            background: transparent;

            li {
                p {
                    font-family: $calibre-semibold;
                    font-size: 1.125rem;
                    line-height: calc(20 / 18);
                }
            }
        }

        .heading-3 {
            width: 100%;
        }

        @media screen and (min-width: $breakpoint-tablet) {
            > .body {
                top: 168px;
                max-width: 490px;
                padding: 0;
            }
        }

        @media screen and (min-width: $breakpoint-lg) {
            height: auto;
            max-height: 780px;
            background: $gray-dark;

            &.reverse {
                justify-content: space-between;
            }

            > .body {
                position: static;
                max-width: none;
                transform: none;

                > p, ul {
                    width: 490px;
                    margin-right: auto;
                    margin-left: auto;
                }

                p {
                    margin-top: 0;
                    color: $secondary-dark;
                }

                ul, li {
                    border-color: $secondary-dark;
                }
            }

            .heading-3 {
                margin-bottom: 70px;
            }

            picture {
                height: auto;
                flex: 0 0 calc(661 / 1440 * 100%);
            }
        }
    }

    &--gi {
        :global {
            .cta {
                > div {
                    border-color: $white;
                    background: transparent;

                    > div, ul li {
                        color: $white;
                    }
                }

                ol {
                    border-color: $white;
                    background: #1F4855;
                }

                path {
                    stroke: $white;
                }
            }
        }

        @media only screen and (min-width: $breakpoint-lg) {
            .body {
                + picture {
                    flex-basis: 721px;
                }
            }
        }
    }

    &--border {
        > .body {
            ul {
                width: 290px;
                padding: .625rem;
                border: 4px solid $white;
                margin: auto;
            }

            li {
                border-bottom: 1px solid $white;

                &:last-of-type {
                    border: 0;
                }
            }

            p {
                color: $white;
                font-family: $calibre-semibold;
            }
        }

        @media screen and (min-width: $breakpoint-tablet) {
            > .body {
                display: flex;
                width: 565px;
                max-width: none;
                justify-content: space-between;
                padding: 0;

                .heading-3 {
                    width: 225px;
                    margin: 0;
                }

                ul {
                    flex-shrink: 0;
                    margin: 0;
                }
            }
        }

        @media screen and (min-width: $breakpoint-lg) {
            > .body {
                flex-direction: column;
                justify-content: flex-start;
                margin-left: 5rem;

                .heading-3 {
                    width: 445px;
                    margin-bottom: 4.375rem;
                }

                ul {
                    width: 490px;
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        .body {
            padding: 0;
            margin: 0 auto;
        }
    }
}

@import '../../../lib/styles/variables';
@import '../../../lib/styles/common';

.cta {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.375rem;

    .button {
        @include viomeButton;
    }

    &.hide-price {
        margin-top: 1.4375rem;
    }
}

.quantity {
    @include dropdownSelect;

    + .button {
        margin-left: 1rem;
    }
}

.footer {
    display: flex;
    flex: 0 0 100%;
    align-items: center;
    margin-top: 1.25rem;

    &.restrictions {
        p {
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    p {
        padding: 0;
        color: $font-gray;
        font-size: 0.9375rem;
        font-style: italic;
        line-height: 1;
        white-space: nowrap;
    }

    > div {
        padding-bottom: 0.1875rem;
        margin-left: 0.3125rem;
    }
}

.overlay {
    &__wrapper {
        position: fixed;
        z-index: 9999;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: scroll;
        padding: 6.25rem 1.25rem 7.875rem;
        background: $white;

        :global {
            p {
                max-width: 330px;
                margin: auto;

                &.heading-2 {
                    margin-bottom: 1.5625rem;
                    font-family: $calibre-medium;
                    font-size: 1.625rem;
                    letter-spacing: -.6px;
                    line-height: 1.15;
                }

                &:not(.heading) {
                    font-size: 1.0625rem;
                    line-height: 1.29;
                }
            }

            a {
                color: $primary;
                font-weight: bold;
                text-decoration: underline;
            }
        }
    }

    &__close {
        position: absolute;
        top: 26px;
        right: 26px;
        width: 49px;
        cursor: pointer;
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        &__wrapper {
            top: calc(150 / 1080 * 100%);
            right: 50%;
            bottom: unset;
            left: unset;
            width: 690px;
            height: 570px;
            padding: 6.5625rem 3.75rem 5rem;
            margin-right: -21.5625rem;
            box-shadow: 0 2px 100px 0 rgb(0 0 0 / 10%);

            :global {
                p {
                    max-width: none;
                }
            }
        }

        &__close {
            top: 42px;
            right: 44px;
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        &__wrapper {
            top: calc(220 / 1080 * 100%);
            width: 920px;
            height: 510px;
            padding: 6.5625rem 4.0625rem 5.625rem;
            margin-right: -28.75rem;
        }

        &__close {
            top: 46px;
            right: 46px;
        }
    }
}

@import '../../lib/styles/variables';
@import '../../lib/styles/common';

.threeSteps {
    margin-top: 0;
    background: $gray;

    > .body {
        width: 100%;
        max-width: 370px;
        padding: 0 1.25rem;
        margin: 0 auto 3.75rem;
        text-align: center;

        .heading-2 {
            margin-bottom: 1.25rem;
            font-size: 1.875rem;
        }

        p {
            &:not(.heading) {
                font-size: 1.125rem;
                line-height: calc(24 / 18);
            }
        }
    }

    > picture {
        img {
            width: 100%;
        }
    }

    .section {
        width: 100%;
        max-width: 370px;
        align-self: center;
        padding: 0 1.25rem;
        margin: 0 auto;

        .heading-2 {
            margin-bottom: .5em;
            font-family: $calibre;
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 1;
        }

        p {
            &:not(.heading) {
                font-size: 1.125rem;
                line-height: calc(19 / 18);
            }
        }
    }

    .viome-modern__home-3steps--decode {
        &.section {
            margin-top: -4.375rem;
        }

        .heading-3 {
            margin-top: 0.625rem;
            font-family: $calibre-medium;
            font-size: 1rem;
            font-weight: 500;
            line-height: 1.38;
        }

        li {
            @include checklist('thin', $primary, 0.875rem, calc(38 / 14), $primary, true);
        }

        picture {
            width: 325px;

            img {
                width: 100%;
            }
        }
    }

    .viome-modern__home-3steps--activate {
        &.section {
            position: relative;
            margin-top: 6.25rem;
        }

        > .wrapper {
            > .body {
                padding-top: 2.25rem;
            }
        }

        .body {
            > p {
                &:not(.heading) {
                    width: 130px;
                    margin-bottom: 2.8em;
                }
            }

            li {
                @include checklist('thin', $primary, 0.875rem, calc(38 / 14), $secondary-dark);
            }
        }

        .viome-modern__home-3steps--activate-bottom {
            &.section {
                position: relative;
                padding: 0;
                margin-top: 3.125rem;
            }

            picture {
                width: 120px;
            }

            ul {
                column-count: 2;
                column-gap: 0.9375rem;
            }

            li {
                margin-bottom: .5rem;

                &:nth-child(3n+3) {
                    p {
                        border: 0;
                    }
                }

                &:last-of-type {
                    &::before {
                        content: url('https://images.ctfassets.net/qk4l4jfatr3e/4snpIyxjO7OHa6oaw8Tecd/c8234f8ace2a532a6fa64c6bd36c6610/group-13.svg');
                    }
                }

                p {
                    width: 80px;
                    padding-bottom: 0;
                    line-height: calc(38 / 16);
                    white-space: nowrap;
                }
            }
        }

        picture {
            position: absolute;
            top: 0;
            right: 0;
            width: 84px;
        }
    }

    .viome-modern__home-3steps--optimize {
        &.section {
            margin-top: 6.25rem;
        }

        img {
            width: 100%;
        }
    }

    &.full {
        padding-top: 4.375rem;
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        > .body {
            max-width: 450px;
            padding: 0;
            margin-bottom: 7.5rem;
        }

        .section {
            max-width: none;
            padding: 0 calc(65 / 768 * 100%);
        }

        .viome-modern__home-3steps--decode {
            &.section {
                margin: -21.875rem 0 0;
            }
        }

        .viome-modern__home-3steps--activate {
            .wrapper {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;

                > .body {
                    width: auto;
                    flex: 0 1 auto;
                    padding: 0;
                }
            }

            .body {
                > p {
                    &:not(.heading) {
                        width: 310px;
                        margin-bottom: 1.25rem;
                    }
                }
            }

            picture {
                position: static;
                width: 139px;
                margin-right: 14%;

                img {
                    width: 100%;
                }
            }

            .viome-modern__home-3steps--activate-bottom {
                &.section {
                    margin-top: 5rem;
                }

                .wrapper {
                    flex-direction: row-reverse;
                }

                picture {
                    width: 201px;
                    margin-left: 3.125rem;
                }
            }
        }

        .viome-modern__home-3steps--optimize {
            &.section {
                padding-right: 0;
            }

            .wrapper {
                position: relative;
            }

            .body {
                position: absolute;
                top: 0;
                left: 0;
                width: 280px;
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;

        > .body {
            max-width: 730px;
            margin-bottom: 6.25rem;
        }

        > picture {
            position: absolute;
            z-index: 0;
            top: 355px;
            right: 0;
        }

        .section {
            z-index: 1;
        }

        .viome-modern__home-3steps--decode {
            &.section {
                margin: 0;
            }
        }

        .viome-modern__home-3steps--activate {
            &.section {
                margin-top: 3.75rem;
            }

            > .wrapper {
                > picture {
                    display: none;
                }
            }

            .viome-modern__home-3steps--activate-bottom {
                &.section {
                    flex-basis: 100%;
                    margin-top: 2.5rem;
                }

                .wrapper {
                    position: relative;
                    width: 400px;
                    margin: 0;

                    > .body {
                        flex-basis: 450px;
                    }
                }

                picture {
                    position: absolute;
                    top: auto;
                    right: 0;
                    bottom: 0;
                    margin: 0;
                }

                ul {
                    column-count: 1;
                }

                li {
                    &:nth-child(3) {
                        p {
                            border-bottom: .5px solid $primary;
                        }
                    }
                }
            }
        }

        .viome-modern__home-3steps--optimize {
            &.section {
                position: absolute;
                right: 260px;
                bottom: 150px;
                width: 352px;
                padding: 0;
                margin: 0;
            }

            .body {
                position: static;
            }
        }

        &.full {
            padding: 11.25rem 0 9.375rem;
        }
    }
}

.threeSteps--hi {
    &.full {
        padding-top: 0;
    }

    > .body {
        display: none;
    }

    .viome-modern__home-3steps--decode {
        &.section {
            margin-top: 3.125rem;
        }
    }

    u {
        text-decoration: none;
    }

    @media screen and (min-width: $breakpoint-tablet) {
        .viome-modern__home-3steps--decode {
            &.section {
                margin-top: -310px;
            }
        }
    }

    @media screen and (min-width: $breakpoint-lg) {
        &.full {
            padding-top: 10rem;
        }

        > picture {
            top: 111px;
            width: 789px;
        }

        .viome-modern__home-3steps--decode {
            &.section {
                margin-top: 0;
            }
        }

        .viome-modern__home-3steps--activate {
            > .wrapper {
                justify-content: flex-start;

                > picture {
                    display: block;
                    margin: 0 0 0 90px;
                }
            }
        }
    }
}

.threeSteps--gi {
    > .body {
        padding: 0;
        margin-bottom: 0;

        .heading-2 {
            margin: 0;
        }
    }

    > picture {
        margin: -1.25rem 0 0 2.5rem;
    }

    .viome-modern__home-3steps--decode {
        &.section {
            margin-top: 2.5rem;
        }
    }

    .viome-modern__home-3steps--activate {
        &.section {
            margin-top: 5rem;
        }

        > .wrapper {
            > .body {
                padding: 0;

                p {
                    &:not(.heading) {
                        width: auto;
                    }
                }

                ul {
                    margin-bottom: 0.9375rem;
                }
            }
        }

        picture {
            position: static;
            width: auto;
        }
    }

    @media screen and (min-width: $breakpoint-tablet) {
        &.full {
            position: relative;
            padding-top: 0;
        }

        > .body {
            position: absolute;
            top: 80px;
            left: calc(65 / 768 * 100%);
            text-align: left;
        }

        > picture {
            width: calc(540 / 768 * 100%);
            max-width: 582px;
            margin: 0 0 0 auto;
        }

        .viome-modern__home-3steps--decode {
            position: absolute;
            top: 196px;
            left: calc(65 / 768 * 100%);
            padding: 0;

            &.section {
                width: auto;
                margin: 0;
            }
        }

        .viome-modern__home-3steps--activate {
            &.section {
                margin-top: 5rem;
            }

            > .wrapper {
                > .body {
                    padding: 0;

                    p {
                        &:not(.heading) {
                            width: 335px;
                        }
                    }

                    ul {
                        width: 310px;
                    }
                }
            }
        }
    }

    @media screen and (min-width: $breakpoint-lg) {
        &.full {
            padding-bottom: 5.9375rem;
        }

        > .body {
            max-width: none;
            margin-top: 13.125rem;
        }

        > .body, .viome-modern__home-3steps--decode, .viome-modern__home-3steps--activate {
            position: static;
            padding: 0 calc(241 / 1440 * 100%);
        }

        > picture {
            top: 60px;
            right: calc(180 / 1440 * 100%);
            max-width: 632px;
            margin: 0;
        }

        .viome-modern__home-3steps--decode {
            &.section {
                width: 100%;
                margin-top: 5rem;
            }
        }

        .viome-modern__home-3steps--activate {
            > .wrapper {
                width: calc(735 / 1440 * 100%);
                min-width: 450px;
                margin: 0;

                > picture {
                    display: block;
                    margin: 0;
                }
            }
        }

        .viome-modern__home-3steps--optimize {
            &.section {
                right: calc(216 / 1440 * 100%);
                bottom: 115px;
            }

            .body {
                margin-bottom: 3.3125rem;
            }
        }
    }
}

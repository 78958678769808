@import 'lib/styles/variables';
@import 'lib/styles/common';

.about-product {
    height: 1622px;
    background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/6HO01nmEAgCwdl9JtAkMG9/bfa52c8a9251ea1e55ff16fd5975b85e/group-24_3x.jpg?h=1633&fm=webp&q=70');
    background-size: cover;

    &.new-product-page {
        background-color: #f2f2ee;
        background-image: none;

        h4 {
            color: $black;
        }

        .about-sections {
            p {
                color: $black;
            }

            .viome-modern__product-about__section--measures {
                li {
                    color: $black;
                }
            }
        }
    }

    &.reverse {
        justify-content: flex-end;
        padding: 5.625rem 1.25rem;
    }

    > picture {
        width: 100%;
        max-width: 277px;
        margin: 2.5rem auto 0;
    }

    h4,
    p {
        color: $white;
    }

    h4 {
        max-width: 310px;
        margin-bottom: 1.875rem;
    }

    hr {
        height: 2px;
        flex-basis: 100%;
        margin: 1.875rem 0;
        background: $secondary;
    }

    .about-sections {
        .body {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;

            > p {
                flex: 0 0 88px;
                margin-right: 1.25rem;
            }
        }

        p {
            color: $white;
            font-size: 1rem;
            line-height: calc(20 / 16);

            b {
                font-family: $calibre-semibold;
                font-size: 1.125rem;
                line-height: calc(24 / 18);
            }
        }

        ul {
            flex: 1;
        }

        .viome-modern__product-about__section--measures {
            ul {
                margin-left: 0.9375rem;
            }

            li {
                margin-bottom: 0.3125rem;
                color: $white;
                list-style-type: disc;
            }
        }

        .viome-modern__product-about__section--included {
            picture {
                margin: 0 0 0.6875rem;
            }

            li {
                p {
                    margin-bottom: 1rem;
                }
            }
        }

        .viome-modern__product-about__section--collection {
            ul,
            li {
                display: flex;
            }

            ul {
                max-width: 165px;
                justify-content: space-between;
            }

            li {
                align-items: center;

                p {
                    margin-left: 0.5rem;
                }

                &:nth-child(even) {
                    p {
                        margin-right: 0.5rem;
                    }
                }
            }

            p {
                font-size: 1.125rem;
                line-height: calc(24 / 18);
            }
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        h4,
        .about-sections {
            max-width: 570px;
            margin: 0 auto;
        }

        h4 {
            width: 100%;
            align-items: flex-start;
            margin-bottom: 1.25rem;
        }

        hr {
            margin: 3.125rem 0 2.5rem;
        }

        > picture {
            margin: 2.5rem 13.75rem 0 auto;
        }

        .about-sections {
            .body {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;

                > p {
                    flex: 0 0 150px;
                    margin-right: 4.375rem;
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        height: 1106px;
        background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/55oJ6mdLLnHlWxKoa4GMyF/4860d22487ff986f3c769b8448a1f9db/group-24_3x__1_.jpg?w=1440&fm=webp&q=70');

        &.new-product-page {
            background-image: none;
        }

        &.reverse {
            display: grid;
            align-content: start;
            justify-content: center;
            padding-top: 10.625rem;
            grid-column-gap: 10.875rem;
            grid-template-columns: auto 277px;
            grid-template-rows: repeat(2, auto);

            h4 {
                max-width: 1110px;
                flex-basis: 100%;
                order: 0;
            }
        }

        > picture {
            align-self: start;
            margin: 0;
            grid-row: 2;
        }

        .about-sections {
            max-width: 659px;
            grid-column: 1;
            grid-row: 2;
        }
    }

    &--gi {
        height: 1609px;
        background: #252535;

        hr {
            background: rgb(231 231 234 / 28%);
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            height: 1531px;
        }

        @media only screen and (min-width: $breakpoint-lg) {
            height: 960px;
        }
    }
}

.aboutProduct {
    background-image: linear-gradient(
        to bottom,
        #e7f5f2 0%,
        #fff 325px,
        $white 100%
    );

    > .body {
        .heading-2 {
            max-width: 370px;
            padding: 95px 20px;
            margin: auto;
            font-size: 30px;
            letter-spacing: -0.38px;
            line-height: 1;
            text-align: center;
        }
    }

    &__top,
    &__bottom {
        position: relative;
        display: flex;
        flex-direction: column-reverse;
    }

    &__top {
        > .body {
            max-width: 370px;
            padding: 0 20px;
            margin: auto;

            .inline {
                @extend %absolute-x;

                width: auto !important;
            }

            > p {
                font-size: 18px;
                line-height: 1.33;
                text-align: center;
            }

            > ul {
                width: 328px;
                border-width: 2px;
                border-style: solid;
                border-top: 0;
                margin: 185px auto 170px;
                border-image-slice: 1;
                border-image-source: conic-gradient(
                    from 0.4turn,
                    #009e82,
                    #028e79 0.18turn,
                    #ca3550 0.44turn,
                    #6b49ae 0.77turn,
                    #009e82
                );
                box-shadow: 0 20px 40px 0 rgb(20 100 100 / 15%);

                li {
                    &:first-of-type {
                        display: flex;
                        height: 52px;
                        align-items: center;
                        justify-content: center;
                        background-image: linear-gradient(
                            to right,
                            #ca3550 0%,
                            #7348b7 50%,
                            #009e82 100%
                        );

                        p {
                            color: $white;
                            font-family: $calibre-semibold;
                            font-size: 20px;
                            line-height: 1;
                        }
                    }

                    &:not(:first-of-type) {
                        display: flex;
                        padding: 30px 35px 18px;
                        border-bottom: solid 1px #e7e7ea;

                        &::before {
                            display: block;
                            margin-right: 15px;
                            content: url('https://images.ctfassets.net/qk4l4jfatr3e/4mOzRJjOsodazJP8LAHo8c/8ce2cc26293bbcc7a6784dcdd8ff4077/path-copy-42__1_.svg');
                        }

                        p {
                            font-size: 16px;
                            line-height: 1.13;
                        }
                    }
                }
            }
        }
    }

    &__bottom {
        margin-top: 210px;

        > picture {
            margin: 120px auto;
        }

        > .body {
            &:not(.aboutProduct__text) {
                width: 185px;
                margin: auto;

                > p {
                    margin-bottom: 16px;
                    font-size: 16px;
                    line-height: 1.13;
                    text-align: center;
                }

                ul {
                    li {
                        display: flex;
                        height: 42px;
                        align-items: center;
                        padding-left: 23px;
                        background-color: #f4f4f5;
                        border-radius: 8px;

                        &::before {
                            display: block;
                            margin-right: 15px;
                            content: url('https://images.ctfassets.net/qk4l4jfatr3e/4mOzRJjOsodazJP8LAHo8c/8ce2cc26293bbcc7a6784dcdd8ff4077/path-copy-42__1_.svg');
                        }

                        + li {
                            margin-top: 12px;
                        }

                        p {
                            font-size: 16px;
                            line-height: 1;
                        }
                    }
                }
            }
        }

        .aboutProduct__text {
            position: relative;
            width: 215px;
            padding: 70px 30px;
            border: solid 1px #009e82;
            margin: auto;
            border-radius: 8px;

            .inline {
                @extend %absolute-x;

                top: -44px;
                width: auto !important;
            }

            p {
                font-size: 18px;
                line-height: 1.22;
                text-align: center;

                b {
                    font-size: 20px;
                }
            }
        }
    }

    &--supplements {
        .aboutProduct__top {
            > .body {
                .inline {
                    top: 225px;
                }
            }
        }

        @media only screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-lg - 1) {
            .aboutProduct__bottom {
                > .body {
                    &:not(.aboutProduct__text) {
                        width: 590px;

                        ul {
                            display: grid;
                            margin-top: 30px;
                            column-gap: 20px;
                            grid-template-columns: repeat(3, 182px);
                            grid-template-rows: repeat(2, auto);
                            row-gap: 20px;

                            li {
                                + li {
                                    margin-top: 0;
                                }
                            }
                        }
                    }
                }

                .aboutProduct__text {
                    width: 452px;
                    padding: 16px 40px;

                    .inline {
                        top: unset;
                        right: -40px;
                        bottom: -28px;
                        transform: none;
                    }

                    p {
                        width: 365px;
                        margin: auto;

                        br {
                            display: none;
                        }

                        b {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }

    &--prepro {
        .aboutProduct__top {
            > .body {
                .inline {
                    top: 257px;
                }

                > ul {
                    margin-top: 150px;
                }
            }
        }

        .aboutProduct__bottom {
            > .body {
                &:not(.aboutProduct__text) {
                    width: 100%;
                    max-width: 370px;
                    padding: 0 20px;

                    p {
                        text-align: left;

                        &.heading-4 {
                            margin-bottom: 10px;
                            font-size: 26px;
                        }

                        + .heading {
                            margin-top: 45px;
                        }
                    }
                }
            }

            .aboutProduct__text {
                width: 286px;
                padding: 18px 22px;

                .inline {
                    position: absolute;
                    top: 40px;
                    right: 65px;
                }

                p {
                    font-size: 18px;
                    line-height: 1.22;
                    text-align: center;

                    b {
                        font-size: 20px;
                    }

                    u {
                        display: none;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        background-image: linear-gradient(
            to bottom,
            #e7f5f2 0%,
            #fff 248px,
            $white 100%
        );

        > .body {
            .heading-2 {
                max-width: none;
                padding: 140px 0 90px;
                font-size: 48px;
                letter-spacing: -0.6px;
                line-height: 1.17;
            }
        }

        &__top {
            > .body {
                max-width: 600px;
                padding: 0;

                > ul {
                    margin: 200px auto 140px;
                }
            }
        }

        &--supplements {
            .aboutProduct__top {
                > .body {
                    .inline {
                        top: 331px;
                    }
                }
            }
        }

        &--prepro {
            > .body {
                .heading-2 {
                    width: 480px;
                }
            }

            .aboutProduct__top {
                > .body {
                    .inline {
                        top: 477px;
                    }

                    > ul {
                        margin-top: 200px;
                    }
                }
            }

            .aboutProduct__bottom {
                > .body {
                    &:not(.aboutProduct__text) {
                        width: 100%;
                        max-width: 370px;
                        padding: 0 20px;

                        p {
                            text-align: left;

                            &.heading-4 {
                                margin-bottom: 10px;
                                font-size: 26px;
                            }

                            + .heading {
                                margin-top: 45px;
                            }
                        }
                    }
                }

                .aboutProduct__text {
                    width: 353px;
                    padding: 18px 22px;

                    .inline {
                        position: absolute;
                        top: 40px;
                        right: 65px;
                    }

                    p {
                        font-size: 18px;
                        line-height: 1.22;
                        text-align: center;

                        b {
                            font-size: 20px;
                        }

                        u {
                            display: inline;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        background-image: linear-gradient(
            to bottom,
            #e7f5f2 0%,
            #fff 265px,
            $white 100%
        );

        &__top {
            > .body {
                max-width: none;

                > p {
                    width: 600px;
                    margin-top: 255px;
                }

                > ul {
                    position: absolute;
                    right: 50%;
                    margin: 0;
                    background: $white;
                }
            }
        }

        &__bottom {
            flex-direction: row-reverse;
            align-items: center;
            justify-content: center;

            > picture {
                margin: 0 90px;
            }

            > .body {
                margin: 0 !important;

                &:not(.aboutProduct__text) {
                    p {
                        text-align: left;
                    }
                }
            }

            .aboutProduct__text {
                margin-top: 80px !important;
            }
        }

        &--supplements {
            .aboutProduct__top {
                > .body {
                    .inline {
                        top: 422px;
                        transform: translateX(-80%);
                    }

                    > ul {
                        top: 260px;
                        transform: translateX(150%);
                    }
                }
            }
        }

        &--prepro {
            .aboutProduct__top {
                > .body {
                    .inline {
                        top: 534px;
                        transform: translateX(-5%);
                    }

                    > ul {
                        top: 180px;
                        transform: translateX(150%);
                    }
                }
            }

            .aboutProduct__bottom {
                width: 840px;
                margin: 210px auto 0;

                > .body {
                    &:not(.aboutProduct__text) {
                        padding-top: 165px;
                    }
                }

                > picture {
                    order: 0;
                    margin-left: 0;
                }

                .aboutProduct__text {
                    position: absolute;
                    top: 40px;
                    right: 40px;
                    margin-top: 0 !important;
                }
            }
        }
    }
}

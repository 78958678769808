@import '../../lib/styles/variables';
@import '../../lib/styles/common';

.how-it-works {
    > h4 {
        width: 275px;
        margin: 0 0 1rem 2.5rem;
    }

    .steps {
        .section {
            + .section {
                margin-top: 4.375rem;
            }

            &:nth-child(even) {
                padding-right: 2.5rem;

                .wrapper {
                    flex-direction: row-reverse;
                }
            }

            &:nth-child(odd) {
                padding-left: 2.5rem;

                picture {
                    text-align: right;
                }
            }

            &:nth-child(3) {
                picture {
                    padding-right: 2.5rem;
                }
            }

            .wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                .body {
                    width: 50%;
                    max-width: none;
                }
            }

            .heading-6 {
                margin-bottom: 0.5rem;
                color: $secondary-light;
                font-size: 1.5rem;
            }

            p {
                &:not(.heading) {
                    font-size: 1.125rem;
                    line-height: calc(24 / 18);
                }
            }
        }

        > .body {
            :global {
                .button {
                    width: 100%;
                    max-width: 330px;
                    padding: 0 1.25rem;
                }

                .footer {
                    text-align: center;
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        > h4 {
            width: 475px;
            margin: 0 auto 1em;
        }

        .steps {
            width: 450px;
            margin: auto;

            .section {
                &:nth-child(even) {
                    padding-right: 0;
                }

                &:nth-child(odd) {
                    padding-left: 0;
                }

                &:nth-child(2) {
                    picture {
                        justify-content: flex-start;
                    }
                }

                &:nth-child(3) {
                    picture {
                        padding-right: 7.5rem;
                    }
                }

                .wrapper {
                    > * {
                        width: 50%;
                        flex: 0 1 50%;
                    }

                    img {
                        max-width: 225px;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        .steps {
            display: grid;
            width: auto;
            max-width: 1110px;
            grid-column-gap: 4.6875rem;
            grid-template-columns: repeat(4, calc(885px / 4));

            .section {
                margin: 0;

                + .section {
                    margin-top: 0;
                }

                &:nth-child(even) {
                    .wrapper {
                        flex-direction: column-reverse;
                    }
                }

                &:nth-child(3) {
                    picture {
                        padding-right: 0;
                    }
                }

                .wrapper {
                    flex-direction: column-reverse;
                    align-items: center;

                    .body {
                        width: 100%;
                    }

                    > * {
                        width: auto;
                        flex-basis: auto;
                    }

                    img {
                        max-width: none;
                    }
                }

                picture {
                    height: 160px;
                    flex-basis: auto;
                    align-items: center;
                    margin-bottom: 1.125rem;
                }
            }

            > div {
                display: none;
            }
        }
    }
}

.how-it-works--home, .how-it-works--hi, .how-it-works--gi {
    .steps {
        .section {
            &:nth-child(4) {
                padding: 2.5rem 1.25rem;
                border-top: 1px solid $gray-dark;
                border-bottom: 1px solid $gray-dark;

                .wrapper {
                    justify-content: center;
                }

                .body {
                    max-width: 200px;
                }

                p {
                    margin-bottom: 0.25rem;
                    color: $secondary;

                    &:last-of-type {
                        @include viomeButton;

                        display: none;
                    }
                }

                picture {
                    flex: 0 0 7.1875rem;
                    margin: 0 1.25rem 0 0;
                }
            }
        }

        > .body {
            margin-top: 4.375rem;

            p {
                display: flex;
                justify-content: center;

                &:last-of-type {
                    @include viomeButton('secondary');
                }
            }
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            .section {
                &:nth-child(4) {
                    picture {
                        flex: 0 0 9.4375rem;
                    }
                }
            }
        }

        @media only screen and (min-width: $breakpoint-lg) {
            grid-gap: 3.75rem 13.9453rem;
            grid-template-columns: repeat(3, calc(885px / 4));
            grid-template-rows: repeat(2, auto);

            .section {
                &:nth-child(4) {
                    grid-column-start: span 3;

                    .wrapper {
                        flex-direction: row-reverse;
                    }

                    .body {
                        max-width: 425px;
                    }

                    p {
                        &:last-of-type {
                            display: flex;
                            margin: 1.25rem 0 0;
                        }
                    }
                }
            }
        }
    }
}

.how-it-works--hi, .how-it-works--gi {
    &.full {
        padding: 6.25rem 0 8.125rem;
    }

    .steps {
        .viome-modern__product-how__cta--product {
            width: 100%;
            max-width: 410px;
            padding: 0 2.5rem;

            h4 {
                margin-bottom: 1.875rem;
            }

            .wrapper {
                display: flex;
                flex-direction: column-reverse;

                .body {
                    width: 100%;
                    margin-top: 2.1875rem;
                    text-align: center;
                }
            }
        }
    }

    :global {
        .price {
            color: #146464;
        }

        .cta {
            justify-content: center;
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        &.full {
            padding-top: 9.375rem;
        }

        .steps {
            .viome-modern__product-how__cta--product {
                .wrapper {
                    h4 {
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        .steps {
            position: relative;
            grid-row-gap: 120px;

            .viome-modern__product-how__step--four {
                padding-left: 0;

                .body {
                    p {
                        &:last-of-type {
                            a {
                                display: none;
                            }
                        }
                    }
                }

            }

            .viome-modern__product-how__cta--product {
                position: absolute;
                top: 220px;

                h4, picture {
                    display: none;
                }
            }
        }

        :global {
            .priceWrapper {
                display: none;
            }

            .cta {
                justify-content: flex-start;
            }
        }
    }
}

.how-it-works--gi-plan, .how-it-works--gi {
    .steps {
        .viome-modern__product-how__step--one {
            .inline {
                display: none
            }

            a {
                @include viomeButton;
            }
        }
    }
}

.how-it-works--gi {
    @media only screen and (min-width: $breakpoint-lg) {
        .steps {
            .viome-modern__product-how__step--one {
                .inline {
                    display: block;
                    margin-top: 20px;
                }

                :global {
                    .cta {
                        flex-wrap: nowrap;
                    }

                    .button {
                        flex-shrink: 0;
                    }
                }
            }
        }
    }
}

.how-it-works--gi-plan {
    border-top: solid 1px $gray-dark;

    > h4 {
        font-size: 30px;
    }

    &.full {
        padding-top: 90px;
    }

    .steps {
        .inline {
            margin-top: 4.375rem;
        }

        > .body {
            :global {
                .cta {
                    justify-content: center;
                    margin: 0;
                }
            }
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            .viome-modern__product-how__step--one {
                .inline {
                    display: block;
                    margin-top: 20px;
                }
            }

            > .body {
                display: none;
            }

            :global {
                .cta {
                    margin: 0;
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        > h4 {
            width: 1110px;
        }
    }
}

.how-it-works--mh {
    &.full {
        padding-bottom: 9.375rem;
        border-bottom: 1px solid $gray-dark;
    }

    .steps {
        .section {
            .heading-6 {
                color: $primary;
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        > h4 {
            width: 1000px;
        }

        .steps {
            display: flex;
            max-width: 1000px;
            justify-content: space-between;

            .section {
                width: 20%;

                .wrapper {
                    align-items: flex-start;
                }
            }
        }
    }
}

/* stylelint-disable plugin/declaration-block-no-ignored-properties */
@import 'lib/styles/variables';

.footer {
    padding: 1.25rem;
    background-color: $black;
    color: $white;

    hr {
        margin: 1rem 0 1.25rem;
    }

    .header {
        margin-bottom: 1.25rem;
        font-size: 1.25rem;
        font-weight: 600;
    }

    .disclaimer {
        font-size: 0.75rem;
        line-height: 1.5;
    }

    .bottom {
        font-size: 0.875rem;
        line-height: 1.5;
    }

    .links {
        display: flex;
        align-items: baseline;
        gap: 1.5rem;

        img {
            filter: invert(1);
        }
    }
}

@media only screen and (min-width: $breakpoint-tablet) {
    .footer {
        padding: 2.5rem;

        .header {
            margin-bottom: 2.5rem;
        }

        .bottom {
            display: flex;
            align-items: baseline;
            gap: 1.5rem;
        }
    }
}

@media only screen and (min-width: $breakpoint-lg-new) {
    .footer {
        padding: 5rem;

        hr {
            margin: 2rem 0 2.5rem;
        }

        .header {
            margin-bottom: 5rem;
        }
    }
}

@import 'lib/styles/variables';

.careers__hiring_container {
    position: relative;
    width: 100%;
    padding-bottom: 40%;
    background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/1VDh7ZC9VW3SAdjDWWltHK/df01e5c7ddab339c93887b5e91de3b3f/kayak.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .careers__hiring_text {
        position: absolute;
        top: 20%;
        right: 0;
        bottom: 0;
        left: 0;

        h1, p {
            width: 80%;
            padding: 0;
            margin: 0 auto;
            color: $white;
        }

        h1 {
            margin-bottom: 10px;
            font-family: $circularair-light;
        }

        p {
            font-size: 18px;
        }
    }

    @media screen and (min-width: 768px) {
        .careers__hiring_text {
            text-align: center;
        }
    }
}

.listings {
    max-width: 370px;
    padding: 0 20px;
    margin: 100px auto;

    .medium_utility_label,
    .small_utility_label {
        margin-right: 20px;
        color: $gray2;
        font: normal 700 16px/1.4 $circularair-light;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .large_category_label,
    .medium_category_label,
    .small_category_label {
        color: $gray2;
        font: normal 700 24px/1.4 $circularair-light;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .horizontal_line {
        width: 80px;
        height: 1px;
        border-color: #e2e2e2;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        margin: 20px 0;
    }

    .dropdown__container {
        position: relative;
        display: inline-block;
        max-width: 100%;
        margin-right: 20px;
        line-height: 40px;
        outline: none;

        .dropdown__button_arrow {
            position: absolute;
            top: 50%;
            right: 15px;
            display: inline-block;
            padding: 4px;
            border: solid #9696a2;
            border-width: 0 1px 1px 0;
            margin-top: -8px;
            content: '';
            transform: rotate(45deg);
        }

        .dropdown__button_arrow_closed {
            position: absolute;
            top: 65%;
            right: 15px;
            display: inline-block;
            padding: 4px;
            border: solid #9696a2;
            border-width: 0 1px 1px 0;
            margin-top: -8px;
            content: '';
            transform: rotate(225deg);
        }

        .dropdown__button_placeholderA {
            margin-right: 90px;
        }

        .dropdown__button_placeholderB {
            min-width: 220px;
            margin-right: 50px;
            text-align: left;
        }

        .dropdown__options {
            z-index: 1;
            width: auto !important;
            border: 1px solid #dcdcdc;
            background: $white;
            border-radius: 3px;
            color: #515357;
            font: normal 400 16px/1.8 $circularair-light;
            letter-spacing: 0;
            text-align: left;
            text-transform: none;
            white-space: nowrap;

            .dropdown__option {
                position: relative;
                padding: 8px 20px 8px 40px;
                cursor: pointer;

                &:hover {
                    background-color: rgb(65 198 236 / 14%);
                    color: #495c68;
                }

                &[aria-selected='true'] {
                    background-color: rgb(65 198 236 / 14%);
                    color: #495c68;

                    &::before {
                        position: absolute;
                        top: 50%;
                        left: 15px;
                        display: inline-block;
                        width: 9px;
                        height: 16px;
                        border-right: 3px solid $blue-light;
                        border-bottom: 3px solid $blue-light;
                        margin-top: -10px;
                        content: '';
                        transform: rotate(45deg);
                    }
                }
            }
        }
    }

    .dropdown__button,
    .template_btn_submit {
        position: relative;
        display: inline-block;
        overflow: hidden;
        width: auto !important;
        max-width: 100%;
        height: auto;
        padding: 5px 15px;
        padding-right: 5px;
        border: 1px solid #dfe0e4;
        margin: 0;
        appearance: none;
        background: #ebecf0;
        border-radius: 3px;
        color: #555659;
        cursor: pointer;
        font: normal 700 14px/1.4 $circularair-light;
        font-size: 14px;
        letter-spacing: 2px;
        line-height: 1.33;
        text-align: center;
        text-overflow: ellipsis;
        text-transform: uppercase;
        user-select: none;
        vertical-align: middle;
        white-space: nowrap;
    }

    .posting_category_title {
        margin-top: 40px;
    }

    .posting {
        position: relative;
        display: flex;
        width: 100%;
        flex-direction: row-reverse;
        justify-content: space-between;
        margin: 10px 0;

        .posting_title,
        .posting_title:visited {
            color: #737578;
            text-decoration: none;
        }

        .posting_title:hover h5 {
            color: $blue-light;
        }

        h2, h5 {
            display: block;
            padding: 0;
            margin: 0;
            color: #2c2d3b;
            font: normal 400 36px/1.4 $circularair-light;
            letter-spacing: 0;
            text-transform: none;
        }

        h5 {
            overflow: hidden;
            min-height: 33px;
            font-size: 24px;
            transition: color 150ms;
        }

        .posting_categories {
            height: 30px;
            vertical-align: top;
        }

        .posting_category {
            display: inline-block;
            margin-right: 15px;
        }

        .posting_apply {
            height: 40px;
            text-align: right;

            a {
                min-width: 100px;
                padding: 5px 15px;
                border: 0;
                background-color: $blue-light;
                border-radius: 5px;
                color: $white;
                text-decoration: none;
            }

            a:hover {
                box-shadow: 0 8px 12px 0 rgb(65 198 236 / 50%);
            }
        }
    }

    .small_category_label {
        font-size: 12px;
        line-height: 1.4;
    }
}

.ender {
    min-height: 120px;
}

@media only screen and (min-width: $breakpoint-tablet) {
    .listings {
        max-width: 1110px;
    }
}

@import '../../lib/styles/variables';
@import '../../lib/styles/common';

.Product {
    gap: 4.375rem;

    &__wrapper {
        display: flex;
        max-width: none;
        flex-direction: column;
        padding: 0;
        gap: 4.375rem;

        :global {
            .Carousel {
                .carousel-slider {
                    overflow: visible;

                    .control-arrow {
                        display: none;
                        padding: 0;
                        opacity: .4;

                        &:hover, &:focus {
                            background: transparent;
                            opacity: 1;
                        }

                        &::before {
                            display: block;
                            border: 0;
                            content: url('https://images.ctfassets.net/qk4l4jfatr3e/6fgJTfzKEESXOSINJMigwT/9d35179ae328d31efad9c5f63e6624e3/gray_pointer_-_carousel.svg');
                        }

                        &.control-prev {
                            left: -6.25rem;
                        }

                        &.control-next {
                            right: -6.25rem;
                        }
                    }
                }

                .control-dots {
                    bottom: -35px;
                    left: 50%;
                    display: flex;
                    justify-content: center;
                    transform: translateX(-50%);
                }
            }
        }
    }

    &.full {
        padding-bottom: 5rem;
    }

    .viome-modern__product-module__top {
        width: 100%;

        .body {
            width: 100%;
            max-width: 370px;
            padding: 0 1.25rem;
            margin: auto;
        }

        hr {
            width: 100%;
            height: 8px;
            border: 0;
            margin: 0 0 1rem;
        }

        p {
            font-family: $calibre-semibold;

            &:not(.heading) {
                font-size: 1.875rem;
                line-height: 1;
                text-align: left;
            }

            &.heading-2 {
                margin: 0;
                font-size: 5rem;
                line-height: 1;
                text-align: left;
            }

            &.heading-4 {
                margin: -10px 0 50px;
                font-family: $calibre;
                font-size: 1.5rem;
                font-weight: normal;
                letter-spacing: 0.6px;
                line-height: 1.5;
            }

            b {
                font-size: 2.5rem;
            }
        }

        picture {
            margin-bottom: 3.75rem;

            img {
                width: 100%;
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        &__wrapper {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;

            :global {
                .Carousel {
                    .carousel-slider {
                        .control-arrow {
                            display: block;
                        }
                    }
                }
            }
        }

        &.full {
            padding: 10.625rem 0 5rem;
        }

        .viome-modern__product-module__top {
            width: 100%;
            padding-left: calc((165/1440) * 100%);

            .wrapper {
                flex-direction: row;
                align-items: flex-start;
                justify-content: space-between;
            }

            .body {
                width: 100%;
                padding: 0;
                margin: 0 calc((178/1440) * 100%) 0 0;
            }

            hr {
                width: 330px;
            }

            picture {
                margin-bottom: 0;
            }
        }
    }
}

@import 'lib/styles/variables';
@import 'lib/styles/common';

.wrapper {
    .cta {
        display: inline-flex;
        width: 100%;
        min-height: 42px;
        align-items: center;
        justify-content: center;
        padding: 0.5rem 1.5rem;
        font-family: $font-TWKLausanne;
        font-weight: 500;
        line-height: 1.5;
        text-align: center;
        text-decoration: none;

        &.autoWidth {
            width: auto;
        }

        &.Standard,
        &.ForwardArrow-Standard,
        &.Custom {
            &.Dark {
                color: $white;

                &:hover {
                    color: $white;
                }
            }
        }

        &.Standard,
        &.ForwardArrow-Standard {
            &.Dark {
                background-color: $black;

                &:hover {
                    background-color: $brand-green-3;
                }

                &:focus {
                    border: 2px $brand-green-3 solid;
                }
            }
        }

        &.Secondary,
        &.ForwardArrow-Secondary {
            &.Light {
                background-color: $white;
                color: $black;

                &:hover {
                    background-color: $brand-green-3;
                    color: $white;
                }

                &:focus {
                    border: 2px $brand-green-2 solid;
                    background-color: $white;
                    color: $black;
                }
            }
        }

        &.Transparent,
        &.Standard,
        &.ForwardArrow-Standard,
        &.ForwardArrow-Transparent {
            &.Light {
                border: 1px $black solid;
                background-color: $white;
                color: $black;

                &:hover {
                    border-color: $brand-green-3;
                    color: $brand-green-3;
                }

                &:focus {
                    border: 2px $brand-green-2 solid;
                    color: $black;
                }
            }
        }

        &.Transparent,
        &.Secondary,
        &.ForwardArrow-Secondary {
            &.Dark {
                border: 1px $white solid;
                background-color: $black;
                color: $white;

                &:hover {
                    border-color: $brand-green-3;
                }

                &:focus {
                    border: 2px $brand-green-2 solid;
                    background-color: $black;
                    color: $white;
                }
            }
        }

        &.Transparent,
        &.ForwardArrow-Transparent {
            &.Dark,
            &.Light {
                background-color: transparent;
            }
        }

        &.ForwardArrow-Transparent {
            &.Dark,
            &.Light {
                padding: 0;
            }

            &.Dark {
                &:hover {
                    border-color: $brand-green-3;
                }
            }
        }

        &.ForwardArrow,
        &.Plus {
            justify-content: space-evenly;
            padding: 0;

            &.Light {
                color: $black;

                &:hover {
                    color: $brand-green-3;
                }

                &:focus {
                    color: $brand-green-2;
                }
            }

            &.Dark {
                color: $white;

                &:hover {
                    color: $gray-4;
                }

                &:focus {
                    color: $brand-green-2;
                }
            }
        }

        &.ForwardArrow,
        &.ForwardArrow-Standard,
        &.ForwardArrow-Secondary,
        &.ForwardArrow-Transparent,
        &.Plus {
            span {
                display: flex;
                align-items: baseline;
                font-size: 1rem;
                white-space: nowrap;
            }
        }

        &.ForwardArrow {
            &.Dark {
                margin-left: 0;
                color: $white;
            }
        }

        &.Plus {
            span {
                align-items: center;
            }

            svg {
                width: 14px;
                height: 14px;
            }
        }

        &.hoverOverride {
            &:hover {
                color: $gray-2;
            }
        }

        &.centered {
            > span {
                justify-content: center;
            }
        }

        &.Inactive {
            &.Light {
                background-color: $gray-3;
                color: $white;

                &:hover,
                &:focus {
                    background-color: $gray-3;
                    color: $white;
                }

                &:focus {
                    border: 2px $brand-green-2 solid;
                }
            }

            &.Dark {
                background-color: $gray-6;
                color: $gray-5;

                &:hover,
                &:focus {
                    background-color: $gray-6;
                    color: $gray-5;
                }

                &:focus {
                    border: 2px $brand-green-2 solid;
                }
            }
        }

        &.inside {
            span {
                display: inline-flex;
                width: auto;
                align-items: center;
                white-space: nowrap;

                span {
                    &::before {
                        display: block;
                        width: 72px;
                        height: 1px;
                        margin: 0 1.5rem;
                        background-color: $white;
                        content: '';
                    }
                }
            }
        }

        @extend %font-overrides;
    }

    svg {
        fill: currentcolor;
    }

    .priceWrapper {
        .price {
            &.price {
                font-size: 1.0625rem;

                @media only screen and (min-width: $breakpoint-tablet) {
                    font-size: 1.125rem;
                }

                &--original {
                    &::after {
                        top: 50%;
                        right: -4px;
                        left: -4px;
                        color: $action-red;
                    }
                }

                &--current {
                    font-weight: 600;

                    .currency {
                        font-weight: 300;
                    }

                    .suffix {
                        font-size: inherit;
                    }
                }
            }
        }

        + .cta {
            margin-top: 1rem;
        }

        &.Dark {
            .taxes {
                color: $white;
            }
        }
    }

    &.larger {
        .priceWrapper {
            .price {
                &.price {
                    &--original,
                    &--current {
                        font-size: 2.25rem;
                    }

                    &--original {
                        + .price--current {
                            color: $brand-green-3;
                        }
                    }
                }

                .suffix {
                    font-size: 1.125rem;
                }

                .currency {
                    font-size: 0.875rem;
                }
            }

            &.Dark {
                .price {
                    &.price {
                        &--original,
                        &--current {
                            color: $white;
                        }
                    }

                    .currency {
                        color: $white;
                    }
                }

                .taxes {
                    color: $white;
                }
            }
        }
    }

    .showInstallment {
        flex-basis: 100%;
        margin-top: 1.875rem;
        color: $gray-5;
        font-size: 0.75rem;
        line-height: 1.5;

        p,
        a {
            font-size: 0.75rem;
        }

        .showInstallment__image {
            display: inline-block;
            width: 54px;
            margin: 0 0.125rem;
            line-height: 1;
            vertical-align: middle;
        }

        svg {
            fill: hsl(258deg 92% 54%);
        }

        strong {
            font-family: $calibre;
            letter-spacing: 0.05rem;
        }

        &.Dark {
            color: $white;

            svg {
                fill: $white;
            }
        }
    }

    .salesMessage {
        padding: 0.5rem 0.75rem;
        border: 2px dotted $brand-green-2;
        border-radius: 0.3125rem;
        margin: 1rem 0;

        p {
            color: $brand-green-2;
            font-size: 0.875rem;

            + p {
                margin-top: 0;
            }
        }

        &.Dark {
            border-color: $white;

            p {
                color: $white;
            }
        }

        + .cta {
            width: 100%;
            margin-right: 0;
        }

        :global {
            .text {
                display: flex;
                flex-wrap: wrap;
                column-gap: 0.375rem;

                p {
                    + p {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: $breakpoint-tablet - 1px) {
        .cta {
            &.ForwardArrow-Standard,
            &.ForwardArrow-Secondary {
                width: 100%;

                span {
                    justify-content: center;
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        .cta {
            width: auto;

            &.full,
            &.wide {
                width: 100%;
            }

            &.full {
                max-width: 375px;
            }
        }

        &.right,
        &.left {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }

        &.left {
            justify-content: flex-start;

            .cta,
            .salesMessage {
                margin-left: auto;
            }
        }

        &.right {
            flex-direction: row-reverse;
            justify-content: flex-end;

            .cta,
            .priceWrapper {
                flex: 0 0 auto;
            }

            .priceWrapper {
                order: 1;
            }

            .cta {
                width: auto;
                order: 2;
                margin-right: 1.75rem;
            }

            .salesMessage {
                flex: 1 1;
                order: 0;
                margin: 0 0 0 1.75rem;
            }

            .showInstallment {
                order: 4;
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg-new) {
        &.right {
            .cta {
                order: 3;
            }

            .salesMessage {
                width: 100%;
                order: 0;
            }

            .priceWrapper {
                order: 3;
            }

            .showInstallment {
                order: 4;
            }
        }
    }
}

@import 'lib/styles/variables';

.text {
    .heading {
        width: 100%;
        font-family: $calibre-medium;
        font-weight: 500;

        &-1 {
            font-size: 3.125rem;
            letter-spacing: -0.0313rem;
            line-height: 1.2;
        }

        &-2 {
            font-size: 2.5rem;
            letter-spacing: -0.0313rem;
            line-height: 1.25;
        }

        &-3 {
            font-size: 1.875rem;
            letter-spacing: -0.0431rem;
            line-height: 1.27;
        }

        &-4 {
            font-size: 1.375rem;
            letter-spacing: -0.0413rem;
            line-height: 1.36;
        }

        &-5 {
            font-size: 1.0625rem;
            letter-spacing: -0.0375rem;
            line-height: 1.29;
        }

        &-6 {
            font-family: $calibre;
            font-size: 1.0625rem;
            font-weight: normal;
            letter-spacing: -0.0375rem;
            line-height: 1.18;
        }
    }

    .headings {
        &.Biggest {
            span {
                font-size: 120%;
            }
        }

        &.Bigger {
            span {
                font-size: 110%;
            }
        }

        &.Smaller {
            span {
                font-size: 90%;
            }
        }

        &.Smallest {
            span {
                font-size: 80%;
            }
        }
    }

    .gradient {
        padding: 0 1%;
        -webkit-background-clip: text;
        -webkit-box-decoration-break: clone;
        -webkit-text-fill-color: transparent;

        &--Blue {
            @extend %blue-gradient;
        }

        &--Green {
            @extend %green-gradient;
        }

        &--Purple {
            @extend %purple-gradient;
        }

        &--Rainbow {
            @extend %rainbow-gradient;
        }
    }

    .calibre {
        font-family: $calibre;

        &--medium {
            font-family: $calibre-medium;
        }

        &--semibold {
            font-family: $calibre-semibold;
        }
    }

    .tiempos {
        font-family: $tiempos;
    }

    .flex {
        display: flex;
        column-gap: 1.25rem;
    }

    .border,
    .backgroundColor {
        display: flex;
        align-items: center;
    }

    .border {
        height: 65px;
        justify-content: space-between;
        padding: 0.375rem 1.125rem 0;
        border: 1px solid;
        margin-bottom: 0.3125rem;

        img {
            margin-top: -0.3125rem;
        }
    }

    .backgroundColor {
        padding: 0.75rem 0.9375rem 0.4375rem;
        margin-bottom: 0.875rem;
    }

    .Top {
        align-items: flex-start;
    }

    .Middle {
        align-items: center;
    }

    .flex.Middle {
        justify-content: center;
    }

    .Bottom {
        align-items: flex-end;
    }

    .inline {
        hr {
            border-width: 1px;
            border-style: solid;
            margin: 0;
        }

        ol,
        ul {
            li {
                b {
                    font-family: $calibre-semibold;
                }
            }
        }
    }

    p {
        &.Biggest {
            font-size: 1.5rem;
        }

        &.Bigger {
            font-size: 1.25rem;
        }

        &.Smaller {
            font-size: 0.875rem;
        }

        &.Smallest {
            font-size: 0.625rem;
            letter-spacing: -0.0375rem;
            line-height: 1.2;
        }

        > img {
            margin-bottom: 0.375rem;
        }
    }

    &.paris {
        hr {
            margin: 0 0 0.625rem;
            background-color: $white;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 0;

            li {
                display: flex;
                width: 48%;
                height: 56px;
                border-bottom: 1px solid $white;
                list-style-type: none;

                p {
                    margin: 0;
                    font-family: $calibre-medium;
                }
            }
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            ul {
                li {
                    width: 23%;
                }
            }
        }
    }

    &.hr {
        hr {
            margin: 0;
        }
    }

    hr {
        margin: 1.875rem 0;
        background-color: $primary;
    }

    span {
        a,
        i,
        b,
        strong,
        em {
            font-family: inherit;
        }
    }

    ol,
    ul {
        li {
            + li {
                margin-top: 0.3125rem;
            }
        }

        &.noBullets {
            li {
                list-style: none;

                + li {
                    margin-top: 0.625rem;
                }
            }
        }

        &.noMargins {
            margin-left: 0;
        }

        &.customBullets {
            margin: 5px 0 0;

            li {
                display: flex;
                align-items: flex-start;

                svg {
                    flex: 0 0 auto;
                    margin: 0.25rem 0.625rem 0 0;
                }

                &.circleCheck {
                    svg {
                        margin-top: -0.125rem;
                    }
                }
            }
        }

        &.borderBottom {
            margin-left: 0;

            li {
                padding: 17px 0;
                border-bottom: 1px solid $white;

                + li {
                    margin: 0;
                }
            }
        }

        @media only screen and (min-width: $breakpoint-lg-new) {
            &.splitList {
                columns: 2;

                li {
                    break-inside: avoid;
                    page-break-inside: avoid;
                }
            }
        }
    }

    > br {
        &:last-child {
            display: none;
        }
    }

    /* Legacy Pricing Support */
    :global {
        .priceModule {
            margin-top: 1.25rem;

            .price {
                position: relative;
                display: inline-block;
                width: auto;
                font-size: 24px;

                &--current {
                    color: $secondary;
                    font-family: $calibre-semibold;
                }

                @media only screen and (min-width: $breakpoint-lg-new) {
                    font-size: 1.875rem;
                }
            }

            .cta {
                justify-content: center;

                a {
                    text-decoration: none;

                    &:hover {
                        background-color: $primary;
                        color: $white;
                    }
                }

                ol {
                    margin-left: 0;
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        p {
            &.Biggest {
                font-size: 1.75rem;
            }

            &.Bigger {
                font-size: 1.5rem;
            }

            &.Smaller {
                font-size: 1rem;
            }

            &.Smallest {
                font-size: 0.75rem;
            }
        }
    }
}

.centered {
    text-align: center;

    @media only screen and (min-width: $breakpoint-tablet) {
        max-width: 570px;
        margin-right: auto;
        margin-left: auto;
    }

    @media only screen and (min-width: $breakpoint-lg-new) {
        max-width: 610px;
    }

    @media only screen and (min-width: $breakpoint-xl-new) {
        max-width: 1100px;
    }
}

.left {
    text-align: left;

    &--tablet,
    &--desktop {
        text-align: center;
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        width: 100%;
        max-width: 450px;

        &--tablet {
            text-align: left;
        }
    }

    @media only screen and (min-width: $breakpoint-lg-new) {
        max-width: 610px;

        &--desktop {
            text-align: left;

            &.full {
                max-width: 610px;
            }
        }
    }

    @media only screen and (min-width: $breakpoint-xl-new) {
        max-width: 840px;

        &--desktop {
            &.full {
                max-width: 840px;
            }
        }
    }

    ul ~ table {
        padding-left: 0.9375rem;
    }

    &.full {
        width: 100%;
        max-width: none;

        table {
            display: block;
            width: inherit;
            max-width: fit-content;
            border-collapse: collapse;
            overflow-x: auto;
            white-space: normal;
        }

        table th p {
            color: $gray2;
        }

        table th,
        table td {
            padding: 0.5rem 1.5rem;
            border: 1px solid $gray4;
            vertical-align: top;
        }

        @media only screen and (min-width: $breakpoint-sml) {
            table {
                display: table;
                max-width: inherit;
            }
        }
    }

    :global {
        .priceModule {
            .priceWrapper {
                text-align: left;
            }

            .cta {
                justify-content: flex-start;
            }
        }
    }
}

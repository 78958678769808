/* stylelint-disable media-feature-name-no-vendor-prefix */
@import '../../lib/styles/variables';
@import '../../lib/styles/common';

.hero {
    > .section {
        background-color: $secondary;
        background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/2w9BCVHPUGa19QCg5xdN4U/a8da50de2acf14f867d75e8edee7b013/viome-home-hero-recoded_sm_3x.jpg?w=370&fm=webp&q=70');
        background-position: center bottom;
        background-size: cover;

        > .wrapper {
            display: flex;
            height: 1070px;
            flex-direction: column;
            justify-content: space-between;
            padding: 4.0625rem 0 4.375rem;

            .body {
                .heading-1 {
                    margin-bottom: 1.75rem;
                    font-family: $tiempos;
                    font-size: 2.5rem;
                }

                p, .heading {
                    color: $white;
                    text-align: center;
                }

                p {
                    &:not(.heading) {
                        width: 100%;
                        max-width: 300px;
                        margin: auto;
                        font-size: 1.25rem;
                        line-height: calc(26 / 20);
                    }

                    &:last-of-type {
                        @include viomeButton('dark');

                        display: flex;
                        justify-content: center;
                        margin: 2.8125rem auto 0;
                    }
                }
            }
        }

        @media only screen and (-webkit-min-device-pixel-ratio: 2),
            only screen and (-o-min-device-pixel-ratio: 2/1),
            only screen and (min-resolution: 192dpi),
            only screen and (min-resolution: 2dppx) {
            background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/2w9BCVHPUGa19QCg5xdN4U/a8da50de2acf14f867d75e8edee7b013/viome-home-hero-recoded_sm_3x.jpg?w=740&fm=webp&q=70');
        }

        @media only screen and (-webkit-min-device-pixel-ratio: 3),
            only screen and (-o-min-device-pixel-ratio: 3/1),
            only screen and (min-resolution: 384dpi),
            only screen and (min-resolution: 3dppx) {
            background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/2w9BCVHPUGa19QCg5xdN4U/a8da50de2acf14f867d75e8edee7b013/viome-home-hero-recoded_sm_3x.jpg?fm=webp&q=70');
        }
    }

    .animate {
        color: $white;
        font-size: 1.25rem;
        line-height: calc(26 / 20);

        > div {
            display: flex !important;
            overflow: hidden !important;
            height: 24px !important;
            justify-content: center;

            div {
                font-family: $calibre-semibold;
                line-height: calc(24 / 20);
                text-decoration-color: $secondary-light;
                text-decoration-line: underline;
                text-decoration-thickness: 0.1875rem;
            }
        }
    }

    :global {
        .Carousel {
            width: 363px;
            margin-top: -1.25rem;
        }
    }

    @media screen and (min-width: $breakpoint-tablet) {
        > .section {
            background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/6JXI5Mv5jGZCa1ptSHhiUs/791a1c09ccf3f756ffc3914796f19466/viome-home-hero-recoded_md_3x.jpg?w=768&fm=webp&q=70');

            > .wrapper {
                height: 1132px;
                padding: 6.25rem 0 7.8125rem;

                .body {
                    .heading-1 {
                        margin-bottom: 1.875rem;
                    }

                    p {
                        &:not(.heading) {
                            max-width: 355px;
                            font-size: 1.5rem;
                            line-height: calc(30 / 24);
                        }
                    }

                    picture {
                        padding-right: 1.25rem;
                    }
                }
            }

            @media only screen and (-webkit-min-device-pixel-ratio: 2),
                only screen and (-o-min-device-pixel-ratio: 2/1),
                only screen and (min-resolution: 192dpi),
                only screen and (min-resolution: 2dppx) {
                background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/6JXI5Mv5jGZCa1ptSHhiUs/791a1c09ccf3f756ffc3914796f19466/viome-home-hero-recoded_md_3x.jpg?w=1536&fm=webp&q=70');
            }

            @media only screen and (-webkit-min-device-pixel-ratio: 3),
                only screen and (-o-min-device-pixel-ratio: 3/1),
                only screen and (min-resolution: 384dpi),
                only screen and (min-resolution: 3dppx) {
                background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/6JXI5Mv5jGZCa1ptSHhiUs/791a1c09ccf3f756ffc3914796f19466/viome-home-hero-recoded_md_3x.jpg?fm=webp&q=70');
            }
        }

        .animate {
            font-size: 1.5rem;
            line-height: calc(30 / 24);
        }
    }

    @media screen and (min-width: $breakpoint-lg) {
        > .section {
            background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/6ieUDZ9maKHDQTnNmIUYqG/eb9bd17452e0251e348fb96ce184b7e8/viome-home-hero-recoded_lg_3x.jpg?w=1200&fm=webp&q=70');

            > .wrapper {
                width: 860px;
                height: 720px;
                flex-direction: revert;
                align-items: center;
                padding: 0;

                .body {
                    width: 350px;

                    .heading-1 {
                        font-size: 3.125rem;
                    }

                    p, .heading {
                        text-align: left;
                    }

                    p {
                        &:not(.heading) {
                            display: inline;
                        }

                        &:last-of-type {
                            display: block;
                        }
                    }
                }
            }

            @media only screen and (-webkit-min-device-pixel-ratio: 2),
                only screen and (-o-min-device-pixel-ratio: 2/1),
                only screen and (min-resolution: 192dpi),
                only screen and (min-resolution: 2dppx) {
                background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/6ieUDZ9maKHDQTnNmIUYqG/eb9bd17452e0251e348fb96ce184b7e8/viome-home-hero-recoded_lg_3x.jpg?w=2400&fm=webp&q=70');
            }

            @media only screen and (-webkit-min-device-pixel-ratio: 3),
                only screen and (-o-min-device-pixel-ratio: 3/1),
                only screen and (min-resolution: 384dpi),
                only screen and (min-resolution: 3dppx) {
                background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/6ieUDZ9maKHDQTnNmIUYqG/eb9bd17452e0251e348fb96ce184b7e8/viome-home-hero-recoded_lg_3x.jpg?fm=webp&q=70');
            }
        }

        .hero-carousel {
            margin: -4rem 0 0;
        }

        .animate {
            display: inline-block !important;
            width: 250px !important;

            > div {
                justify-content: flex-start;
            }
        }
    }
}

@import '../../lib/styles/variables';
@import '../../lib/styles/common';

.ViomeCoreTech {
    margin-top: 6.25rem;

    h4 {
        font-size: 1.875rem;
    }

    > h4, > .body {
        width: auto;
        max-width: 330px;
        margin: 0 auto 1.875rem;
    }

    > .body {
        p {
            text-align: center;

            &.heading-2 {
                margin-bottom: 40px;
                font-size: 30px;
                letter-spacing: -0.37px;
                line-height: 1.2;
            }

            &:not(.heading) {
                font-family: $calibre-medium;
                font-size: 1.25rem;
                letter-spacing: -0.46px;
                line-height: 1.3;
            }
        }
    }

    .techologies {
        display: flex;
        width: 100%;
        max-width: 330px;
        flex-direction: column-reverse;
        margin: 3.125rem auto 0;
    }

    .viome-modern__home__core-technology-item {
        position: relative;
        height: 200px;
        margin: 0 0 1.875rem;

        .body {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            flex-direction: column;
        }

        .heading {
            width: 220px;
            padding: 1.875rem 0 1.875rem 1.875rem;
            color: $white;
            font-family: $calibre-semibold;
            font-size: 1.25rem;
            font-weight: 600;
            line-height: 1;
        }

        img {
            width: 100%;
        }

        picture {
            height: 100%;
        }
    }

    .viome-modern__home__core-technology-cta {
        p {
            @include viomeButton('secondary');

            a {
                width: 310px;
            }
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        > h4, > .body {
            max-width: 580px;
            text-align: center;
        }

        > .body {
            p {
                &.heading-2 {
                    margin-bottom: 45px;
                    font-size: 48px;
                    letter-spacing: -0.6px;
                    line-height: 1.17;
                }

                &:not(.heading) {
                    font-size: 26px;
                    letter-spacing: -0.6px;
                    line-height: 1.23;
                }
            }
        }

        .viome-modern__home__core-technology-cta {
            margin-top: 50px;

            p {
                a {
                    width: 330px;
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        > .body {
            max-width: 1110px;

            p {
                text-align: left;

                &.heading-2 {
                    max-width: 620px;
                }

                &:not(.heading) {
                    max-width: 670px;
                }
            }
        }

        .techologies {
            display: grid;
            max-width: 1110px;
            justify-content: center;
            grid-gap: 1.875rem 3.75rem;
            grid-template-columns: repeat(3, 330px);
            grid-template-rows: repeat(2, auto);

            > .body {
                grid-column: 2;
                grid-row: 2;
            }
        }

        .viome-modern__home__core-technology-item {
            grid-row: 1;

            &--one {
                grid-column: 1
            }

            &--three {
                grid-column: 3
            }
        }

        .viome-modern__home__core-technology-cta {
            margin-top: 0;
        }
    }
}

@import '../../../lib/styles/variables';

.video {
    width: 100%;

    &__header {
        width: 100%;
        margin: 0 auto 2.8125rem;
        text-align: center;

        @media only screen and (min-width: $breakpoint-tablet) {
            max-width: 570px;
            margin-bottom: 3.75rem;
        }

        @media only screen and (min-width: $breakpoint-lg-new) {
            max-width: 610px;
        }

        @media only screen and (min-width: $breakpoint-xl-new) {
            max-width: 730px;
        }
    }

    &__wrapper {
        position: relative;
        padding-top: 62.5%;  /* Player ratio: 100 / 1.6 */

        .video__button {
            transition: all ease-in-out .2s;
        }

        &:hover {
            .video__button {
                transform: scale(1.1);

                :global() {
                    circle {
                        fill: $primary;
                        opacity: 1;
                    }
                }
            }
        }

        > div {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__wrapper-narrow {
        padding-top: 56.25%;  /* Player ratio: 100 / 1.8 */
    }

    &__player {
        position: absolute;
        top: 0;
        left: 0;
    }
}

@import '../../lib/styles/variables';
@import '../../lib/styles/common';

.productCTA {
    width: 100%;
    max-width: 390px;
    border: solid 1px $gray-dark;
    margin: 6.25rem auto;

    .body {
        width: 100%;
        justify-content: center;
        margin-top: 3.125rem;
        text-align: center;
    }

    .heading-2 {
        font-size: 1.875rem;
    }

    .heading-3 {
        font-family: $tiempos;
        font-size: 1.3125rem;
        font-weight: normal;
        line-height: calc(40 / 21);
    }

    :global {
        .price {
            color: $secondary;
        }

        .button {
            margin: auto;
        }
    }

    @media screen and (min-width: $breakpoint-lg) {
        max-width: 920px;

        .body {
            display: flex;
            flex-wrap: wrap;
            align-self: center;
            padding: 0;
            margin: 0 0 0 3.75rem;
            text-align: left;

            > * {
                flex-basis: 100%;
            }

            > p {
                > a {
                    text-align: left;
                }
            }
        }
    }
}

.productCTA--gi-bio {
    padding: 3.75rem 1.0625rem;

    .body {
        > p {
            max-width: 240px;
            margin: auto;

            &.heading-2 {
                margin-bottom: 1.25rem;
            }
        }
    }

    .heading-3 {
        line-height: calc(31 / 21);
    }

    .inline {
        margin-top: 2.1875rem;
    }

    @media screen and (min-width: $breakpoint-tablet) {
        max-width: 510px;

        .body {
            > p {
                max-width: 350px;
            }
        }
    }

    @media screen and (min-width: $breakpoint-lg) {
        max-width: 920px;
        padding: 3.125rem 1.875rem 2.375rem;

        &.reverse {
            align-items: flex-end;
        }

        .body {
            margin: 0;

            > p {
                max-width: 390px;
                margin: 0;
            }
        }

        .inline {
            max-width: 390px;
        }

        :global {
            .button {
                margin: 0;
            }
        }
    }
}

.productCTA--v2 {
    width: calc(100% - 2.5rem);
    max-width: 330px;
    padding: 4.375rem 1.875rem 3.75rem;
    border: solid 2px $secondary-light;
    margin: auto;

    .body {
        margin-top: 0;

        .inline {
            position: relative;

            &:first-of-type {
                picture {
                    margin: 3.125rem 0;
                }
            }
        }
    }

    .heading-2 {
        margin-bottom: 1.5625rem;
        font-size: 1.5rem;
    }

    .heading-3 {
        color: $secondary-dark;
        font-size: 1.5rem;
        line-height: 1.33;

        b {
            display: block;
            font-family: $tiempos;
            font-weight: normal;
        }

        br {
            content: '';

            &::after {
                content: '\00a0';
            }
        }
    }

    > picture {
        display: none;
    }

    :global {
        .cta {
            .footer {
                @extend %absolute-x;

                bottom: -25px;
                margin: 0;
            }
        }
    }

    @media screen and (max-width: $breakpoint-tablet) {
        .heading-3 {
            b {
                margin-top: 0.625rem;
                color: $charcoal;
                font-size: 1rem;
                line-height: 1.38;
            }

            br {
                display: block;

                &::after {
                    content: '';
                }
            }
        }
    }

    @media screen and (min-width: $breakpoint-tablet) {
        width: 100%;
        max-width: 586px;
        padding: 4.6875rem 4.375rem 4.375rem;

        .heading-2 {
            font-size: 1.5rem;
        }

        .heading-3 {
            font-size: 1.25rem;
            line-height: 1.3;
        }

        .body {
            .inline {
                &:last-of-type {
                    display: inline-flex;
                    width: auto !important;
                    align-items: center;
                    column-gap: 1.5625rem;
                }
            }
        }

        :global {
            .cta {
                margin: 0;

                .footer {
                    right: unset;
                    transform: none;
                }
            }
        }
    }

    @media screen and (min-width: $breakpoint-lg) {
        max-width: 1110px;
        padding: 6.875rem 0 6.25rem 3.75rem;

        .heading-3 {
            margin-bottom: 2.8125rem;
        }

        .body {
            display: block;
            margin: 0 0 0 5.625rem;

            .inline {
                &:first-of-type {
                    picture {
                        display: none;
                    }
                }

                &:last-of-type {
                    flex-basis: auto;
                }
            }
        }

        > picture {
            display: block;
        }
    }
}

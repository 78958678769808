@import 'lib/styles/variables';

.CTA {
    display: inline-flex;
    width: 100%;
    min-width: 90px;
    height: 50px;
    align-items: center;
    padding: 0 1.25rem;
    border-radius: 0;
    margin-top: 1.25rem;
    font-family: $calibre-semibold;

    svg {
        fill: currentcolor;
    }

    &.Primary,
    &.Secondary,
    &.SecondaryTransparent,
    &.White,
    &.Black,
    &.BlackPink {
        font-size: 1.25rem;
        letter-spacing: normal;
        line-height: 1;
        text-align: center;
        text-decoration: none;
    }

    &.Primary {
        background-color: $secondary-light;

        &.Light {
            color: $white;

            &:hover {
                background-color: $primary;
                color: $white;
            }
        }

        &.Dark {
            color: $white;

            &:hover {
                background-color: $white;
                color: $primary;
            }
        }
    }

    &.Secondary,
    &.SecondaryTransparent {
        border: 2px solid;
        color: $primary;

        &.Light {
            border-color: $primary;
            color: $primary;

            &:hover {
                background-color: $primary;
                color: $white;
            }
        }

        &.Dark {
            border-color: $white;
            color: $white;

            &:hover {
                background-color: $white;
                color: $primary;
            }
        }
    }

    &.Secondary {
        &.Dark {
            background-color: $primary;
        }
    }

    &.White {
        background-color: $white;

        &.Light {
            color: $black;

            &:hover {
                color: $black;
            }
        }

        &.Dark {
            color: $secondary-dark;

            &:hover {
                color: $secondary-dark;
            }
        }
    }

    &.Black,
    &.BlackPink {
        background-color: $primary;
        color: $white;

        &:hover {
            background-color: $primary;
            color: $secondary-light;
        }
    }

    &.ForwardArrow,
    &.Plus {
        width: auto;
    }

    &.Standard,
    &.ForwardArrow,
    &.Plus {
        display: inline-flex;
        height: 44px;
        align-items: center;
        justify-content: space-evenly;
        padding: 0;
        margin: 0;
        font-weight: 500;
        text-align: center;
        text-decoration: none;

        &:hover,
        &:focus,
        &:active {
            color: $black;
        }

        &.Dark {
            color: $white;

            &:hover {
                color: $white;
            }
        }

        &.bg,
        &.border {
            padding: 0 24px;
        }

        span {
            white-space: nowrap;

            svg {
                display: inline;
            }
        }
    }

    &.centerAtMobile {
        width: 100%;
    }

    &.reverse {
        span {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;

            svg {
                margin-left: 0.625rem;
            }
        }
    }

    &.bg {
        padding: 0.625rem 1.5rem;

        &.Light {
            color: $black;
        }
    }

    &.border {
        border-width: 1px;
        border-style: solid;
    }

    &.legacy {
        margin: 20px 0;
    }

    &__Wrapper {
        &.Standard-wrapper {
            flex-flow: row-reverse wrap;
            justify-content: flex-end;

            .Standard {
                &.showPrice {
                    margin: 0 28px 0 0;
                }
            }
        }

        &.ForwardArrow-wrapper {
            &.showPrice {
                justify-content: center;
            }

            .ForwardArrow {
                &.showPrice {
                    margin-left: 1.125rem;
                }
            }
        }

        &.Standard-wrapper,
        &.ForwardArrow-wrapper {
            display: flex;
            align-items: center;

            .Standard,
            .ForwardArrow {
                &.showPrice {
                    margin-top: 0;
                }
            }

            .priceWrapper {
                margin-top: 0;
                white-space: nowrap;

                .price {
                    &.price {
                        &--original,
                        &--current {
                            color: $black;
                            font-family: $font-TWKLausanne;
                            font-size: 1.25rem;
                        }

                        &--original {
                            &::after {
                                top: 50%;
                                right: -4px;
                                left: -4px;
                                color: $action-red;
                            }
                        }
                    }

                    .currency {
                        color: $black;
                    }
                }

                &.Dark {
                    .price {
                        &.price {
                            &--original,
                            &--current {
                                color: $white;
                            }
                        }

                        .currency {
                            color: $white;
                        }
                    }
                }
            }

            .showInstallment {
                flex-basis: 100%;
                color: $gray-5;

                p,
                a {
                    font-size: 12px;
                }
            }
        }

        &.BlackPink-wrapper {
            .BlackPink {
                &:hover {
                    background-color: #c93b76;
                    color: $white;
                }
            }

            :global {
                .priceWrapper {
                    margin-top: 0;
                    white-space: nowrap;

                    .price {
                        &--original {
                            &::after {
                                height: 0.125rem;
                                background-color: #c93b76;
                            }
                        }
                    }
                }
            }
        }

        &.subscription {
            display: flex;
            align-items: center;

            .CTA {
                margin-left: 1.125rem;
            }

            :global {
                .priceWrapper {
                    margin: 0;

                    .price {
                        &--current {
                            color: $secondary;
                            font-size: 1.875rem;
                        }

                        .suffix {
                            font-size: 1.25rem;
                        }
                    }

                    + a {
                        margin-top: 0;
                    }
                }
            }
        }

        .showInstallment {
            margin-top: 1.875rem;
            font-size: 0.75rem;
            line-height: 1.5;

            .showInstallment {
                &__image {
                    display: inline-block;
                    width: 54px;
                    margin: 0 0.125rem;
                    line-height: 1;
                    vertical-align: middle;
                }
            }

            svg {
                fill: #5e1df5;
            }

            strong {
                font-family: $calibre;
                letter-spacing: 0.05rem;
            }

            &.Dark {
                svg {
                    fill: $white;
                }
            }
        }

        :global {
            .priceWrapper {
                margin-top: 1.25rem;
                text-align: left;

                .price {
                    width: auto;
                    font-size: 1.5rem;

                    &--current {
                        font-family: $calibre-semibold;
                    }
                }

                + a {
                    margin-top: 0.25rem;
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        width: auto;

        &.centerAtMobile {
            width: auto;
        }
    }
}

.salesMessage {
    padding: 0.5rem 0.75rem;
    border: 2px dotted $brand-green-2;
    border-radius: 0.3125rem;
    margin: 1.25rem 0 0;

    p {
        color: $brand-green-2 !important;

        + p {
            margin-top: 0;
        }
    }

    &.Dark {
        border-color: $white;

        p {
            color: $white;
        }
    }

    + .cta {
        width: 100%;
        margin-right: 0;
    }

    :global {
        .text {
            display: flex;
            flex-wrap: wrap;
            column-gap: 0.375rem;

            p {
                + p {
                    margin-top: 0;
                }
            }
        }
    }
}

@import 'lib/styles/variables';

.nav {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;

    > p {
        margin-right: 24px;
        color: $gray-5;
        font-weight: 500;
    }

    .links {
        display: flex;
        align-items: center;
        margin: 0;
        text-align: center;

        li {
            display: block;
            padding: 0 0.75rem;
            list-style-type: none;

            &:first-of-type {
                padding-left: 0;
            }

            &:last-of-type {
                border: 0;
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 500;
                text-decoration: none;

                &:hover {
                    color: $brand-green-3;
                }

                svg {
                    transform: rotate(90deg);
                }
            }
        }
    }
}

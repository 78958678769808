@import 'lib/styles/variables';

.navbar {
    position: fixed;
    z-index: 1040;
    top: 0;
    width: 100vw;
    height: 70px;
    background-color: #fff;
    box-shadow: #cbcaca 0 0 5px;

    .habit-logo {
        display: flex;
        height: 100%;
        justify-content: center;
        padding: 15px;
        cursor: pointer;

        a, img {
            height: 100%;
        }
    }
}

@media only screen and (min-width: $breakpoint-lg-new) {
    .navbar {
        height: 102px;
    }
}

@import '../../lib/styles/variables';

.RecodedMisc {
    > .body {
        max-width: 390px;
        padding: 0 1.875rem;
        margin: 0 auto 3.125rem;
    }

    .heading {
        margin-bottom: 0;
        font-family: $calibre-semibold;
        font-size: 1.875rem;
        line-height: calc(35 / 30);
        line-height: 1;
    }

    p {
        &:not(.heading) {
            font-size: 1.25rem;
            line-height: calc(25 / 20);
        }
    }

    > .section {
        width: 65%;
        margin: 0 0 0 auto;
    }

    &.full {
        padding: 6.25rem 0;
    }

    :global {
        .Carousel__wrapper {
            position: relative;
        }

        .slide {
            position: relative;

            .viome__section__body {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 290px;
                height: 210px;
                padding: 1.25rem;
                margin-bottom: 0;
                background-color: #151833;

                p {
                    color: #fff;
                    font-size: 1.125rem;
                    line-height: calc(24 / 18);
                    text-align: left;
                }
            }
        }

        .carousel {
            &.carousel-slider {
                overflow: visible;

                .control-arrow {
                    display: none;
                    padding: 0;
                    opacity: 1;

                    &:hover {
                        background: transparent;
                    }

                    &::before {
                        display: block;
                        border: 0;
                        margin: 0;
                        content: url('https://images.ctfassets.net/qk4l4jfatr3e/6REyX3MvLqAmd7fv6HJA2l/e29e98834d5865edaa804bf5594de5a9/green_pointer_-_carousel.svg');
                    }

                    &.control-prev {
                        left: -100px;

                        &::before {
                            transform: rotate(180deg);
                        }
                    }

                    &.control-next {
                        right: -100px;
                    }
                }
            }

            .control-dots {
                position: absolute;
                bottom: 20px;
                left: 20px;
                width: auto;

                .dot {
                    background: $charcoal;
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        max-width: 600px;
        margin: auto;

        > .body {
            max-width: none;
            padding: 0;

            p {
                width: 425px;
            }
        }

        > .section {
            width: 100%;
            margin: 0;
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        &.full {
            padding-bottom: 12.5rem;
        }

        :global {
            .carousel {
                &.carousel-slider {
                    .control-arrow {
                        display: block;
                    }
                }
            }
        }
    }
}

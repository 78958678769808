@import 'lib/styles/variables';

.grid-dropdown {
    &.light {
        .selector {
            &.active {
                border: solid 1px $gray-6;
            }
        }
    }

    &.dark {
        .selector {
            border: solid 1px $gray-6;

            &.active {
                border-color: $white;
            }
        }
    }

    .selectors {
        display: grid;

        .selector {
            &.active {
                :global {
                    .row__container {
                        > .moduleText {
                            .image {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }
        }

        :global {
            .row__container {
                > .moduleText {
                    .image {
                        width: 17px;
                        flex: 0 0 17px;
                        margin-right: 0;
                        transition: 0.2s ease-in-out;
                    }
                }
            }
        }
    }

    .selected {
        overflow: hidden;
        max-height: 0;
        transition: 0.2s ease-in-out;

        &.active {
            max-height: 2000px;
            transition: 0.2s ease-in-out;
        }
    }
}

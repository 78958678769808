@import '../../lib/styles/variables';
@import '../../lib/styles/common';

.viome-modern__product-module--compare {
    display: flex;
    flex-direction: column;

    > h4 {
        display: block;
        max-width: 260px;
        margin: 6.25rem auto;
        font-family: $calibre-semibold;
        font-size: 1.875rem;
        text-align: center;
    }

    .viome-modern__product-module--compare--item {
        position: relative;
        width: 100%;
        max-width: 539px;
        padding: 9.375rem 1.875rem 6.25rem;
        border: solid 1px $gray-dark;
        box-shadow: 0 0.125rem 1.25rem 0 rgb(0 0 0 / 5%);

        + .viome-modern__product-module--compare--item {
            margin-top: 4.375rem;
        }

        h4 {
            position: absolute;
            top: 76px;
            right: 50%;
            display: block;
            width: 150px;
            height: 40px;
            background-color: $purple;
            border-radius: 3.125rem;
            color: $white;
            font-family: $calibre-semibold;
            font-size: 1.25rem;
            line-height: 2;
            text-align: center;
            transform: translateX(50%);
        }

        picture {
            margin-bottom: 2.1875rem;
        }

        .body {
            i {
                margin-bottom: 4.375rem;
            }

            b {
                text-align: left;
            }

            i, b {
                display: block;
            }

            > p {
                text-align: center;

                &:not(.heading) {
                    font-size: 1.125rem;
                    line-height: calc(22 / 18);
                }

                &.heading-2 {
                    margin-bottom: 0.625rem;
                    font-size: 1.875rem;
                    line-height: calc(34 / 30);
                }

                &.heading-3 {
                    margin-bottom: 2.5rem;
                    font-size: 1.25rem;
                }
            }

            ul {
                margin-top: 3.75rem;

                li {
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 1.5rem;

                    &::before {
                        display: block;
                        margin: 0.1875rem .75rem 0 0;
                        content: url('https://images.ctfassets.net/qk4l4jfatr3e/41f5JTNBoCF7NG2VrG67za/68758b3753bc5cc115901072e5c15a64/green-check-thick.svg');
                    }

                    &:last-of-type {
                        padding: 0;
                        margin: 0;
                    }

                    p {
                        font-size: 1rem;
                        text-align: left;
                    }
                }
            }
        }

        :global {
            ol {
                .priceWrapper {
                    margin: 0;
                }

                .suffix {
                    font-size: .6em;
                }
            }

            .priceWrapper {
                margin-top: 6.25rem;
                text-align: center;
            }

            .price {
                font-size: 1.875rem;

                &--current {
                    color: $secondary;
                }
            }

            .currency {
                font-size: 1.875rem;
            }

            .cta {
                justify-content: center;
            }
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        > h4 {
            max-width: none;
            margin-bottom: 4.375rem;
        }

        .viome-modern__product-module--compare--item {
            padding: 9.375rem 6.25rem 6.25rem;
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        .reverse {
            flex-direction: column-reverse;
        }

        .items {
            display: flex;
            max-width: 1110px;
            justify-content: space-between;
            margin: auto;
        }

        .viome-modern__product-module--compare--item {
            height: 1200px;
            margin: 0;

            + .viome-modern__product-module--compare--item {
                margin: 0;
            }
        }
    }
}

.viome-modern__product-module--compare--cs {
    background-color: $secondary;

    .body {
        > p {
            color: $white;

            &.heading-2 {
                margin-bottom: 0.625rem;
                font-size: 1.875rem;
                line-height: calc(34 / 30);
            }
        }

        ol {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            padding: 2.1875rem 1.875rem 1.875rem;
            margin: 3.125rem 0 0;
            background: $secondary-light;

            p {
                margin: 0;
                color: $white !important;
            }

            li {
                &:nth-of-type(1), &:nth-of-type(2) {
                    display: flex;
                    width: 50%;
                    height: 65px;
                    align-items: center;
                    justify-content: center;
                }

                &:nth-of-type(3), &:nth-of-type(4) {
                    width: 100%;

                    p {
                        text-align: center;
                    }
                }

                &:nth-of-type(1) {
                    border-right: 1px solid $white;
                }

                &:nth-of-type(2) {
                    p {
                        max-width: 80%;
                        padding-top: 0.375rem;
                        font-size: 0.875rem;
                        line-height: calc(18 / 14);
                        text-align: center;

                        u {
                            display: block;
                            font-family: $calibre-semibold;
                            font-size: 1.875rem;
                            line-height: calc(20 / 30);
                            text-decoration: none;
                        }
                    }
                }

                &:nth-of-type(3) {
                    margin: 1.25rem 0;

                    p {
                        @include viomeButton('dark');
                    }
                }

                &:nth-of-type(4) {
                    i {
                        margin: 0;
                        font-size: 1.125rem;
                    }
                }
            }
        }

        ul {
            p {
                color: $white;
            }
        }
    }
}

.viome-modern__product-module--compare--cs-home, .viome-modern__product-module--compare--hi-home {
    .wrapper {
        .body {
            > p {
                &.heading {
                    font-size: 1.5rem;
                    line-height: calc(30 / 24);

                    + p {
                        &:not(.heading) {
                            margin-top: 4.375rem;
                            color: $charcoal;
                            font-size: 1.125rem;
                            line-height: calc(24 / 18);
                            text-align: left;
                            text-decoration: none;
                        }
                    }
                }

                &.heading-2 {
                    margin-bottom: 0;
                }

                &.heading-3 {
                    font-family: $calibre;
                    font-weight: normal;

                    + p {
                        &:not(.heading) {
                            margin-top: 0;
                        }
                    }
                }

                > a {
                    color: $blue;
                    font-family: $calibre-semibold;
                }
            }

            b {
                font-family: $calibre-medium;
                font-weight: 500;
            }

            i {
                margin-bottom: 2.5rem;
                color: $charcoal;
            }

            u {
                text-decoration: none;
            }

            ol {
                margin: 0.625rem 0 1.875rem;

                li {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding: 1rem;
                    border: solid 1px #cfd0d5;
                    margin-bottom: 1.25rem;
                    border-radius: 0.3125rem;

                    .inline {
                        flex: 0 0 100px;
                        text-align: center;
                    }

                    p {
                        flex: 0 auto;
                        margin: 0 0 0 1.375rem;
                        font-family: $calibre-medium;
                        font-size: 1rem;
                        font-weight: 500;
                        line-height: calc(22 / 16);
                    }

                    picture {
                        margin: 0;
                    }
                }
            }

            ul {
                margin-top: 0.8125rem;

                li {
                    margin-bottom: 0.625rem;

                    &:last-of-type {
                        &::before {
                            content: url('https://images.ctfassets.net/qk4l4jfatr3e/4WrqUuh3ZD29IAuKkXkrh1/6fca0b19052488f5458865c4acf222c3/green-plus-thick.svg');
                        }
                    }
                }
            }

            :global {
                .priceWrapper {
                    margin-top: 3.125rem;
                }

                .cta {
                    margin: 0 0 0.9375rem;
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        .viome-modern__product-module--compare & {
            padding: 6.25rem 2.8125rem;
        }
    }
}

@media only screen and (min-width: $breakpoint-lg) {
    .viome-modern__product-module--compare--hi-home {
        .wrapper {
            > picture {
                height: 140px;

                img {
                    height: 110px;
                }
            }

            .body {
                > p {
                    > a {
                        display: block;
                        margin-top: 4.6875rem;
                    }
                }

                :global {
                    .priceWrapper {
                        margin-top: 16.875rem;
                    }
                }
            }
        }
    }
}

.compare {
    > .body {
        padding: 8.75rem 1.25rem 5.3125rem;

        p {
            max-width: 350px;
            margin: auto;
            text-align: center;

            &:not(.heading) {
                font-size: 1.5rem;
                line-height: 1.25;
            }

            &.heading-2 {
                width: 250px;
                margin-bottom: 3.125rem;
                color: $secondary;
                line-height: 1.15;
            }
        }
    }

    .compare--item {
        position: relative;
        width: 100%;
        max-width: 539px;
        padding: 25.3125rem 1.875rem 3.75rem;
        border: solid 1px $gray-dark;
        background-color: $white;
        background-position: top;
        background-repeat: no-repeat;
        background-size: 100% 310px;
        box-shadow: 0 0.125rem 1.25rem 0 rgb(0 0 0 / 5%);

        + .compare--item {
            margin-top: 6.875rem;
        }

        > .wrapper {
            > .body {
                display: flex;
                flex-direction: column;

                > p {
                    &:not(.heading) {
                        font-size: 1.125rem;
                        line-height: 1.33;

                        u {
                            display: block;
                            margin-bottom: 0.375rem;
                            color: $charcoal;
                            text-decoration: none;
                        }
                    }

                    &.heading {
                        position: absolute;
                        font-weight: normal;
                        text-align: center;
                    }

                    &.heading-2, &.heading-3 {
                        font-family: $calibre-semibold;
                    }

                    &.heading-2, &.heading-4 {
                        right: 20px;
                        left: 20px;
                    }

                    &.heading-3 {
                        top: 80px;
                        right: 50%;
                        left: unset;
                        display: flex;
                        width: 130px;
                        height: 30px;
                        align-items: center;
                        justify-content: center;
                        padding-top: 0.1875rem;
                        margin-right: -4.0625rem;
                        background-color: $purple;
                        border-radius: 1.25rem;
                        font-size: 1.125rem;
                        line-height: 1;
                    }

                    &.heading-2 {
                        top: 120px;
                        font-size: 1.875rem;
                        line-height: 1.25;

                        u {
                            display: inline-block;
                            font-family: $calibre;
                            text-decoration: none;
                        }
                    }

                    &.heading-4 {
                        top: 36px;
                        font-family: $tiempos-light;
                        font-size: 1.25rem;
                        font-style: italic;
                        letter-spacing: 0.4px;
                        text-decoration: underline;
                    }

                    &:last-of-type {
                        margin-top: 3.125rem;
                        text-align: center;

                        a {
                            @extend %standard-link;
                        }
                    }
                }

                > ul {
                    margin-top: 0.75rem;

                    li {
                        height: 40px;

                        .inline {
                            margin-right: 0.9375rem;

                            + p {
                                padding-top: 0.3125rem;
                                font-family: $calibre-semibold;
                            }
                        }
                    }

                    &:last-of-type {
                        li {
                            @include checklist('thin', $primary, 1.125rem, 1.33, 0, true);
                        }
                    }
                }

                > ol {
                    margin: 1.5625rem 0 2.8125rem;

                    li {
                        height: 80px;
                        padding: 0 1.25rem;
                        border: 1px solid $gray-dark;

                        + li {
                            margin-top: 1rem;
                        }

                        p {
                            margin-left: 1.25rem;
                            font-family: $calibre-medium;
                            font-size: 1rem;
                            line-height: 1.25;
                        }

                        .inline {
                            flex: 0 0 100px;
                            text-align: center;
                        }
                    }
                }

                li {
                    display: flex;
                    align-items: center;

                    .inline {
                        width: auto !important;
                    }
                }

                > hr {
                    width: 100%;
                    margin: 0 auto 1.5625rem;
                    background: $charcoal;
                }
            }

            > picture {
                @extend %absolute-x;

                top: 220px;
            }
        }

        :global {
            .priceWrapper {
                text-align: center;
            }

            .price {
                color: $secondary;
                font-size: 1.875rem;

                .suffix {
                    font-family: $calibre-semibold;
                    font-size: 1.125rem;
                }
            }

            .cta {
                position: relative;
                justify-content: center;
                margin-top: 0;

                .footer {
                    position: absolute;
                    bottom: -35px;
                }
            }

            .Expanding__wrapper {
                &:not(.active) {
                    max-height: 3rem;
                }

                p {
                    font-size: 1.125rem;
                    line-height: 1.33;
                }

            }
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        > .body {
            padding: 14.375rem 0 6.875rem;

            p {
                max-width: 650px;

                &.heading-2 {
                    width: auto;
                    margin-bottom: 2.8125rem;
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        > .body {
            padding-top: 7.8125rem;
        }

        .items {
            display: flex;
            width: 1140px;
            align-items: flex-start;
            justify-content: space-between;
            margin: auto;
        }

        .compare--item {
            padding: 25.3125rem 2.8125rem 3.75rem;
            margin: 0;

            + .compare--item {
                margin: 0;
            }

            > .wrapper {
                height: 100%;

                > .body {
                    height: 100%;
                }
            }
        }
    }
}

.compare--white {
    &.compare--item {
        > .wrapper {
            > .body {
                > p {
                    &.heading {
                        color: $white;
                    }
                }
            }
        }
    }
}

.compare--gi {
    background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/6Jl28a94F03A2AdMzpl8q8/cc757f78e557795c63732b2de1d336b0/stocksy-txp-60407140-e-hn-200-medium-3185391-copy-3_3x.png?&w=540&fm=webp&q=70');

    &.compare--item {
        > .wrapper {
            > .body {
                > hr {
                    margin-top: 15.375rem;
                }
            }
        }
    }
}

.compare--hi {
    background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/2QyKyaMpLQIpFJ28B105yA/f783bc1ca26c5c98e351c082889bf6f6/stocksy-txp-60407140-e-hn-200-medium-3185391-copy-2.png?&w=540&fm=webp&q=70');
}

.compare--test {
    &.compare--gi, &.compare--hi {
        min-height: auto;

        &.compare--item {
            > .wrapper {
                > .body {
                    > hr {
                        margin-top: 0;
                    }

                    > ul {
                        &:first-of-type {
                            min-height: 240px;
                            margin-bottom: 3.125rem;
                        }

                        > li {
                            &::before {
                                display: none;
                            }
                        }

                        + ul {
                            display: none;
                        }
                    }
                }
            }
        }

        :global {
            .sale {
                .price--original {
                    color: $black;
                }

                .price--current {
                    color: $red;
                }
            }

            .sales-message {
                position: absolute;
                top: -25px;
                right: 50%;
                display: block;
                color: $red;
                font-family: $calibre-semibold;
                font-size: 1.125rem;
                transform: translateX(50%);
            }
        }
    }
}

.compare--plan {
    &.compare--gi-plan, &.compare--hi-plan {
        &.compare--item {
            > .wrapper {
                > .body {
                    @media only screen and (max-width: $breakpoint-tablet) {
                        p {
                            &.heading-2 {
                                font-size: 1.5rem;
                            }
                        }

                        > ol {
                            li {
                                padding: 0 0.625rem;

                                .inline {
                                    flex-basis: 48px;

                                    img {
                                        width: 48px;
                                    }
                                }

                                p {
                                    font-size: 0.875rem;
                                }
                            }
                        }
                    }

                    @media only screen and (min-width: $breakpoint-lg) {
                        > ul {
                            &:first-of-type {
                                min-height: 240px;
                            }

                            + ul {
                                margin-top: 1.5625rem;
                            }
                        }
                    }
                }
            }
        }

        :global {
            .sale {
                margin-top: 9.375rem;
            }

            .sales-message {
                position: absolute;
                top: -94px;
                right: 0;
                left: 0;
                display: flex;
                height: 60px;
                align-items: center;
                justify-content: center;
                border: solid 1px $secondary-light;
                background-color: $secondary-bright;
                color: $secondary;
                font-family: $calibre-medium;
                font-size: 1.125rem;
                line-height: calc(22 / 18);
            }
        }
    }

    &.compare--gi-plan {
        background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/6Qay33WI7T1SU6tyUawBpc/37b6518eef2103204398e6fe41e5d38e/stocksy-txp-60407140-e-hn-200-medium-3185391-copy_3x.png?&w=540&fm=webp&q=70');
    }
}

.compare--test, .compare--plan {
    &.compare--item {
        > .wrapper {
            > .body {
                > p {
                    &.heading-2 {
                        top: 130px;
                    }
                }
            }
        }
    }
}

.compare--hi-plan {
    background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/76o8sU10SomVK9pcewcSbb/21cd34bd44ce1130269e9d5a18ef684b/stocksy-txp-60407140-e-hn-200-medium-3185391_3x.png?&w=540&fm=webp&q=70');
}

.compare--gi-plan {
    background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/3MueLzccecJXDbYiIYjqfn/16f89c32e77d0622c92f8fcf6cbefc53/stocksy-txp-60407140-e-hn-200-medium-3185391-copy_3x.png?&w=540&fm=webp&q=70');

    &.compare--item {
        > .wrapper {
            > .body {
                > p {
                    &.heading-2 {
                        top: 100px;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        &.compare--item {
            > .wrapper {
                > .body {
                    > p {
                        &.heading-2 {
                            top: 120px;
                        }
                    }
                }
            }
        }
    }
}

.button {
    @include viomeButton('secondary');

    width: 290px;

    margin: 5.625rem auto 0;
}

@import '../../lib/styles/variables';

.viome-modern__product-perks {
    max-width: 370px;
    padding: 6.25rem 1.25rem 0;
    margin: auto;

    > h4 {
        margin-bottom: 1.875rem;
        font-size: 1.875rem;
        line-height: calc(35 / 30);
        text-align: center;
    }

    .perks {
        display: flex;
        flex-direction: column;
        align-items: center;

        > .body {
            padding: .9375rem 1.25rem;
            margin-bottom: 2.1875rem;

            p {
                color: $white;

                &:not(.heading) {
                    font-size: 1rem;
                    line-height: calc(22 / 16);
                }

                &.heading-6 {
                    font-size: 1.125rem;
                    line-height: calc(22 / 18);
                }
            }

            &.viome-modern__product-perk {
                &--precision {
                    background-color: $primary;
                }

                &--cost {
                    background-color: $secondary-light;
                }

                &--flexibility {
                    background-color: $secondary-dark;
                }

                &--quality {
                    margin: 0;
                    background-color: $secondary;
                }
            }
        }
    }

    @media screen and (min-width: $breakpoint-tablet) {
        max-width: 648px;
        padding: 6.25rem 0 0;

        > h4 {
            margin-bottom: 1rem;
            font-size: 2.5rem;
            line-height: calc(46 / 40);
            text-align: left;
        }

        .perks {
            flex-flow: row wrap;
            align-items: flex-start;
            justify-content: space-between;

            > .body {
                width: 311px;
                height: 158px;
                margin: 0 0 2.5rem;

                &.viome-modern__product-perk {
                    &--flexibility {
                        margin: 0;
                    }
                }
            }
        }
    }

    @media screen and (min-width: $breakpoint-tablet) {
        > h4 {
            margin-bottom: 4.375rem;
            font-size: 1.875rem;
            line-height: calc(46 / 30);
            text-align: center;
        }
    }
}

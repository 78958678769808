@import 'lib/styles/variables';

.testimonial {
    height: 100%;

    .head {
        min-height: 90px;
    }

    .types {
        margin-left: 0;

        li {
            display: flex;
            gap: 0.25rem;
            list-style-type: none;

            p {
                color: $gray-6;
                white-space: nowrap;
            }

            svg {
                width: 16px;
            }
        }
    }

    .headshot {
        width: 90px;
        height: 90px;
        border-radius: 50%;
    }

    .name {
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1.4;
    }

    .role {
        color: $gray-4;
    }

    :global {
        .image {
            height: 90px;
        }

        .text {
            * {
                font-family: $tiempos;
                font-size: 1.375rem;
                font-weight: 300;
                line-height: 1.4;

                @media only screen and (min-width: $breakpoint-tablet) {
                    font-size: 1.5rem;
                }
            }
        }
    }
}

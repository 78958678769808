@import 'lib/styles/variables';

.inlineList {
    display: inline-block;

    ul,
    ol,
    li {
        font-size: inherit !important;
    }

    ul {
        &.customBullets {
            li {
                display: flex;
                align-items: center;

                &.dash {
                    svg {
                        margin-top: 0.125rem;
                    }

                    b {
                        margin-right: 0.25rem;
                    }
                }

                svg {
                    flex: 0 0 auto;
                    margin: 0 0.625rem 0 0;
                    fill: currentcolor;
                }

                + li {
                    margin-top: 0.625rem;
                }
            }

            &.colored {
                display: flex;
                flex-wrap: wrap;

                li {
                    &.colored {
                        flex: 0 0 50%;
                        white-space: nowrap;
                    }

                    svg {
                        margin-right: 0.5rem;
                    }
                }

                @media only screen and (min-width: $breakpoint-tablet) {
                    flex-wrap: nowrap;

                    li {
                        &.colored {
                            flex-basis: 25%;
                            justify-content: center;
                        }

                        + li {
                            margin-top: 0;
                        }
                    }
                }

                @media only screen and (min-width: $breakpoint-lg-new) {
                    li {
                        &.colored {
                            white-space: normal;
                        }
                    }
                }
            }
        }

        &.alignTop {
            li {
                align-items: flex-start;

                &.check {
                    svg {
                        margin: 0.5rem 1.5rem 0 0;
                    }
                }
            }
        }
    }

    .borderTop {
        border-top: 1px solid;
    }

    .borderBottom {
        > li {
            padding: 1rem 0;
            border-bottom: 1px solid;
        }
    }

    .noBullets {
        li {
            list-style: none;
        }
    }

    .noMargins {
        margin-left: 0;
    }

    .hoverLinks {
        a {
            text-decoration: none;

            &:hover {
                color: inherit;
                text-decoration: underline;
            }
        }
    }

    .bubbles {
        padding-left: 0;
        counter-reset: elementcounter;
        list-style-type: none;

        &.alignTop {
            li {
                align-items: flex-start;
            }
        }

        .numbers {
            position: absolute;
            z-index: 2;
            left: 0;
            display: inline-flex;
            width: 40px;
            height: 40px;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            font-size: 1.25rem;
            line-height: 1.4;

            &::before {
                content: counter(elementcounter);
                counter-increment: elementcounter;
            }
        }

        li {
            position: relative;
            display: flex;
            align-items: center;
            padding: 0 0 0 3.5rem;
            line-height: 2.5rem;

            > b {
                font-size: 125%;
            }

            &:not(:last-of-type) {
                padding-bottom: 2rem;
            }
        }
    }

    .topic {
        display: flex;
        flex-wrap: wrap;
        justify-content: inherit;
        gap: 8px;

        li {
            display: flex;
            align-items: center;
            padding: 2px 12px;
            border-radius: 40px;
            font-weight: 400;
            white-space: nowrap;
        }
    }

    @media only screen and (min-width: $breakpoint-lg-new) {
        .borderBottom {
            &:not(.thinner) {
                > li {
                    padding: 1.5rem 0;
                }
            }
        }
    }
}

@import '../../lib/styles/variables';

.simple-text {
    margin: 9.25rem auto;
    background: $secondary-dark;

    p,
    h2,
    h3 {
        text-align: center;
    }

    h2 {
        font-size: 30px;
        line-height: calc(40 / 30);
    }

    h3 {
        margin-bottom: 10px;
        font-family: $calibre;
        font-size: 18px;
        font-weight: normal;
        line-height: calc(22 / 18);
    }

    &--one {
        background: $white;

        h2 {
            max-width: 573px;
            padding: 0 20px;
            margin: 0 auto 25px;
            color: $blue3;
            font-family: $tiempos-light;
            font-size: 30px;
            font-weight: 500;
            line-height: calc(40 / 30);
            text-align: center;
        }

        p {
            display: none;
        }

        u {
            color: #146464;
        }
    }
}

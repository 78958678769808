@import 'lib/styles/variables';

.Row {
    position: relative;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        width: 100%;
        color: $primary;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    p {
        font-family: $calibre-medium;
        font-weight: 500;
    }

    h3,
    h4,
    h5,
    h6 {
        + h2,
        + h3,
        + h4,
        + h5,
        + h6,
        + p {
            margin-top: 1.25rem;
        }
    }

    h1,
    h2 {
        + h2,
        + h3,
        + h4,
        + h5,
        + h6,
        + p {
            margin-top: 0.625rem;
        }
    }

    h1 {
        font-size: 3.125rem;
        letter-spacing: -0.0313rem;
        line-height: 1.2;
    }

    h2,
    h4 {
        + h4 {
            margin-top: 0;
        }
    }

    h2 {
        font-size: 2.5rem;
        letter-spacing: -0.0313rem;
        line-height: 1.25;
    }

    h3 {
        font-size: 1.875rem;
        letter-spacing: -0.0431rem;
        line-height: 1.27;

        + h3 {
            margin: 0;
        }
    }

    h4 {
        font-size: 1.375rem;
        letter-spacing: -0.0413rem;
        line-height: 1.36;
    }

    h5,
    p {
        font-size: 1.0625rem;
        letter-spacing: -0.0375rem;
        line-height: 1.29;
    }

    h6 {
        font-family: $calibre;
        font-size: 1.0625rem;
        font-weight: normal;
        letter-spacing: -0.0375rem;
        line-height: 1.18;
    }

    p {
        font-family: $calibre;
    }

    b {
        font-family: $calibre-semibold;
    }

    i {
        font-family: $calibre-medium;
        font-style: italic;
    }

    ol,
    ul {
        margin-left: 1.5625rem;

        li {
            > * {
                margin-left: 0.375rem;
            }

            &::marker {
                color: $primary;
            }
        }
    }

    ol {
        list-style: auto;

        li {
            &::marker {
                font-family: $calibre-medium;
            }
        }
    }

    ul {
        li {
            list-style-type: disc;

            &::marker {
                font-size: 1.625rem;
            }

            ul {
                padding-left: 1.875rem;
                margin: 1.25rem 0 1.875rem;

                li {
                    list-style-type: circle;
                }
            }
        }
    }

    > .videoBackground {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;

        video {
            width: 100%;
        }

        + .dock {
            &-left,
            &-right {
                position: absolute;
                bottom: 50%;
                left: 5vw;
                max-width: 90vw;
                padding: 0;
                transform: translateY(50%);

                .moduleText {
                    max-width: 450px;
                }

                @media only screen and (min-width: $breakpoint-tablet) {
                    .moduleText {
                        margin: 0;
                    }
                }

                @media only screen and (min-width: $breakpoint-lg-new) {
                    left: 11.4%;

                    .moduleText {
                        max-width: 610px;
                    }
                }
            }
        }
    }

    &.bg {
        display: flex;
        flex-wrap: wrap;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        > .Row__body {
            flex-basis: 100%;
        }

        + .separator {
            border: 0;
        }
    }

    &.bg--attached {
        background-attachment: fixed;
    }

    &.bg,
    &.dark {
        padding: 3.75rem 0;

        &.removePadding {
            padding: 0;
        }
    }

    &.dark {
        background-color: $primary;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        a {
            color: $white;
        }

        a {
            &:active {
                color: $white;
            }

            &:hover {
                color: $blue;
            }
        }

        li {
            &::marker {
                color: $white;
            }
        }
    }

    &.border {
        padding: 50px 30px 40px;
        border-width: 1px;
        border-style: solid;
    }

    &.full {
        width: 100%;
    }

    &.scrollable {
        overflow: scroll;
    }

    &.margin {
        &-top {
            margin-top: 3.75rem;
        }

        &-bottom {
            margin-bottom: 3.75rem;
        }
    }

    &.paddingNone {
        &--top,
        &--tb {
            padding-top: 0 !important;

            > .Row__body,
            > .Row__container {
                margin-top: 0 !important;
            }
        }

        &--bottom,
        &--tb {
            padding-bottom: 0 !important;
        }

        &--tb {
            > .Row__body,
            > .Row__container {
                margin-bottom: 0 !important;
            }

            @media only screen and (max-width: $breakpoint-tablet - 1) {
                > .Row__container {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }
            }
        }

        &--left,
        &--sides {
            padding-left: 0;

            > .Row__container {
                padding-left: 0;
            }
        }

        &--right,
        &--sides {
            padding-right: 0;

            > .Row__container {
                padding-right: 0;
            }
        }

        &--sides {
            > .Row__container {
                .moduleText {
                    padding-right: 1.25rem;
                    padding-left: 1.25rem;
                }
            }
        }

        > .Row__body {
            padding: 0;
        }

        @media only screen and (min-width: $breakpoint-lg-new) {
            &--sides {
                > .Row__container {
                    &.flipAtDesktop {
                        .moduleText {
                            padding-right: 3.75rem;
                        }
                    }

                    &:not(&.flipAtDesktop) {
                        .moduleText {
                            padding-left: 3.75rem;
                        }
                    }
                }
            }
        }
    }

    &__container,
    &__body {
        z-index: 2;
        width: 100%;
        max-width: 520px;
        padding: 0 1.25rem;
        margin: auto;
    }

    &__container {
        &.Top {
            align-items: flex-start;
        }

        &.Center {
            align-items: center;
        }

        &.Bottom {
            align-items: flex-end;
        }
    }

    :global {
        .text {
            a {
                &:not(.cta, .legacy-cta) {
                    font-family: $calibre-semibold;
                    letter-spacing: -0.0288rem;
                    text-decoration: underline;
                    text-underline-offset: 1px;

                    &:active {
                        color: $primary;
                    }

                    &:hover {
                        color: $blue;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        h1 {
            font-size: 4.375rem;
            letter-spacing: -0.0437rem;
            line-height: 1.11;
        }

        h2 {
            font-size: 3.125rem;
            letter-spacing: -0.0394rem;
            line-height: 1.2;
        }

        h3 {
            font-size: 2.25rem;
            letter-spacing: -0.0519rem;
            line-height: 1.28;
        }

        h4 {
            font-size: 1.5rem;
            letter-spacing: -0.045rem;
            line-height: 1.25;
        }

        h5,
        p {
            font-size: 1.25rem;
            letter-spacing: -0.0444rem;
            line-height: 1.2;
        }

        &.bg,
        &.dark {
            padding: 5rem 0;
        }

        &.margin {
            &-top {
                margin-top: 5rem;
            }

            &-bottom {
                margin-bottom: 5rem;
            }
        }

        &__container,
        &__body {
            width: 100%;
            max-width: 768px;
            padding: 0 2.4375rem;
        }
    }

    @media only screen and (min-width: $breakpoint-lg-new) {
        h1 {
            font-size: 5.625rem;
            letter-spacing: -0.0563rem;
            line-height: 1.11;
        }

        h2 {
            font-size: 3.75rem;
            letter-spacing: -0.0375rem;
            line-height: 1.17;
        }

        h3 {
            font-size: 2.5rem;
            letter-spacing: -0.0313rem;
            line-height: 1.25;
        }

        h4 {
            font-size: 1.625rem;
            letter-spacing: -0.0375rem;
            line-height: 1.23;
        }

        h5 {
            font-size: 1.5rem;
            letter-spacing: normal;
            line-height: 1.5;
        }

        p {
            letter-spacing: -0.0375rem;
            line-height: 1.3;
        }

        &__container,
        &__body {
            max-width: 1024px;
            padding: 0 2.9375rem;
        }

        &.bg {
            > .Row__container {
                &.flex {
                    width: 100%;
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-xl-new) {
        &.bg,
        &.dark {
            padding: 6.25rem 0;
        }

        &.margin {
            &-top {
                margin-top: 6.25rem;
            }

            &-bottom {
                margin-bottom: 6.25rem;
            }
        }

        &__container,
        &__body {
            max-width: 1110px;
            padding: 0;
        }
    }
}

.single-column {
    > .modules {
        + .modules {
            margin-top: 3.75rem;
            margin-bottom: 3.75rem;

            @media only screen and (min-width: $breakpoint-tablet) {
                margin-top: 5rem;
                margin-bottom: 5rem;
            }

            @media only screen and (min-width: $breakpoint-xl-new) {
                margin-top: 6.25rem;
                margin-bottom: 6.25rem;
            }
        }
    }

    .removePadding {
        .Row__body,
        .Row__container {
            padding-right: 0;
            padding-left: 0;
        }
    }

    .sectionMultiColumn {
        @media only screen and (min-width: $breakpoint-tablet) {
            .Row__container {
                &.flex {
                    display: flex;
                    width: auto;
                    justify-content: center;

                    > .modules {
                        padding-right: 3.125rem;
                        padding-left: 3.125rem;

                        &.moduleImage {
                            width: 100%;
                        }

                        + .modules {
                            margin: 0;
                        }
                    }
                }
            }
        }

        .removePadding {
            .Row__body {
                padding-right: 0;
                padding-left: 0;
            }

            .Row__container {
                &.flex {
                    > .modules {
                        padding-right: 0;
                        padding-left: 0;
                    }
                }
            }

            @media only screen and (min-width: $breakpoint-tablet) {
                .Row__body {
                    padding-right: 0;
                    padding-left: 0;
                }

                .Row__container {
                    &.flex {
                        > .modules {
                            padding-right: 0;
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }

    &.bg {
        .moduleText {
            :global(.centered) {
                @media only screen and (min-width: $breakpoint-lg-new) {
                    max-width: 730px;
                }
            }
        }
    }

    .dock-left,
    .dock-right {
        @media only screen and (min-width: $breakpoint-tablet) {
            .moduleText {
                width: 100%;
                max-width: 450px;
                margin: auto;
            }

            .removePadding {
                .moduleText {
                    margin-right: 0;
                    margin-left: 0;
                }
            }
        }

        @media only screen and (min-width: $breakpoint-lg-new) {
            width: 100%;
            margin-bottom: 0;

            .moduleText {
                max-width: 540px;
            }
        }

        @media only screen and (min-width: $breakpoint-xl-new) {
            .moduleText {
                max-width: 610px;
            }
        }
    }

    .dock-left {
        @media only screen and (min-width: $breakpoint-lg-new) {
            .moduleText {
                margin-left: 0;
            }
        }
    }

    .dock-right {
        @media only screen and (min-width: $breakpoint-lg-new) {
            .moduleText {
                margin-right: 0;
            }
        }
    }

    .isModule {
        > .modules {
            + .modules {
                margin-top: 1.875rem;
                margin-bottom: 0;
            }
        }

        .moduleImage {
            + .moduleText {
                margin: 0;

                h2,
                h3,
                h5,
                p,
                a {
                    &:first-child {
                        margin-top: 1.25rem;
                    }
                }
            }
        }
    }

    .promo {
        padding: 4.6875rem 0 0;

        &.noPadding {
            padding: 0;
        }

        .moduleText {
            width: 285px;
            margin: auto;

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                margin-top: 0;

                > span {
                    display: inline-block;
                }
            }

            h2 {
                font-family: $calibre-semibold;
                font-size: 4.375rem;
                letter-spacing: -0.1013rem;
                line-height: 1.25;
            }

            h3 {
                font-size: 2.25rem;
                line-height: 1.28;
            }

            h4 {
                font-size: 1.875rem;
                line-height: 1.27;
            }

            u {
                border-bottom: 1px solid;
                text-decoration: none;
            }

            br {
                &:last-child {
                    display: none;
                }
            }

            hr {
                width: 40%;
                margin: 3.125rem auto;
                background-color: $gray-darkest;
            }

            :global {
                .heading {
                    letter-spacing: normal;
                }
            }
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            padding-bottom: 5rem;

            u {
                display: inline;
            }

            .flex {
                > .modules {
                    padding-top: 0.75rem;
                    border-right: 1px solid $gray-darkest;

                    &:last-of-type {
                        border: 0;
                    }
                }
            }

            .moduleText {
                width: 500px;

                h2 {
                    font-size: 5rem;
                }

                h4 {
                    font-size: 1.5rem;
                    line-height: 1.25;
                }
            }

            .sectionMultiColumn {
                .Row {
                    position: relative;

                    &.margin {
                        &-top {
                            margin-top: 0;
                        }

                        &-bottom {
                            margin-bottom: 3.75rem;
                        }
                    }

                    hr {
                        display: none;
                    }
                }

                .moduleText {
                    width: 300px;
                }
            }
        }

        @media only screen and (min-width: $breakpoint-lg-new) {
            .moduleText {
                width: auto;

                h2 {
                    font-size: 5.625rem;
                }

                h3 {
                    font-size: 2.5rem;
                    line-height: 1.25;
                }

                :global {
                    .centered {
                        max-width: none;
                    }
                }
            }
        }
    }
}

.multi-column {
    &.Row {
        > .Row__container {
            > .modules {
                + .modules {
                    margin-top: 3.75rem;

                    @media only screen and (min-width: $breakpoint-tablet) {
                        margin-top: 5rem;
                    }

                    @media only screen and (min-width: $breakpoint-xl-new) {
                        margin-top: 6.25rem;
                    }
                }

                &.moduleCarousel {
                    :global {
                        .swiper {
                            .image {
                                width: 70%;

                                @media only screen and (min-width: $breakpoint-lg-new) {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }

                &.moduleImage {
                    max-width: 100%;
                }
            }

            .single-column {
                margin: 0;

                &:not(.bg) {
                    .Row__container {
                        width: 100%;
                        padding: 0;
                        margin: 0;
                    }
                }

                &.bg {
                    height: 100%;

                    .Row__container {
                        width: 65%;
                        padding: 0;
                    }
                }
            }
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            > .Row__container {
                width: 450px;
                padding: 0;

                .single-column {
                    &.bg {
                        .Row__container {
                            width: 60%;
                        }
                    }
                }
            }
        }
    }

    &-0 {
        &.Row {
            > .Row__container {
                &.flex {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-evenly;
                    margin-top: 6px;
                }

                > .modules {
                    flex: 0 0 30%;
                    margin-bottom: 25px;

                    + .modules {
                        margin-top: 0;
                    }
                }

                @media only screen and (min-width: $breakpoint-tablet) {
                    width: auto;
                }

                @media only screen and (min-width: $breakpoint-lg-new) {
                    &.flex {
                        flex-wrap: nowrap;
                        justify-content: space-between;
                    }

                    > .modules {
                        flex: 0 0 auto;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    &-2 {
        &.Row {
            > .Row__container {
                &.fourty-fourty,
                &.fourty-sixty,
                &.sixty-fourty,
                &.half,
                &.half-desktop {
                    @media only screen and (min-width: $breakpoint-tablet) {
                        display: grid;
                        width: auto;
                        justify-content: center;
                        grid-gap: 30px;
                    }
                }

                &.fourty-fourty,
                &.fourty-sixty,
                &.sixty-fourty,
                &.half {
                    @media only screen and (min-width: $breakpoint-tablet) {
                        > .modules {
                            + .modules {
                                margin-top: 0;
                            }
                        }
                    }
                }

                &.fourty-sixty,
                &.sixty-fourty {
                    @media only screen and (min-width: $breakpoint-tablet) {
                        > .modules {
                            align-items: center;

                            &.moduleImage {
                                align-items: flex-start;
                            }
                        }
                    }

                    @media only screen and (min-width: $breakpoint-tablet) {
                        &.flipAtDesktop {
                            > .modules {
                                &:first-of-type {
                                    grid-column: 2;
                                    grid-row: 1;
                                }
                            }
                        }
                    }

                    @media only screen and (min-width: $breakpoint-xl-new) {
                        grid-column-gap: 125px;
                    }
                }

                &.sixty-fourty {
                    @media only screen and (min-width: $breakpoint-tablet) {
                        grid-template-columns: 390px 270px;

                        &.flipAtDesktop {
                            grid-template-columns: 270px 390px;
                        }
                    }

                    @media only screen and (min-width: $breakpoint-lg-new) {
                        grid-template-columns: 530px 370px;

                        &.flipAtDesktop {
                            grid-template-columns: 370px 530px;
                        }
                    }

                    @media only screen and (min-width: $breakpoint-xl-new) {
                        grid-template-columns: 540px 445px;

                        &.flipAtDesktop {
                            grid-template-columns: 445px 540px;
                        }
                    }
                }

                &.fourty-sixty {
                    @media only screen and (min-width: $breakpoint-tablet) {
                        grid-template-columns: 270px 390px;

                        &.flipAtDesktop {
                            grid-template-columns: 390px 270px;
                        }
                    }

                    @media only screen and (min-width: $breakpoint-lg-new) {
                        grid-template-columns: 370px 530px;

                        &.flipAtDesktop {
                            grid-template-columns: 530px 370px;
                        }
                    }

                    @media only screen and (min-width: $breakpoint-xl-new) {
                        grid-template-columns: 445px 540px;

                        &.flipAtDesktop {
                            grid-template-columns: 540px 445px;
                        }
                    }
                }

                &.fourty-fourty,
                &.half {
                    @media only screen and (min-width: $breakpoint-tablet) {
                        grid-template-columns: repeat(2, 330px);

                        &.wrap {
                            &.odd {
                                > .modules {
                                    &:last-child {
                                        width: 330px;
                                        grid-column-start: span 2;
                                        justify-self: center;
                                    }
                                }
                            }
                        }
                    }
                }

                &.fourty-fourty,
                &.half,
                &.half-desktop {
                    :global {
                        .image {
                            overflow: hidden;
                            max-width: 100%;

                            &.left,
                            &.right {
                                text-align: center;
                            }
                        }
                    }

                    @media only screen and (min-width: $breakpoint-tablet) {
                        &.flipAtDesktop {
                            > .modules {
                                &:first-of-type {
                                    grid-column: 2;
                                    grid-row: 1;
                                }
                            }
                        }
                    }

                    @media only screen and (min-width: $breakpoint-lg-new) {
                        grid-template-columns: repeat(2, 450px);

                        &.wrap {
                            &.odd {
                                > .modules {
                                    &:last-child {
                                        width: 450px;
                                    }
                                }
                            }
                        }

                        :global {
                            .image {
                                &.left {
                                    text-align: left;
                                }

                                &.right {
                                    text-align: right;
                                }
                            }
                        }
                    }

                    @media only screen and (min-width: $breakpoint-xl-new) {
                        grid-template-columns: repeat(2, 540px);

                        &.wrap {
                            &.odd {
                                > .modules {
                                    &:last-child {
                                        width: 540px;
                                    }
                                }
                            }
                        }
                    }
                }

                &.half-desktop {
                    @media only screen and (min-width: $breakpoint-lg-new) {
                        > .modules {
                            + .modules {
                                margin-top: 0;
                            }
                        }
                    }
                }

                &.fourty-fourty {
                    @media only screen and (min-width: $breakpoint-xl-new) {
                        width: 1110px;
                        justify-content: space-between;
                        grid-template-columns: repeat(2, 450px);
                    }
                }

                .half-flex {
                    display: flex;

                    &.wrap {
                        flex-wrap: wrap;
                        justify-content: space-between;
                    }

                    &.Row__container {
                        width: 100%;

                        > .modules {
                            width: 100%;
                            max-width: calc(50% - 2px);
                            align-items: center;
                            margin-bottom: 4px;

                            + .modules {
                                margin-top: 0;
                            }

                            .removePadding {
                                padding: 0;
                            }
                        }
                    }
                }

                &.flex {
                    display: flex;
                    column-gap: 20px;

                    > .modules {
                        padding: 0 !important;

                        + .modules {
                            margin: 0;
                        }

                        &.moduleImage {
                            width: auto;
                        }
                    }

                    &.horizontal- {
                        &Around {
                            justify-content: space-around;
                        }

                        &Between {
                            justify-content: space-between;
                        }

                        &Evenly {
                            justify-content: space-evenly;
                        }

                        &Left {
                            justify-content: flex-start;
                        }

                        &Center {
                            justify-content: center;
                        }

                        &Right {
                            justify-content: flex-end;
                        }
                    }
                }

                > .modules {
                    > .single-column {
                        .sectionMultiColumn {
                            > .multi-column-2 {
                                > .Row__container {
                                    &.half,
                                    &.half-desktop {
                                        grid-template-columns: repeat(2, 50%);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.full {
                > .Row__container {
                    width: 100%;
                    max-width: none;

                    &.half {
                        @media only screen and (min-width: $breakpoint-tablet) {
                            grid-template-columns: repeat(2, calc(50% - 15px));
                        }
                    }

                    &.half-desktop {
                        @media only screen and (min-width: $breakpoint-lg-new) {
                            grid-template-columns: repeat(2, calc(50% - 15px));

                            .moduleText {
                                padding: 0 20px;
                            }
                        }

                        @media only screen and (min-width: $breakpoint-xl-new) {
                            grid-template-columns: repeat(2, calc(50% - 15px));
                        }
                    }
                }
            }
        }
    }

    &-3 {
        .single-column {
            &.bg {
                padding: 1.25rem 0;

                @media only screen and (min-width: $breakpoint-tablet) {
                    padding: 1.5625rem 0;
                }
            }
        }

        &.Row {
            > .Row__container {
                .single-column {
                    :global {
                        .br {
                            height: 20px;

                            @media only screen and (min-width: $breakpoint-tablet) {
                                height: 30px;
                            }

                            @media only screen and (min-width: $breakpoint-lg-new) {
                                height: 40px;
                            }
                        }
                    }
                }

                &.flex {
                    display: flex;
                    justify-content: center;

                    &.wrap {
                        flex-wrap: wrap;
                    }
                }
            }

            @media only screen and (min-width: $breakpoint-tablet) {
                > .Row__body {
                    width: 450px;
                    padding: 0;
                }

                > .Row__container {
                    &.flex {
                        width: 100%;

                        > .modules {
                            + .modules {
                                margin-top: 5rem;

                                &:nth-of-type(2) {
                                    margin-top: 0;
                                }
                            }
                        }
                    }
                }
            }

            @media only screen and (min-width: $breakpoint-lg-new) {
                > .Row__body {
                    width: 930px;
                }

                > .Row__container {
                    display: grid;
                    width: auto;
                    justify-content: center;
                    grid-gap: 30px;
                    grid-template-columns: repeat(3, 290px);

                    > .modules {
                        + .modules {
                            margin-top: 0;
                        }
                    }

                    &.flex {
                        > .modules {
                            + .modules {
                                &:nth-of-type(3) {
                                    margin-top: 0;
                                }
                            }
                        }
                    }
                }
            }

            @media only screen and (min-width: $breakpoint-xl-new) {
                > .Row__body {
                    width: auto;
                }

                > .Row__container {
                    grid-template-columns: repeat(3, 350px);
                }
            }
        }
    }

    &-4,
    &-6 {
        .single-column {
            &.bg {
                padding: 1.25rem 0;
            }
        }
    }

    &-4 {
        .single-column {
            &.bg {
                @media only screen and (min-width: $breakpoint-xl-new) {
                    padding: 1.5625rem 0;
                }
            }
        }

        &.Row {
            @media only screen and (min-width: $breakpoint-tablet) {
                > .Row__container {
                    display: grid;
                    width: auto;
                    justify-content: center;
                    grid-gap: 80px 30px;
                    grid-template-columns: repeat(2, 210px);
                    grid-template-rows: auto;

                    > .modules {
                        + .modules {
                            margin-top: 0;
                        }
                    }
                }
            }

            @media only screen and (min-width: $breakpoint-lg-new) {
                > .Row__container {
                    grid-template-columns: repeat(4, 210px);
                }
            }

            @media only screen and (min-width: $breakpoint-xl-new) {
                > .Row__container {
                    grid-template-columns: repeat(4, 255px);
                }
            }
        }
    }

    &-6 {
        .single-column {
            &.bg {
                @media only screen and (min-width: $breakpoint-xl-new) {
                    .Row__container {
                        width: 70% !important;
                    }
                }
            }
        }

        &.Row {
            @media only screen and (min-width: $breakpoint-tablet) {
                > .Row__container {
                    display: grid;
                    width: auto;
                    align-content: center;
                    align-items: center;
                    justify-content: center;
                    grid-gap: 40px 30px;
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: auto;
                    justify-items: center;

                    > .modules {
                        width: 100%;

                        + .modules {
                            margin-top: 0;
                        }
                    }
                }
            }

            @media only screen and (min-width: $breakpoint-lg-new) {
                > .Row__container {
                    grid-template-columns: repeat(3, 1fr);
                }
            }

            @media only screen and (min-width: $breakpoint-xl-new) {
                > .Row__container {
                    grid-template-columns: repeat(6, 1fr);
                }
            }
        }
    }
}

.demo {
    z-index: 10;
    padding: 1.25rem;
    margin: 1.25rem 0;
    background: $gold;
    text-align: center;
}

.separator {
    height: 0;
    border-color: transparent;
    margin: 0;
}

@import '../../../lib/styles/variables';
@import '../../../lib/styles/common';

.made-for-you {
    padding: 4.375rem 0;

    > .body {
        width: 250px;
        margin: auto;

        .heading-2 {
            font-family: $tiempos;
            font-size: 2.3625rem;
            letter-spacing: 0.94px;
            line-height: 1.07;
            white-space: nowrap;
        }

        p {
            u {
                position: relative;
                display: inline-block;
                text-decoration: none;

                &::before {
                    position: absolute;
                    z-index: -1;
                    right: 0;
                    bottom: 7%;
                    left: 0;
                    height: 7px;
                    background: $secondary-light;
                    content: '';
                }
            }
        }

        ul {
            max-width: 215px;
            margin: 0 auto 3.125rem;
        }

        li {
            @include checklist('primary', $primary, 1rem, 1);

            flex-direction: column;

            align-items: center;
            margin-bottom: 1.25rem;
            text-align: center;

            p {
                &:not(.heading) {
                    font-family: $calibre-semibold;
                    font-size: 1.3188rem;
                    font-weight: 600;
                    letter-spacing: 0.53px;
                    line-height: 1.17;
                }
            }
        }
    }

    > picture {
        margin: 6.25rem auto;
    }

    .ask-yourself {
        position: relative;
        width: 300px;
        margin: auto;

        h4 {
            margin-bottom: 1rem;
            color: $secondary-dark;
            font-size: 1.125rem;
            letter-spacing: 0.1756rem;
            text-align: center;
        }

        > .body {
            display: none;
        }

        > picture {
            width: 300px;

            img {
                width: 300px;
            }
        }

        .messages__container {
            position: absolute;
            top: 120px;
            right: 13px;
            bottom: 90px;
            left: 13px;
            display: flex;
            height: 417px;
            flex-direction: column;
            justify-content: flex-end;
            padding: 0 1.25rem;
            margin: 0 auto;
            overflow-y: auto;
        }

        .messages__item {
            position: relative;
            display: none;
            width: 100%;
            padding: 1px;
            margin-bottom: 1.25rem;
            background: conic-gradient(from 0.26turn, $secondary-light, $primary 0.49turn, $secondary-light);
            border-radius: 1.5625rem;
            opacity: 0;
            transition: all 0.15s ease-in-out;

            &::before, &::after {
                position: absolute;
                bottom: 0;
                height: 25px;
                content: '';
            }

            &::before {
                right: -7px;
                width: 20px;
                background-color: $secondary-light;
                border-bottom-left-radius: 16px 14px;
            }

            &::after {
                right: -26px;
                width: 26px;
                background-color: white;
                border-bottom-left-radius: 10px;
            }

            &:global(.active) {
                display: block;
                animation: fadeNewMessage 0.5s;
                animation-fill-mode: forwards;
            }
        }

        .messages__body {
            position: relative;
            min-height: 40px;
            box-sizing: border-box;
            padding: 1.25rem 0.9375rem;
            background: $white;
            border-radius: 24px;
            color: $secondary-dark;
            font-size: 1.125rem;
            line-height: 1.11;

            &::before, &::after {
                position: absolute;
                bottom: 0;
                height: 23px;
                content: '';
            }

            &::before {
                right: -6px;
                width: 17px;
                background-color: $white;
                border-bottom-left-radius: 16px 13px;
            }

            &::after {
                right: -26px;
                width: 26px;
                background-color: $secondary-light;
                border-bottom-left-radius: 7px 9px;
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg) {
        display: flex;
        max-width: 1110px;
        align-items: center;
        padding: 8.125rem 0 0;
        margin: auto;

        > .body {
            width: 320px;
            margin: 0 auto 0 1.25rem;

            .heading-2 {
                margin-left: 1.25rem;
                font-size: 2.95rem;
                letter-spacing: 1.18px;
            }

            ul {
                max-width: 290px;
                margin-left: 0;
            }

            li {
                flex-direction: row;
                align-items: flex-start;
                text-align: left;

                p {
                    &:not(.heading) {
                        font-size: 1.65rem;
                        line-height: 1.17;

                    }
                }
            }
        }

        > picture {
            width: 9px;
            margin: 0;

            img {
                width: 100%;
            }
        }

        .ask-yourself {
            margin-right: 4.375rem;
        }
    }
}

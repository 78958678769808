@import 'lib/styles/variables';

.product-selector {
    .selectors {
        .selector {
            display: flex;
            align-items: baseline;
            padding: 1.5625rem 0;
            cursor: pointer;
            line-height: 1.4;
            user-select: none;

            .radio {
                position: absolute;
                width: 0;
                height: 0;
                padding: 0 !important;
                margin: 0 !important;
                opacity: 0;
                pointer-events: none;
            }

            &::before {
                display: inline-block;
                width: 16px;
                height: 16px;
                flex: 0 0 16px;
                padding: 0.25rem;
                border: 1px solid $black;
                margin: 0 1rem 0 0.25rem;
                border-radius: 50%;
                content: '';
                transition: 0.2s;
            }

            + .selector {
                border-top: 1px dashed $gray-4;
            }

            .name {
                font-size: 1.0625rem;
                font-weight: 500;

                :global {
                    .priceWrapper {
                        display: inline;

                        .price {
                            margin-right: 0.5rem;
                            color: $brand-green-3;
                            font-size: 1.0625rem;
                            font-weight: 500;

                            .suffix {
                                font-size: 1.0625rem;
                            }
                        }

                        > span {
                            display: none;
                        }
                    }
                }
            }

            .price {
                :global {
                    .priceWrapper {
                        margin-top: 0.25rem;

                        .price {
                            font-size: 1.125rem;

                            .suffix {
                                font-size: 1.125rem;
                            }

                            .currency {
                                font-weight: 300;
                            }

                            &--current {
                                font-weight: 700;
                            }

                            &--original {
                                &::after {
                                    top: 50%;
                                }
                            }
                        }

                        > span {
                            display: none;
                        }
                    }
                }
            }

            .subtext {
                color: $brand-green-3;
                font-size: 0.875rem;

                &.subtextBlack {
                    color: $black;
                }
            }

            .subtext,
            .additional {
                font-weight: 200;
            }

            .additional {
                font-size: 0.6875rem;
            }

            &.active {
                &::before {
                    background-color: $black;
                    background-image: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 10 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.18511 1.18119L3.46372 7.81404L0.619431 4.74464' stroke='white'/%3E%3C/svg%3E%0A");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 10px 9px;
                }
            }

            @media only screen and (min-width: $breakpoint-tablet) {
                .name {
                    font-size: 1.125rem;
                    font-weight: 600;

                    :global {
                        .priceWrapper {
                            .price {
                                color: $brand-green-3;
                                font-size: 1.125rem;
                                font-weight: 600;

                                .suffix {
                                    font-size: 1.125rem;
                                }
                            }
                        }
                    }
                }

                .subtext {
                    font-size: 1rem;
                }

                .additional {
                    font-size: 0.875rem;
                }
            }
        }
    }

    .cta {
        ul {
            padding-bottom: 2.8125rem;
            border-bottom: 1px solid $gray-3;
            margin: 1.25rem 0 0;
        }

        li {
            display: flex;
            align-items: center;
            font-family: $font-TWKLausanne;
            font-size: 0.75rem;
            font-weight: 250;
            line-height: 1.6;
            list-style: none;

            svg {
                width: 9.27px;
                margin-right: 0.5rem;
                fill: $brand-green-3;
            }
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            li {
                font-size: 1rem;
                font-weight: 300;
                line-height: 1.5;
            }
        }
    }

    .selected {
        overflow: hidden;
        max-height: 0;
        transition: 0.2s ease-in-out;

        &.active {
            max-height: 2000px;
            transition: 0.2s ease-in-out;
        }
    }
}

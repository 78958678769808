/* stylelint-disable media-feature-name-no-vendor-prefix */
@import '../../lib/styles/variables';
@import '../../lib/styles/common';

.Product {
    background-color: $primary;
    background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/3rWpBj6ElThawbbxZ929FX/51d42a9165df2c64e470ddf65846be55/home-hi-mission-bkg-151833_mobile.png?w=370&fm=webp&q=70');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: auto;

    @media only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (-o-min-device-pixel-ratio: 2/1),
        only screen and (min-resolution: 192dpi),
        only screen and (min-resolution: 2dppx) {
        background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/1hmNaTxyALhsdiGTNuEyTz/9797fcb0219f5f28bfe289847e13c437/home-hi-mission-bkg-151833_mobile_2x.png?w=740&fm=webp&q=70');
    }

    @media only screen and (-webkit-min-device-pixel-ratio: 3),
        only screen and (-o-min-device-pixel-ratio: 3/1),
        only screen and (min-resolution: 384dpi),
        only screen and (min-resolution: 3dppx) {
        background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/1Lux9TYF24Mn3hh0CmsaBG/17a111ccc568a2675f386b078294c3d9/home-hi-mission-bkg-151833_mobile_3x.png?fm=webp&q=70');
    }

    p {
        color: $white;

        &.heading {
            text-align: center;
        }

        &.heading-2 {
            width: 290px;
            margin: 0 auto 0.3125rem;
            font-family: $calibre;
            font-size: 1.125rem;
            font-weight: 400;
            line-height: calc(24 / 18);
        }

        &.heading-3 {
            margin: 0 0 2.8125rem;
            font-size: 1.875rem;
            line-height: calc(35 / 30);
            text-align: center;
        }
    }

    &__wrapper {
        width: 100%;
        max-width: 400px;
        padding: 6.25rem 1.875rem;
        margin: auto;

        .viome-modern__accordion {
            padding-top: 1.25rem;

            .body {
                p {
                    margin: 3.75rem 0 2.5rem;
                    font-size: 1.125rem;
                    text-align: center;
                }
            }
        }

        .viome-modern__product-module__bottom {
            max-width: 310px;
            margin: 4.375rem auto 0;

            p {
                @include viomeButton;
            }
        }

        :global {
            .Carousel {
                .Carousel__wrapper {
                    max-width: 277px;
                }

                .carousel {
                    .carousel-slider {
                        overflow: visible;
                    }

                    .control-dots {
                        bottom: -2.1875rem;

                        .dot {
                            background: rgb(255 255 255 / 20%);

                            &.selected, &.hover {
                                background: $white;
                            }
                        }
                    }
                }
            }

            .Card {
                max-width: 330px;
                padding: 0 1.125rem;
                border-bottom: solid 2px $secondary;
                margin: 0 auto 1.875rem;

                &__wrapper {
                    &.active {
                        .viome__section__body {
                            p {
                                &::after {
                                    transform: rotate(-135deg);
                                }
                            }
                        }
                    }
                }

                &__before {
                    display: flex;
                    align-items: flex-start;

                    > div {
                        max-width: 23px;
                        margin: -0.1875rem 0.9375rem 0 0;
                    }

                    img {
                        width: 100%;
                    }

                    &.viome__section__body {
                        p {
                            display: flex;
                            flex-grow: 1;
                            align-items: flex-start;
                            justify-content: space-between;
                            margin: 0 0 1.25rem;
                            font-family: $calibre-semibold;
                            font-size: 1.375rem;
                            line-height: 1;

                            &::after {
                                display: inline-block;
                                padding: 0.1875rem;
                                border: solid $white;
                                border-width: 0 3px 3px 0;
                                margin-top: 0.125rem;
                                content: '';
                                transform: rotate(45deg);
                                transition: transform .2s ease-in-out;
                            }
                        }
                    }
                }

                &__after {
                    &.viome__section__body {
                        p {
                            margin: 0 0 1.875rem;
                            font-size: 1.375rem;
                            line-height: calc(28 / 22);
                            text-align: left;
                        }
                    }
                }
            }
        }
    }

    @media screen and (min-width: $breakpoint-tablet) {
        background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/3n8PEdbXOzx6Hg2VjHCm05/63700f6d41109d07020e9553052d2532/home-hi-mission-bkg-151833_tablet.png?w=768&fm=webp&q=70');

        @media only screen and (-webkit-min-device-pixel-ratio: 2),
            only screen and (-o-min-device-pixel-ratio: 2/1),
            only screen and (min-resolution: 192dpi),
            only screen and (min-resolution: 2dppx) {
            background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/6jf4UKlRrLYdMOFTmXir59/a2d232010cdb54341165b944c055a721/home-hi-mission-bkg-151833_tablet_2x.png?w=1536&fm=webp&q=70');
        }

        @media only screen and (-webkit-min-device-pixel-ratio: 3),
            only screen and (-o-min-device-pixel-ratio: 3/1),
            only screen and (min-resolution: 384dpi),
            only screen and (min-resolution: 3dppx) {
            background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/2bnAQrkblvtzQdPSTXlHif/063e58cdfdc1e28113e672d0a2a0ee37/home-hi-mission-bkg-151833_desktop_3x.png?fm=webp&q=70');
        }

        &__wrapper {
            max-width: 445px;
            padding: 6.25rem 0;
        }
    }

    @media screen and (min-width: $breakpoint-lg) {
        background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/3EolghhP4nAgjxeFSRNiuj/3a08db20b661a77782f6ac33e6cb1b99/home-hi-mission-bkg-151833_desktop.png?w=1440&fm=webp&q=70');

        @media only screen and (-webkit-min-device-pixel-ratio: 2),
            only screen and (-o-min-device-pixel-ratio: 2/1),
            only screen and (min-resolution: 192dpi),
            only screen and (min-resolution: 2dppx) {
            background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/4zZ6lLJhi681tD4aJmhKwE/ee90b6055d6a5ca5375edd2f7657a221/home-hi-mission-bkg-151833_desktop_2x.png?w=2880&fm=webp&q=70');
        }

        @media only screen and (-webkit-min-device-pixel-ratio: 3),
            only screen and (-o-min-device-pixel-ratio: 3/1),
            only screen and (min-resolution: 384dpi),
            only screen and (min-resolution: 3dppx) {
            background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/2bnAQrkblvtzQdPSTXlHif/063e58cdfdc1e28113e672d0a2a0ee37/home-hi-mission-bkg-151833_desktop_3x.png?fm=webp&q=70');
        }

        p {
            &.heading {
                text-align: left;
            }

            &.heading-2 {
                margin: 0 0 0.3125rem
            }

            &.heading-3 {
                margin-bottom: 3.75rem;
            }
        }

        &__wrapper {
            display: grid;
            max-width: none;
            justify-content: center;
            padding: 8.125rem 1.875rem 6.25rem;
            gap: 120px;
            grid-template-columns: 333px 350px;

            .viome-modern__product-module__top, .viome-modern__accordion {
                margin: 0;
            }

            .viome-modern__product-module__top {
                grid-row: 1 / span 2;
            }

            .viome-modern__accordion {
                padding-top: 0;

                > .wrapper {
                    > .body {
                        p {
                            margin: 0 0 3.75rem;
                            text-align: left;
                        }
                    }
                }
            }

            .viome-modern__product-module__bottom {
                display: block;
                width: 310px;
                padding: 0;
                margin: -3.75rem 0 0;
                grid-column: 2;
                grid-row: 2;
                justify-self: center;
            }

            :global {
                .Carousel {
                    .Carousel__wrapper {
                        margin: 0;
                    }
                }

                .Card {
                    max-width: none;
                }
            }
        }
    }
}

.Product--hi-plan {
    background: $primary;

    .viome-modern__product-module__top {
        > p {
            &:not(.heading) {
                display: none;
                margin-bottom: 20px;

                &:last-of-type {
                    margin: 0;
                }

                a {
                    @extend %viome-btn;

                    background-color: $secondary-light;
                    color: $white;
                    font-size: 1.25rem;
                }
            }
        }
    }

    .viome-modern__product-module__bottom, .viome-modern__product-module__top {
        > p {
            &:not(.heading) {
                &:first-of-type {
                    margin-bottom: 20px;
                }

                &:last-of-type {
                    border: 2px solid #fff;

                    a {
                        background: transparent;
                    }
                }
            }
        }
    }

    @media screen and (min-width: $breakpoint-lg) {
        .Product__wrapper {
            row-gap: 0;

            .viome-modern__product-module__top {
                .inline {
                    margin-bottom: 100px;

                    img {
                        margin-left: -40px;
                    }
                }

                > p {
                    &:not(.heading) {
                        display: block;
                    }
                }
            }

            .viome-modern__product-module__bottom {
                display: none;
            }
        }
    }
}

@import 'lib/styles/variables';

.separator {
    height: 0;
    padding: 0.0031rem;
    margin: 0;
    visibility: hidden;
}

.modules {
    &:empty {
        display: none;

        + .modules {
            margin-top: 0 !important;
        }
    }

    .clickable {
        text-decoration: none;

        &:hover {
            color: inherit;
        }
    }

    &.moduleTestimonial {
        height: 100%;
    }

    &.moduleSelector {
        + .moduleSelector {
            margin-top: 1.25rem;
        }
    }

    &.absolute {
        position: absolute;
        z-index: -1;

        &.z-10 {
            z-index: 10;
        }

        &.center {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &.top {
            top: 0;
        }

        &.bottom {
            bottom: 0;
        }

        &.left {
            left: 0;
        }

        &.right {
            left: 0;
        }

        &.top,
        &.bottom {
            &-middle {
                left: 50%;
                transform: translateX(-50%);
            }

            &-left {
                left: 0;
            }

            &-right {
                right: 0;
            }
        }

        &.left,
        &.right {
            &-middle {
                top: 50%;
                transform: translateY(-50%);
            }

            &-top {
                top: 0;
            }

            &-bottom {
                bottom: 0;
            }
        }
    }

    &.span {
        margin-top: 0 !important;
        grid-column: 1 / -1;
    }

    &.autoTop {
        margin-top: auto !important;
    }
}

/* some styles are covered in _base.scss but still need until legacy stuff is removed */
.row {
    &.sectionMultiColumn20 {
        > .row__container {
            &:not(.sm-hasColumns) {
                > .modules {
                    + .modules {
                        margin-top: 2.5rem;
                    }
                }
            }

            &.snuggle {
                > .modules {
                    + .modules {
                        margin-top: 6px;
                    }
                }
            }

            &.tight {
                > .modules {
                    + .modules {
                        margin-top: 0;
                    }
                }

                @media only screen and (max-width: $breakpoint-tablet - 1) {
                    &.flex-between {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                    }
                }
            }

            &.sm {
                &-hasColumns {
                    > .modules {
                        + .modules {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }

    &.sticky {
        position: sticky;
        z-index: 3;
        top: 0;
    }

    &.dark {
        color: $white;

        table,
        tr {
            border-color: $white;
        }

        th {
            background: $gray-4;
        }
    }

    &.scrollable {
        overflow: scroll;
    }

    &.absolute {
        position: absolute;
    }

    &.fullheight-important {
        height: 100% !important;
    }

    .hoverWrapper {
        position: relative;

        .hoverSection {
            position: absolute;
            inset: 0;
            opacity: 0;
        }

        .hover,
        .hoverSection {
            transition: 0.5s ease-in-out;
        }

        &:hover {
            .hover {
                opacity: 0;
            }

            .hoverSection {
                opacity: 1;
            }
        }
    }

    &.hoverVideo {
        overflow: hidden;

        :global {
            .text {
                > p {
                    opacity: 0;
                }
            }
        }
    }

    &.hasPopup {
        cursor: pointer;
    }

    &.bg {
        display: flex;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        transition: none;

        &.contain {
            background-size: contain;
        }

        &--attached {
            background-attachment: fixed;
        }

        &-auto {
            background-size: auto;
        }

        &-top {
            background-position-y: top;
        }

        &-bottom {
            background-position-y: bottom;
        }

        &-left {
            background-position-x: left;
        }

        &-right {
            background-position-x: right;
        }

        + .separator {
            display: none;
        }
    }

    .compact-testimonial {
        .moduleTestimonial {
            margin-bottom: 2.5rem;

            .testimonial {
                display: flex;
                flex-direction: column-reverse;
                align-items: flex-start;
                gap: 0;
            }

            .stars {
                display: flex;
                order: 2;
                color: $action-gold;

                + div {
                    order: 1;
                    margin: 0.375rem 0;

                    > p {
                        font-family: $calibre-light;
                        font-size: 1.5rem;
                        font-style: italic;
                        text-align: left;
                    }
                }
            }

            .line,
            .headshot {
                display: none;
            }

            .head {
                min-height: unset;
                align-self: flex-end;
                justify-content: flex-end;
                margin-top: 0;

                .bio {
                    display: flex;
                    flex-direction: row;

                    p {
                        font-size: 1.125rem;
                    }
                }

                .name {
                    &::before {
                        display: inline;
                        content: '- ';
                    }

                    &::after {
                        display: inline;
                        content: ',';
                    }
                }

                .role {
                    margin-top: 0;
                    margin-left: 0.25rem;
                }
            }
        }
    }

    > .videoBackground {
        position: absolute;
        z-index: 0;
        overflow: hidden;
        width: 100%;
        inset: 0;

        > div {
            height: 100%;
        }

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        ~ .row__container {
            position: absolute;
            z-index: 0;
            width: 100%;
            inset: 0;
        }
    }

    &.margin-bottom {
        margin-bottom: 2rem;
    }

    &.margin-top {
        margin-top: 2rem;
    }

    > .row__container {
        z-index: 1;
        overflow: hidden;

        &.flex {
            &:not(.wrap) {
                display: flex;
            }
        }

        &.full-column {
            height: 100%;

            > .modules {
                height: 100%;

                > div,
                > section {
                    height: 100%;
                }
            }

            .full-section {
                display: flex;
                height: 100%;
                flex-direction: column;

                .moduleImage {
                    + .sectionSingle20 {
                        flex-grow: 1;
                    }
                }
            }
        }
    }

    &.hrline {
        hr {
            margin: 0;
            background-color: $black;
        }
    }

    @media only screen and (max-width: $breakpoint-lg-new - 1) {
        &.sectionMultiColumn20 {
            > .row__container {
                &.tight {
                    gap: 0;
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        &.sectionMultiColumn20 {
            > .row__container {
                &:not(.md-hasColumns) {
                    > .modules {
                        + .modules {
                            margin-top: 5rem;
                        }
                    }
                }

                &.tight {
                    > .modules {
                        + .modules {
                            margin-top: 0;
                        }
                    }
                }

                &.snuggle,
                &.md-hasColumns {
                    > .modules {
                        + .modules {
                            margin-top: 0;
                        }
                    }
                }
            }
        }

        &.margin-bottom {
            margin-bottom: 2.5rem;
        }

        &.margin-top {
            margin-top: 2.5rem;
        }

        > .row__container {
            &.flex {
                &.wrap {
                    &.md-max {
                        &-2 {
                            > div {
                                width: 50%;
                                flex: 0 0 50%;
                            }
                        }

                        &-3 {
                            > div {
                                width: calc(1 / 3 * 100%);
                                flex: 0 0 calc(1 / 3 * 100%);
                            }
                        }
                    }
                }
            }

            &.grid {
                &.md-max-2 {
                    &.flip--tablet {
                        > div {
                            &:nth-child(1) {
                                grid-column-start: 2;
                            }

                            &:nth-child(2) {
                                grid-column-start: 1;
                                grid-row-start: 1;
                            }
                        }
                    }
                }

                &.lg-max-2 {
                    &.flip--desktop {
                        > div {
                            &:nth-child(1) {
                                grid-column-start: 2;
                            }

                            &:nth-child(2) {
                                grid-column-start: 1;
                                grid-row-start: 1;
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg-new) {
        h1 {
            font-size: 3.75rem;
            line-height: 1.2;
        }

        h2 {
            font-size: 3rem;
        }

        h3 {
            font-size: 2.25rem;
        }

        h4 {
            font-size: 1.875rem;
        }

        &.sectionMultiColumn20 {
            > .row__container {
                .modules {
                    + .modules {
                        margin-top: 0;
                    }
                }

                &:not(.md-hasColumns),
                &.snuggle {
                    > .modules {
                        + .modules {
                            margin-top: 0;
                        }
                    }
                }
            }
        }

        &.margin-bottom {
            margin-bottom: 5rem;
        }

        &.margin-top {
            margin-top: 5rem;
        }

        > .row__container {
            &.flex {
                &.wrap {
                    &.lg-max {
                        &-2 {
                            > div {
                                width: 50%;
                                flex: 0 0 50%;
                            }
                        }

                        &-3 {
                            > div {
                                width: calc(1 / 3 * 100%);
                                flex: 0 0 calc(1 / 3 * 100%);
                            }
                        }

                        &-4 {
                            > div {
                                width: 25%;
                                flex: 0 1 25%;
                            }
                        }

                        &-5 {
                            > div {
                                width: 20%;
                                flex: 0 1 20%;
                            }
                        }

                        &-6 {
                            > div {
                                width: calc(1 / 6 * 100%);
                                flex: 0 1 calc(1 / 6 * 100%);
                            }
                        }
                    }
                }
            }
        }
    }
}

.demo {
    z-index: 10;
    padding: 1.25rem;
    margin: 1.25rem 0;
    background: $gold;
    text-align: center;
}

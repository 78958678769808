@import 'lib/styles/variables';
@import 'lib/styles/common';

.modalOverlay {
    position: fixed;
    z-index: 1;
    height: 100%;
    background-color: rgb(0 0 0 / 30.1%);
    inset: 0;
}

.modalDetails {
    position: fixed;
    z-index: 9999999;
    overflow: scroll;
    height: 100vh;
    padding: 6.25em 2.5em;
    background-color: $white;
    inset: 0;

    &.upsell {
        width: 100vw;

        p {
            font-size: 1rem;
        }

        :global {
            .price {
                color: $brand-green-3;
            }

            .promo {
                font-size: 1rem;

                p {
                    margin-bottom: 0;
                    font-size: 1rem;
                }
            }
        }

        @media only screen and (min-width: $breakpoint-tablet) {
            width: 80vw;
            max-width: 1024px;

            p {
                font-size: 1.25rem;
            }

            :global {
                .price {
                    color: $brand-green-3;
                }

                .promo {
                    font-size: 1.125rem;

                    p {
                        font-size: 1.125rem;
                    }
                }
            }
        }
    }

    &__CloseButton {
        position: absolute;
        z-index: 1000;
        top: 30px;
        right: 30px;
    }

    h2 {
        margin-bottom: 1.5em;
        font-size: 1.6em;
        font-stretch: normal;
        font-style: normal;
        font-weight: 500;
        line-height: 1.15;
    }

    p {
        margin-bottom: 1.3em;
        font-size: 1.1em;
        font-stretch: normal;
        font-style: normal;
        font-weight: normal;
        letter-spacing: normal;
        line-height: 1.29em;
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        top: 40%;
        bottom: auto;
        left: 50%;
        height: auto;
        min-height: 70%;
        padding: 6.25em 2.5em 2.5em;
        box-shadow: 0 2px 100px 0 rgb(0 0 0 / 30%);
        transform: translate(-50%, -40%);
    }

    @media only screen and (min-width: $breakpoint-desktop) {
        min-height: auto;
    }
}

@import '../../lib/styles/variables';

.podcast-module {
    max-width: 691px;
    padding-bottom: 70px;
    margin: 9.25rem auto;

    h2,
    p {
        text-align: center;
    }

    h2 {
        margin-bottom: 10px;
        font-size: 30px;
        line-height: calc(40 / 30);
    }

    p {
        margin-bottom: 50px;
        font-size: 18px;
        line-height: calc(22 / 18);
    }

    @media screen and (min-width: $breakpoint-tablet) {
        p {
            margin-bottom: 40px;
        }
    }

    @media screen and (min-width: $breakpoint-desktop) {
        .viome-modern__multi-column__wrapper {
            display: block;
        }
    }

    @media screen and (min-width: $breakpoint-tablet) {
        max-width: 918px;
    }
}

@import 'lib/styles/variables';

.publication {
    width: 80%;
    margin: 0 auto 5rem;

    a {
        text-decoration: none;
    }

    img {
        margin-bottom: 1rem;
    }

    h4 {
        margin-bottom: 1.75rem;
    }

    p {
        color: $gray-darkest;
    }

    @media only screen and (min-width: $breakpoint-lg-new) {
        margin-bottom: 9.375rem;
    }
}

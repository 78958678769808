@import './Count-Global.module.scss';

.Product {
    background: $secondary-dark;

    .heading, p {
        color: $white;
    }

    .heading-3 {
        margin: 0 0 1rem;
        font-size: 1.875rem;
        line-height: calc(35 / 30);
        text-align: left;
    }

    .viome-modern__product-module__top {
        hr {
            background: $white;
        }
    }

    &__wrapper {
        order: 2;
    }

    > .body {
        width: 100%;
        max-width: 370px;
        order: 1;
        padding: 0 1.25rem;
        margin: 2.5rem auto 3.125rem;
    }

    @media only screen and (min-width: $breakpoint-lg) {
        display: flex;
        flex-wrap: wrap;

        &.reverse {
            justify-content: flex-end;
        }

        &__wrapper {
            order: 1;
        }

        > .body {
            order: 2;
            padding: 0;
            margin: 0;
        }

        > picture {
            width: 330px;
            order: 3;
            margin: -17.8125rem calc((195/1440) * 100%) 0 calc((150/1440) * 100%);
        }
    }

    &--gi, &--gi-plan {
        .viome-modern__product-module__top {
            p {
                &:not(.heading) {
                    font-family: $calibre;
                    font-size: 1.125rem;
                    font-weight: normal;
                    line-height: calc(24 / 18);
                }
            }
        }

        > .body {
            position: relative;
            padding: 0.5rem 1.25rem 0.25rem;

            .heading-3 {
                font-size: 1.5rem;
                line-height: calc(29 / 24);
            }

            p {
                &:not(.heading) {
                    font-size: 1.125rem;
                    line-height: calc(24 / 18);
                }
            }
        }

        @media only screen and (min-width: $breakpoint-lg) {
            > .body {
                margin: -17.5rem 1.25rem 0 calc((165/1440) * 100%);
            }

            .heading-3 {
                width: 380px;
                margin-bottom: 1.875rem;
            }
        }
    }

    &--gi {
        @media only screen and (min-width: $breakpoint-lg) {
            > .body {
                padding: 0;
                margin-top: -9.375rem;
            }
        }

        @media only screen and (min-width: $breakpoint-xl) {
            > .body {
                margin-top: -21.875rem;
            }
        }
    }

    &--gi-plan {
        > .body {
            padding-left: 2.5rem;

            &::after {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 20px;
                width: 1.5px;
                background: $white;
                box-shadow: 0 0 13px 4px rgb(255 255 255 / 45%);
                content: '';
            }
        }

        @media only screen and (min-width: $breakpoint-lg) {
            gap: 7.5rem 0;

            > .body {
                order: 4;
                padding-left: 1.25rem;

                &::after {
                    left: 0;
                }
            }

            > picture {
                margin: -20.3125rem 0 0;
            }
        }
    }
}

@import '/lib/styles/variables';

.accordion {
    width: 100%;

    &.dark {
        .Accordion__item {
            border-color: $white;

            svg {
                path {
                    stroke: currentcolor;
                }
            }
        }

        .before {
            &.plusButton {
                min-height: 40px;
                padding: 0;
                margin: 1rem;
                background-color: $white;
                color: $black;

                :global {
                    .text {
                        p {
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }

    &__item {
        border-bottom: 1px solid $gray-3;
        margin-bottom: 1px;

        .section {
            :global {
                .heading-5 {
                    line-height: 1.4;
                }
            }
        }

        .text {
            p {
                text-align: left;
            }

            .before {
                p {
                    width: max(320px, calc(100% - 60px));
                    padding-top: 0.3125rem;
                }
            }

            .after {
                p {
                    padding: 0.3125rem 0 2.5rem;
                }
            }
        }

        .before {
            display: flex;
            min-height: 60px;
            align-items: center;
            justify-content: space-between;
            padding: 0.9375rem 0;
            cursor: pointer;
            gap: 1.25rem;

            svg {
                flex-shrink: 0;
                margin-right: 0.625rem;
                fill: currentcolor;
                transition: 0.3s ease-in-out;
            }
        }

        .after {
            overflow: hidden;
            max-height: 0;
            transition: all 0.3s ease-in-out;

            :global {
                .text {
                    padding: 0 0.0625rem 1.5rem;
                }
            }
        }

        .active {
            svg {
                transform: rotate(-180deg);
            }

            + .after {
                &:not(.popupContainer) {
                    max-height: 2000px;
                }
            }
        }

        &.Bio {
            .before {
                align-items: baseline;

                i {
                    font-weight: 200;
                    line-height: 1.4;
                }
            }

            .after {
                :global {
                    .text {
                        p {
                            font-size: 1rem;
                            font-weight: 250;
                            line-height: 1.4;
                        }
                    }
                }
            }
        }

        &.ForwardArrow {
            display: inline-block;

            .before {
                min-height: auto;
                padding: 0;
                gap: 1rem;

                p {
                    font-size: inherit !important;
                }

                svg {
                    width: 16px;
                    margin: 0 !important;
                }
            }
        }

        &.PopUp {
            .before {
                min-height: unset;
                padding: 0;

                > div {
                    > p {
                        padding: 0.5rem 1.5rem;
                        border: 1px solid;
                    }
                }
            }
        }

        :global {
            .text {
                br {
                    &:last-child {
                        display: none;
                    }
                }
            }
        }
    }

    &.ctaExpand {
        &.full {
            .accordion__item {
                .before {
                    justify-content: flex-start;
                }
            }
        }

        .accordion__item {
            border: 0;
            margin: 0;

            .before {
                display: flex;
                justify-content: center;

                .icon {
                    position: relative;
                    width: 16px;
                    height: 16px;
                    box-sizing: border-box;
                    margin-left: 0.625rem;
                    transform: rotate(180deg);
                    transition: transform 0.3s;

                    &::before {
                        position: absolute;
                        bottom: 16px / 2 - 1px/2;
                        display: block;
                        width: 16px;
                        height: 0;
                        border-bottom: solid 1px currentcolor;
                        content: '';
                        transform: rotate(90deg);
                        transition: width 0.3s;
                    }

                    &::after {
                        position: absolute;
                        bottom: 16px / 2 - 1px/2;
                        display: block;
                        width: 16px;
                        height: 0;
                        border-bottom: solid 1px currentcolor;
                        content: '';
                    }
                }

                .text {
                    background-color: green;
                }

                :global {
                    .text {
                        padding: 0;

                        p {
                            font-size: 1rem;
                        }
                    }
                }
            }

            .active {
                .icon {
                    transform: rotate(0deg);

                    &::before {
                        width: 0;
                    }

                    &::after {
                        border-color: currentcolor;
                    }
                }
            }
        }
    }

    &.generic {
        .accordion__item {
            border: 0;
        }
    }

    &.icons {
        &--collapse {
            .accordion__item {
                .before {
                    justify-content: flex-start;
                }

                .after {
                    :global {
                        .text {
                            padding: 0 0 0 1.5625rem;
                        }

                        .text.pl-0 {
                            padding-left: 0;
                        }
                    }
                }
            }
        }

        .accordion__item {
            .before {
                svg {
                    margin: 0 0 0 0.625rem;
                }

                :global {
                    .text {
                        > * {
                            display: flex;
                            align-items: center;

                            img {
                                margin-right: 0.625rem;
                            }
                        }
                    }
                }
            }
        }

        &--start {
            .accordion__item {
                .before {
                    :global {
                        .text {
                            > * {
                                align-items: flex-start;
                            }

                            img {
                                max-width: 2.5rem;
                            }
                        }
                    }
                }

                .after {
                    padding: 0.625rem 0 0 3.125rem;
                }
            }
        }
    }

    &.large {
        .before {
            p {
                font-size: 1.25rem;
                line-height: 1.4;
                white-space: nowrap;
            }
        }
    }

    .popup {
        bottom: unset;
        height: 100vh;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 0.5rem;
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $gray-4;
        }

        .wrapper {
            padding: 2rem 1.5rem;
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        max-width: 570px;
        margin: auto;

        &.generic {
            margin: 0;
        }

        &.full {
            max-width: none;
        }

        &__item {
            .before {
                min-height: 72px;
            }

            .text {
                p {
                    font-size: 1.25rem;
                    line-height: 1.3;
                }
            }
        }

        .popup {
            background-color: rgb(0 0 0 / 80%) !important;

            &.active {
                &.center {
                    display: flex;

                    .wrapper {
                        margin: auto;
                    }
                }
            }

            .wrapper {
                position: relative;
                width: 494px;
                padding: 5rem;
                margin: 5rem auto;
            }
        }
    }

    @media only screen and (min-width: $breakpoint-lg-new) {
        max-width: 768px;

        &:not(&.dark) {
            .accordion__item {
                border-color: $black;
            }
        }

        &.full {
            max-width: none;
        }

        .popup {
            .wrapper {
                width: 842px;
            }
        }
    }
}

/* stylelint-disable media-feature-name-no-vendor-prefix */
@import './Carousel.module.scss';

.Carousel {
    background-color: $secondary;
    background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/6Epwvw7YbpAHRcYBNkn01d/b0d3ba024385235e60dc906e0120d2e4/group-13_3x.jpg?w=370&h=760&fit=crop&q=70');
    background-size: cover;

    &__wrapper {
        max-width: 227px;
        padding: 7.5rem 0 8.75rem;
    }

    :global {
        .heading-2, .heading-3, p {
            color: $white;
        }

        .heading-2 {
            margin-bottom: 0.375rem;
            font-size: 1.25rem;
            line-height: calc(24 / 20);
        }

        .heading-3 {
            display: block;
            margin-bottom: 0;
            font-family: $calibre;
            font-size: 1rem;
            font-style: italic;
            font-weight: normal;
            line-height: calc(24 / 16);

            &:last-of-type {
                margin-bottom: 1.25rem;
            }
        }

        .image {
            margin: auto;
        }

        p {
            &:not(.heading) {
                margin-bottom: 2.5rem;
                font-size: 1rem;
                line-height: calc(24 / 16);
                white-space: normal;
            }
        }

        picture {
            display: inline-block;
            width: 100px;
            margin-bottom: 1.625rem;
        }
    }

    @media only screen and (min-width: $breakpoint-tablet) {
        &__wrapper {
            max-width: 450px;
        }
    }

    @media only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (-o-min-device-pixel-ratio: 2/1),
        only screen and (min-resolution: 192dpi),
        only screen and (min-resolution: 2dppx) {
        background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/6Epwvw7YbpAHRcYBNkn01d/b0d3ba024385235e60dc906e0120d2e4/group-13_3x.jpg?w=740&h=1540&fit=crop');

        @media screen and (min-width: $breakpoint-lg) {
            background-image: url('https://images.ctfassets.net/qk4l4jfatr3e/6Epwvw7YbpAHRcYBNkn01d/b0d3ba024385235e60dc906e0120d2e4/group-13_3x.jpg?w=1440&h=663&fit=crop');
        }
    }
}

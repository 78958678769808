@import '../../../../lib/styles/variables';

.study {
    padding: 30px 45px;
    border: solid 1px $gray-dark2;

    p {
        &.header {
            margin-top: 30px;
            color: $secondary;
        }
    }
}
